<template>
  <section>
    <search-query-input
      :id="id"
      ref="queryInput"
      :placeholder="placeholder"
      @change="$emit('change')"
    />
  </section>
</template>
<script>
import SearchQueryInput from "@/components/assemblies/search/components/query/SearchQueryInput";

export default {
  components: {
    SearchQueryInput,
  },
  props: {
    placeholder: {
      type: String,
      default: "Search courses, research, events and more...",
    },
  },
  computed: {
    id() {
      return `search-keywords-${Math.floor(Math.random() * 9999999)}`;
    },
  },
};
</script>
