<template>
  <div :class="[...classList]" data-tab-nav>
    <!-- eslint-disable vue/no-v-html -->
    <button
      v-for="trigger in triggers"
      :id="trigger.id"
      :key="trigger.id"
      :aria-controls="trigger.target"
      :class="triggerClass(trigger)"
      class="w-1/2 py-3 font-bold border-b transition"
      @click.prevent="handleClick(trigger)"
      v-html="trigger.text"
    />
  </div>
</template>
<script>
export default {
  props: {
    classList: {
      type: Array,
      default: () => [],
    },
    wrapper: {
      type: Element,
      required: true,
    },
    triggers: {
      type: Array,
      required: true,
    },
    initialTarget: {
      type: Element,
      required: true,
    },
    targets: {
      type: Array,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentTarget: null,
      hiddenClass: "js:hidden",
    };
  },
  watch: {
    currentTarget(target) {
      this.activateTarget(target);
    },
  },
  created() {
    this.currentTarget = this.initialTarget;
  },
  methods: {
    handleClick(trigger) {
      this.currentTarget = this.getTarget(trigger);
    },
    triggerClass(trigger) {
      return this.isActiveTrigger(trigger)
        ? ["border-accent-blue text-accent-blue"]
        : ["border-lavender focus:text-accent-blue hover:text-accent-blue"];
    },
    isActiveTrigger(trigger) {
      return this.currentTarget && trigger.target === this.currentTarget.id;
    },
    activateTarget(target) {
      this.targets.map((t) => this.hideTarget(t));
      this.showTarget(target);
    },
    hideTarget(target) {
      target.classList.add(this.hiddenClass);
      target.setAttribute("aria-hidden", true);
    },
    showTarget(target) {
      target.classList.remove(this.hiddenClass);
      target.removeAttribute("aria-hidden");
    },
    getTarget(trigger) {
      return this.targets.find((target) => target.id === trigger.target);
    },
  },
};
</script>
