export default {
  computed: {
    tagMetaItems() {
      return [
        {
          title: this.hit.section,
        },
        {
          title: this.hit.type,
        },
      ];
    },
    metaItems() {
      return [].concat(this.hit.internalPeople).filter(Boolean);
    },
  },
};
