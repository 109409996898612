<template>
  <div id="results-screen" class="overflow-y-auto h-screen overflow-x-hidden">
    <div class="w-screen h-10 mt-5">
      <close-search-button
        v-if="toggleSearchQuery && !isSearchPage"
        class="xl:mr-24 mr-10 float-right"
        @click="closeSearch"
      />
    </div>

    <section>
      <div v-if="paginationShown && toggleSearchQuery && !isMobile">
        <result-count />
      </div>
      <div
        :class="toggleSearchQuery && 'toggleSearchQuery'"
        :style="{
          marginBottom:
            searchExpanded && !toggleSearchQuery
              ? '15px'
              : activeFilter === 'courses' && !isMobile
              ? '130px'
              : '40px',
          marginTop: isMobile ? '15px' : '',
        }"
      >
        <search-global-input v-if="queryShown" />
        <div v-if="paginationShown && toggleSearchQuery && isMobile">
          <result-count />
        </div>
        <desktop-filters v-if="filtersShown && toggleSearchQuery" />
      </div>
      <section>
        <div v-if="searchExpanded && !toggleSearchQuery" class="defaultLayout">
          <div
            v-if="
              query.length > 0 &&
              courseFilteredHits.length === 0 &&
              eventsFilteredHits.length === 0 &&
              newsFilteredHits.length === 0 &&
              researchFilteredHits.length === 0 &&
              peopleFilteredHits.length === 0
            "
          >
            <NoResultsOptions />
          </div>
          <list
            v-else
            :title="query.length > 0 ? 'Suggestions' : 'Popular searches'"
            :data="query.length > 0 ? suggestions : popularSearches"
          />
          <default :hits="this.resultsByCategory" />
        </div>
        <query-matches v-if="searchExpanded && toggleSearchQuery" :hits="this.resultsByCategory" />
      </section>
    </section>
  </div>
</template>

<style scoped>
.toggleSearchQuery {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 0 90px;
}

.defaultLayout {
  padding: 0 90px 40px;
}

@media (max-width: 1375px) {
  .toggleSearchQuery {
    padding: 0 60px;
  }
}

@media (max-width: 1340px) {
  .toggleSearchQuery {
    padding: 0 20px;
  }
}

@media (max-width: 1600px) {
  .toggleSearchQuery {
    flex-direction: column;
  }
}

@media (max-width: 1024px) {
  .defaultLayout {
    padding: 0 15px 40px;
    border-top: 1px solid #ededed;
  }

  .toggleSearchQuery {
    flex-direction: column;
    padding: 0 15px;
  }
}
</style>
<script>
import { mapGetters, mapState, mapActions } from "vuex";

import CloseSearchButton from "@/components/molecules/close-search-button/CloseSearchButton";
import SearchGlobalInput from "@/components/assemblies/search/components/SearchGlobalInput";

import DesktopFilters from "@/components/assemblies/search/components/filters/Desktop";
import List from "@/components/assemblies/search/components/hits/List";
import Default from "@/components/assemblies/search/components/results/Default";
import ResultCount from "@/components/assemblies/search/components/results/ResultCount";
import QueryMatches from "@/components/assemblies/search/components/results/QueryMatches";
import NoResultsOptions from "@/components/assemblies/search/components/results/NoResultsOptions";

export default {
  components: {
    CloseSearchButton,
    DesktopFilters,
    Default,
    List,
    ResultCount,
    QueryMatches,
    SearchGlobalInput,
    NoResultsOptions,
  },
  computed: {
    ...mapState("masthead", ["searchExpanded", "toggleSearchQuery"]),
    ...mapState("search", [
      "isSearchPage",
      "suggestions",
      "popularSearches",
      "query",
      "filtersShown",
      "queryShown",
      "paginationShown",
      "activeFilter",
      "hits",
    ]),
    ...mapGetters("search", [
      "resultsByCategory",
      "totalHitsV2",
      "courseFilteredHits",
      "eventsFilteredHits",
      "newsFilteredHits",
      "researchFilteredHits",
      "peopleFilteredHits",
    ]),
    ...mapGetters("ui", ["isMobile"]),
  },
  methods: {
    ...mapActions("masthead", ["toggleSearch"]),
    closeSearch() {
      console.log("close Search");
      this.toggleSearch();
    },
  },
};
</script>
