<template>
  <button
    v-if="!isMini || !isExpanded"
    :id="id"
    :aria-controls="'inner-' + id"
    :aria-label="ariaLabel"
    :aria-expanded="isExpanded"
    :class="[...htmlClasses, isExpanded ? 'is--active' : '']"
    class="block w-full text-left flex items-center"
    @click.prevent="toggle"
  >
    <span v-if="!isMini" class="mr-2 text-accent-blue">
      <icon
        :icon="expandIcon"
        role="presentation"
        width="8"
        height="8"
        class="text-accent-blue block w-6"
      />
    </span>
    <!-- eslint-disable vue/no-v-html -->
    <span :class="toggleTextClass" class="block trim font-bold" v-html="text" />
    <span v-if="!isMini" class="text-accent-blue ml-auto text-sm">{{
      isExpanded ? "Close" : "More"
    }}</span>
  </button>
</template>
<script>
import ToggleBase from "./ToggleBase";

export default {
  extends: ToggleBase,
  props: {
    isMini: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    toggleTextClass() {
      if (this.isMini) {
        return ["block", "trim", "link"];
      }
      return ["block", "trim", "font-bold"];
    },
  },
};
</script>
