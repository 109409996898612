import Vue from "vue";
import ImageCarousel from "./ImageCarousel";

export const imageCarousel = (configs) =>
  configs.map(
    (c) =>
      new Vue({
        el: c.el,
        name: "CarouselWrapper",
        data: function () {
          return {
            items: c.items,
          };
        },
        render(h) {
          return h(ImageCarousel, {
            props: {
              items: c.items,
            },
          });
        },
      })
  );
