var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('search-overlay',{staticClass:"searchOverlay",style:({
      overflow: _vm.mobileFilters ? 'hidden' : '',
      transform:
        _vm.searchExpanded && _vm.toggleSearchQuery
          ? 'translateY(0px)'
          : _vm.searchExpanded
          ? 'translateY(0px)'
          : 'translateY(-100%)',
    }),attrs:{"id":"search-overlay"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }