export default {
  computed: {
    metaItems() {
      return [].concat(this.hit.postDateFormats.localeDate).filter(Boolean);
    },
    tagMetaItems() {
      const items = [
        {
          title: "News",
        },
      ];

      if (this.hit.newsCategoriesNested && this.hit.newsCategoriesNested.length) {
        items.push({
          title: this.hit.newsCategoriesNested[0].title,
          url: this.hit.newsCategoriesNested[0].url,
        });
      }
      return items;
    },
  },
};
