export default {
  computed: {
    tagMetaItems() {
      const items = [
        {
          title: "Testimonial",
        },
      ];

      if (this.hit.levelOfStudy && this.hit.levelOfStudy.length) {
        items.push({
          title: this.hit.levelOfStudy[0],
        });
      }

      return items;
    },
  },
};
