<script>
import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("search", ["hasNextPage"]),
  },
  methods: {
    ...mapActions("search", ["pageNext"]),
  },
};
</script>

<template>
  <li class="pagination-next" :class="{ disabled: !hasNextPage }">
    <button
      :disabled="!hasNextPage"
      aria-label="Next"
      class="block border border-grey-light w-10 h-10 flex flex-wrap items-center justify-center hover:text-accent-blue focus:text-accent-blue"
      @click.prevent="pageNext"
    >
      <b aria-hidden="true">&gt;</b>
    </button>
  </li>
</template>
