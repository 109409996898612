<template>
  <section>
    <search-overlay
      class="searchOverlay"
      id="search-overlay"
      :style="{
        overflow: mobileFilters ? 'hidden' : '',
        transform:
          searchExpanded && toggleSearchQuery
            ? 'translateY(0px)'
            : searchExpanded
            ? 'translateY(0px)'
            : 'translateY(-100%)',
      }"
    />
  </section>
</template>
<style scoped>
.searchOverlay {
  position: fixed;

  /* high z-index in order to cover the feedback and  live chat buttons */
  z-index: 999999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-top: 1px solid #ededed;
  background: #f8f9fb;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  transition: transform 0.5s ease-in-out;
}

.searchOverlay::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
</style>
<script>
import { mapState } from "vuex";

import SearchOverlay from "@/components/assemblies/search/components/SearchOverlay";

export default {
  components: {
    SearchOverlay,
  },
  computed: {
    ...mapState("masthead", ["searchExpanded", "toggleSearchQuery"]),
    ...mapState("search", ["mobileFilters"]),
  },
};
</script>
