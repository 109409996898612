<!-- eslint-disable vue/no-v-for-template-key-on-child -->
<template>
  <div :class="{ 'lg:w-1/2': !hasMediaRight }" class="w-full">
    <div
      :class="{
        'pb-12': hit.hasUrl,
        'm-teaser-top': !hasMediaRight,
        'm-teaser-right': hasMediaRight,
      }"
    >
      <div
        :class="{
          'flex flex-wrap md:flex-row-reverse md:flex-row': hasMediaRight,
        }"
        class="md:px-2"
      >
        <div
          :class="{ 'w-full md:w-2/5 md:pl-12 md:max-w-md': hasMediaRight }"
          class="mb-6 relative"
        >
          <hit-image v-if="!hasMediaRight || hit.image" :hit="hit" />
        </div>
        <div :class="{ 'w-full md:w-3/5': hasMediaRight }">
          <template v-for="(metaItem, index) in subMetaItems">
            <span
              v-if="index === 1"
              :key="metaItem.title + '-presep'"
              class="text-grey"
              role="presentation"
            >
              {{ subMetaSeparator }}
            </span>
            <a v-if="metaItem.url" :key="metaItem.title + '-item'" class="text-sm mx-1 ml-0">
              {{ metaItem.title }}
            </a>
            <span v-else :key="metaItem.title + '-item'" class="text-sm mx-1 ml-0">
              {{ metaItem.title }}
            </span>
            <span :key="metaItem.title + '-sep'" class="text-grey" role="presentation">
              {{ subMetaSeparator }}
            </span>
          </template>
          <h3 class="mb-1 text-md">
            <a
              v-if="hit.hasUrl"
              class="block no-underline focus:underline hover:underline"
              :href="hit.uri"
              @click="trackGoResult(hit.uri)"
            >
              {{ hit.title }}
            </a>
            <span v-else class="block text-lg">
              {{ hit.title }}
            </span>
          </h3>

          <div class="flex-1 mt-3 leading-tight">
            <span v-for="(item, index) in metaItems" :key="item + '-' + index" class="text-xs">
              <a
                v-if="item.url"
                :key="item.title + '-item'"
                :href="item.url"
                class="text-sm no-underline"
              >
                {{ item.title }}
              </a>
              <span v-else>
                {{ truncate(item, 90, "...") }}
              </span>
              <span v-if="index < metaItems.length - 1" class="text-brand-yellow px-1">
                {{ metaSeparator }}
              </span>
            </span>
          </div>
          <slot name="excerpt">
            <div v-if="hit.excerpt">
              <p v-if="hit.hasUrl" class="text-comet mt-3 mb-0">
                {{ parseAndTruncate(hit.excerpt, 200, "...") }}
              </p>
              <p v-else class="text-comet mt-3 mb-0">{{ hit.excerpt }}</p>
            </div>
          </slot>

          <div v-if="tagMetaItems.length" class="mt-6 mb-3">
            <span v-for="(metaItem, index) in tagMetaItems" :key="metaItem.title" class="meta">
              <a
                v-if="metaItem.url"
                :href="metaItem.url"
                :class="{ 'meta--accent-blue': index > 0 }"
              >
                <span class="meta__inner">{{ metaItem.title }}</span>
              </a>
              <span v-else :class="{ 'meta--accent-blue': index > 0 }">
                {{ metaItem.title }}
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseHit from "../BaseHit";

export default {
  extends: BaseHit,
};
</script>
