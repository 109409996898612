<!-- Teriary Component
    Craft CMS Data:
      -items.heading ('anything in a string')
      -items.icon ('anything in a string')
      -items.alt ('description of image')
      -items.link ('https://henley.imgix.net/v3/...')
      -items.lineDivider ('yes' or 'no')
      -items.blockDivider ('yes' or 'no')

    Maximum no. of characters for Heading:
    -Heading = 30
-->
<template>
  <a :href="items.link" @mouseover="hover = true" @mouseleave="hover = false">
    <section>
      <div class="image" v-bind:style="{ backgroundImage: 'url(' + items.icon + ')' }"></div>
      <h6 :class="{ 'hover-colour': hover }">{{ items.heading }}</h6>
      <span>
        <inline-arrow
          :name="!hover ? 'default-arrow' : 'default-hover-arrow'"
          :class="{ 'active-animate': hover }"
          class="hover"
        />
      </span>
    </section>
    <HorizontalLine v-if="items.lineDivider == 'yes'" class="line" />
  </a>
</template>
<style scoped>
section {
  display: flex;
  width: 180px;
  align-items: center;
  justify-content: space-between;
  color: black;
}

svg {
  width: 10px;
  min-width: 10px;
}

a,
h6 {
  margin: auto 0;
}

h6 {
  width: 100%;
  margin-right: 15px;
  color: #333;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
}

.image {
  min-width: 34px;
  min-height: 34px;
  margin-right: 10px;
  background-size: cover;
  border-radius: 4px;
}

.line {
  width: 100%;
  min-width: 180px;
  margin: 20px 0;
}

.hover-colour {
  color: #0a3b85 !important;
}

.hover {
  transition-duration: 0.2s;
}

.active-animate {
  transform: translateX(3px);
}

@media (max-width: 1340px) {
  section {
    min-width: 150px;
    max-width: 160px;
  }

  section h6 {
    min-width: 65px;
    margin: auto 5px;
    margin-right: 15px;
  }

  .line {
    min-width: 150px;
  }
}
</style>
<script>
import InlineArrow from "@/components/assemblies/masthead/components/elements/InlineArrow";
import HorizontalLine from "@/components/assemblies/masthead/components/elements/HorizontalLine";

export default {
  components: {
    InlineArrow,
    HorizontalLine,
  },
  data() {
    return {
      hover: false,
    };
  },
  props: {
    items: {
      type: Object,
    },
  },
};
</script>
