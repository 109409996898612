import Vue from "vue";
import BreakpointDebugger from "./BreakpointDebugger";
import store from "@/store/index";

export const breakpointDebug = (configs) => {
  return configs.map(() => {
    return new Vue({
      el: document.body.appendChild(document.createElement("div")),
      render: (h) => h(BreakpointDebugger),
      store,
    });
  });
};
