<template>
  <header id="masthead">
    <desktop-masthead v-if="is2lg" />
    <mobile-masthead v-else />
    <navigation-banner />
  </header>
</template>
<style>
a:hover {
  text-decoration: none;
}
</style>
<script>
import { createNamespacedHelpers } from "vuex";

import MobileMasthead from "@/components/assemblies/masthead/components/mobile/MobileMasthead";
import DesktopMasthead from "@/components/assemblies/masthead/components/desktop/DesktopMasthead";
import NavigationBanner from "@/components/assemblies/masthead/components/NavigationBanner";

const { mapGetters: mapUiGetters } = createNamespacedHelpers("ui");

export default {
  components: {
    MobileMasthead,
    DesktopMasthead,
    NavigationBanner,
  },
  computed: {
    ...mapUiGetters(["is2lg"]),
  },
};
</script>
