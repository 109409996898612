<template>
  <div :id="id" :class="blockClass">
    <splide :options="splideOptions" @splide:mounted="onSplideMounted">
      <splide-slide
        v-for="(date, index) in items"
        :key="index"
        class="bg-grey-light flex flex-col justify-between md:flex-row splide__slide"
      >
        <div
          class="antialiased bg-brand-blue text-white p-6 text-center flex justify-center items-center content-center flex-wrap max-w-none md:max-w-xxxs"
        >
          <p class="font-bold text-xl px-2 w-full mb-2 leading-tight uppercase">
            {{ date.startDate }}
          </p>
          <p
            v-if="
              date.spacesAvailable !== null &&
              date.spacesAvailable !== '' &&
              date.displaySpacesAvailable
            "
            class="border border-white font-bold px-2 mb-0 whitespace-no-wrap"
          >
            {{ getLabelForSpaces(date.spacesAvailable) }}
          </p>
        </div>
        <div class="p-6 whitespace-no-wrap flex flex-col justify-between">
          <p class="mb-2">
            <span v-if="date.delivery.length > 0"
              ><strong>Delivery: </strong>{{ getDeliveryString(date.delivery) }}<br
            /></span>
            <span v-if="date.locations.length > 0">
              <strong>Location: </strong
              ><span v-for="loc in date.locations" :key="loc.title">{{ loc.title }}</span>
            </span>
          </p>
          <div class="flex flex-col md:flex-row items-start md:items-center">
            <a
              v-if="date.bookingLink"
              class="btn btn--accent-yellow px-4 py-2"
              :href="date.bookingLink"
              >{{ date.ctaLabel }}</a
            >
            <button
              v-if="date.sessions.length > 0 || date.overview.length > 0"
              v-on:click="modalItemIndex = index"
              class="text-accent-blue no-underline mt-2 md:ml-2 md:mt-0"
            >
              View schedule
            </button>
          </div>
        </div>
      </splide-slide>
      <splide-slide v-if="fallback" class="mr-4 w-3/6 p-6 bg-grey-light">
        <div class="max-w-xs">
          <h3 class="text-lg font-bold">{{ fallback.headline }}</h3>
          <p>{{ fallback.description }}</p>
          <a class="btn btn--accent-yellow" :href="fallback.buttonUrl">{{ fallback.buttonText }}</a>
        </div>
      </splide-slide>
    </splide>

    <div
      v-if="modalItemIndex !== null"
      class="flex justify-center items-center bg-black-50 fixed inset-0 z-50"
      v-on:click.self="closeModal"
    >
      <div class="w-full md:w-1/2 bg-white p-8 relative mx-4 max-h-ninety overflow-auto">
        <button v-on:click="closeModal" class="absolute top-5 right-5">
          <icon icon="close" role="presentation" width="8" height="8" class="w-8 h-8" />
        </button>
        <div class="flex items-center mb-8">
          <p class="font-bold text-ml m-0">{{ items[modalItemIndex].startDate }}</p>
          <p
            v-if="
              items[modalItemIndex].spacesAvailable !== null &&
              items[modalItemIndex].spacesAvailable !== '' &&
              items[modalItemIndex].displaySpacesAvailable
            "
            class="border border-inherit rounded text-sm px-2 mb-0 whitespace-no-wrap ml-2"
          >
            {{ getLabelForSpaces(items[modalItemIndex].spacesAvailable) }}
          </p>
        </div>
        <p class="mb-4 flex -ml-8">
          <span class="ml-8" v-if="items[modalItemIndex].delivery.length > 0"
            ><strong>Delivery: </strong>{{ getDeliveryString(items[modalItemIndex].delivery) }}<br
          /></span>
          <span class="ml-8" v-if="items[modalItemIndex].locations.length > 0">
            <strong>Location: </strong
            ><span v-for="loc in items[modalItemIndex].locations" :key="loc.title">{{
              loc.title
            }}</span>
          </span>
        </p>
        <div v-html="items[modalItemIndex].overview"></div>
        <table class="w-full mb-8">
          <tr class="text-left border border-grey">
            <th class="p-4">Session</th>
            <th class="p-4">Starts</th>
            <th class="p-4">Ends</th>
          </tr>
          <!-- eslint-disable vue/no-v-for-template-key-on-child -->
          <template v-for="(session, index) in items[modalItemIndex].sessions">
            <tr
              class="border-grey border-t border-l border-r"
              :key="index"
              :class="{ 'border-b': !session.title, 'bg-grey-lighter': index % 2 == 0 }"
            >
              <td class="p-4">{{ session.description }}</td>
              <td class="p-4">{{ session.startDate }}</td>
              <td class="p-4">{{ session.endDate }}</td>
            </tr>
            <tr
              v-if="session.title"
              class="border-grey border-l border-r border-b"
              :key="index"
              :class="{ 'bg-grey-lighter': index % 2 == 0 }"
            >
              <td class="pb-4 pl-4 pr-4" colspan="3">{{ session.title }}</td>
            </tr>
          </template>
        </table>
        <div>
          <a
            v-if="items[modalItemIndex].bookingLink"
            class="btn btn--accent-yellow px-4 py-2"
            :href="items[modalItemIndex].bookingLink"
            >{{ items[modalItemIndex].ctaLabel }}</a
          >
        </div>
      </div>
    </div>
  </div>
</template>
<style>
/* purgecss start ignore */
.splide__track {
  overflow: visible !important;
}

/* purgecss end ignore */
</style>
<script>
import Icon from "@/components/atoms/icon/Icon";

export default {
  components: {
    Icon,
  },
  props: {
    id: {
      type: String,
      default: () => `card-slider-${Math.floor(Math.random() * 9999999999999)}`,
    },
    items: {
      type: Array,
      required: true,
    },
    fallback: {
      type: Object,
    },
    blockClass: {
      type: String,
      default: "",
    },
    quoteClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      modalItemIndex: null,
      splideOptions: {
        autoWidth: true,
        perMove: 1,
        gap: "1rem",
        arrows: false,
        pagination: false,
      },
    };
  },
  computed: {
    itemCount() {
      return this.items.length;
    },
  },
  methods: {
    closeModal() {
      this.modalItemIndex = null;
    },
    getLabelForSpaces(spaces) {
      if (spaces <= 0) {
        return `Full`;
      }
      if (spaces == 1) {
        return `${spaces} space available`;
      }
      return `${spaces} spaces available`;
    },
    onSplideMounted(splide) {
      window.addEventListener("henley-splide-prev", () => {
        splide.go("-");
      });
      window.addEventListener("henley-splide-next", () => {
        splide.go("+");
      });
    },
    getDeliveryString(delivery_methods) {
      let str = "";
      for (let i = 0; i < delivery_methods.length; i++) {
        if (i !== 0) {
          if (i === delivery_methods.length - 1) {
            str += " and ";
          } else {
            str += ", ";
          }
        }
        str += delivery_methods[i];
      }
      return str;
    },
  },
};
</script>
