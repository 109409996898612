<template>
  <div :id="id" :class="blockClass">
    <span role="presentation" class="absolute block dot-grid h-48 w-40 opacity-50"></span>

    <div class="container">
      <div class="m-carousel py-12 w-full">
        <div
          v-for="(item, index) in items"
          :key="index"
          :class="['m-carousel__item', { active: index === currentIndex }]"
        >
          <div class="max-w-lg">
            <figure :class="quoteClass">
              <!-- eslint-disable vue/no-v-html -->
              <blockquote v-html="item.text"></blockquote>
              <div class="flex flex-wrap items-center">
                <div v-if="item.image" class="w-16 h-16 md:mr-5">
                  <img
                    class="w-full block rounded rounded-full"
                    :src="item.image.url"
                    :alt="item.image.title"
                  />
                </div>
                <figcaption
                  v-if="item.attribution"
                  class="flex-grow w-3/4 text-base tracking-normal"
                >
                  <span class="font-bold" v-text="item.attribution"></span
                  ><span v-if="item.role">, <span v-text="item.role"></span></span
                  ><span v-if="item.course">, <span v-text="item.course.title"></span></span>
                </figcaption>
              </div>
            </figure>
          </div>
        </div>
      </div>

      <div v-if="items.length > 1" class="absolute bg-accent-blue-dark bottom-0 right-0">
        <carousel-controls
          :items="items"
          :current-index="currentIndex"
          text-color="white"
          @prev="goPrev"
          @next="goNext"
        />
      </div>
    </div>
  </div>
</template>
<script>
import CarouselControls from "../media/carousel/CarouselControls";

export default {
  components: {
    CarouselControls,
  },
  props: {
    id: {
      type: String,
      default: () => `carousel-${Math.floor(Math.random() * 9999999999999)}`,
    },
    items: {
      type: Array,
      required: true,
    },
    blockClass: {
      type: String,
      default: "",
    },
    quoteClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      currentIndex: 0,
    };
  },
  computed: {
    itemCount() {
      return this.items.length;
    },
    currentItem() {
      return this.items[this.currentIndex];
    },
    ariaLabel() {
      return `${this.currentIndex + 1} of ${this.items.length}`;
    },
  },
  methods: {
    goNext() {
      console.log("next");
      this.currentIndex = this.currentIndex === this.itemCount - 1 ? 0 : this.currentIndex + 1;
    },
    goPrev() {
      console.log("prev");
      this.currentIndex = this.currentIndex === 0 ? this.itemCount - 1 : this.currentIndex - 1;
    },
  },
};
</script>
