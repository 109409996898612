<template>
  <div v-if="!searchMobileExpanded" class="w-full h-16">
    <label class="sr-only" :for="id">Enter search keywords</label>
    <query-input
      :id="id"
      ref="queryInput"
      :placeholder="placeholder"
      class="w-full h-full px-8 block appearance-none bg-transparent text-inherit leading-tight focus:outline-none"
      @change="$emit('change')"
    />
  </div>
</template>
<script>
import { mapState } from "vuex";

import QueryInput from "@/search/components/query/QueryInput";

export default {
  components: {
    QueryInput,
  },
  props: {
    placeholder: {
      type: String,
      default: "What are you looking for?",
    },
  },
  computed: {
    ...mapState("masthead", ["searchMobileExpanded"]),
    id() {
      return `search-keywords-${Math.floor(Math.random() * 9999999)}`;
    },
  },
  methods: {
    focus() {
      this.$refs.queryInput.focus();
    },
  },
};
</script>
