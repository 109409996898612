<template>
  <div class="shadow-md">
    <div class="flex flex-wrap items-center">
      <div v-if="showSearchInput || forceResultsShown" class="flex-1">
        <search-box
          ref="searchBox"
          :placeholder="placeholderText"
          :class="{
            'border border-solid border-grey-light border-b-0 p-4': !isFixed,
            'md:border-l-0 text-inherit': !isFixed && filtersShown,
          }"
          @change="showSearchResults"
        />
      </div>
      <div v-if="(isCloseable && showSearchIcon) || forceShowSearchIcon" class="m-0 ml-auto">
        <button
          class="bg-blue-zodiac hover:bg-accent-blue-dark focus:bg-accent-blue-dark block w-full h-16 flex items-center text-white text-center"
          :aria-label="label"
          @click.prevent="onButtonClick"
        >
          <span class="px-4">Search</span>
          <icon :icon="icon" class="text-white block w-5 ml-auto mr-2" width="12" height="12" />
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";

import Icon from "@/components/atoms/icon/Icon";
import SearchBox from "@/search/components/query/SearchBox";

export default {
  components: {
    Icon,
    SearchBox,
  },
  props: {
    isFixed: {
      type: Boolean,
      default: false,
    },
    isCloseable: {
      type: Boolean,
      default: true,
    },
    showSearchIcon: {
      type: Boolean,
      default: true,
    },
    forceShowSearchIcon: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState("search", ["forceResultsShown", "filtersShown", "query", "queryHasFocus"]),
    ...mapGetters("search", ["resultsShown", "placeholderText"]),
    ...mapGetters("masthead", ["showSearchInput"]),
    ...mapState("masthead", ["menuExpanded"]),
    label() {
      return this.forceShowSearchIcon || !this.showSearchInput
        ? "Show site search"
        : "Hide site search";
    },
    icon() {
      return this.forceShowSearchIcon || !this.showSearchInput ? "search" : "close";
    },
  },
  methods: {
    ...mapActions("masthead", ["toggleSearch"]),
    onButtonClick() {
      this.$store.commit("masthead/HIDE_SEARCH", false);
      document.querySelector("body").style.overflow = this.menuExpanded ? "hidden" : "auto";
      return this.isCloseable ? this.toggleSearch() : this.showSearchResults();
    },
    showSearchResults() {
      if (!this.resultsShown && this.query.length) {
        this.toggleSearch();
      }
      this.focus();
    },
    focus() {
      this.$refs.searchBox.focus();
    },
  },
};
</script>
