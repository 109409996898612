<template>
  <modal
    class="mobileMenu"
    @close="toggleMenu"
    :style="!expanded ? 'transform: translateX(100%); ' : 'transform: translateX(0);'"
  >
    <nav>
      <HorizontalLine class="line firstLine" />
      <section v-if="loginMenu" class="loginMenu">
        <a href="/student-login" target="_blank"
          >Student Login <InlineArrow class="arrow" :name="'default-arrow'"
        /></a>
        <HorizontalLine class="line" />
        <a href="https://live.henley.ac.uk" target="_blank"
          >Alumni Login <InlineArrow class="arrow" :name="'default-arrow'"
        /></a>
        <HorizontalLine class="line" />
        <a href="https://www.henley.ac.uk/buzz" target="_blank"
          >Staff Login <InlineArrow class="arrow" :name="'default-arrow'"
        /></a>
        <HorizontalLine class="line" />
      </section>
      <ThemeProvider v-else>
        <masthead-navigation :items="items" />
        <a href="https://www.henleybusinessschool.cn/" target="_blank" class="language">中文</a>
      </ThemeProvider>
    </nav>
    <HorizontalLine class="line firstLine" />
    <div class="buttons">
      <a href="#" class="loginLink">
        <button class="login" @click="loginMenu = true">
          <div>
            <span> Login </span>
            <Icon icon="login-icon-blue" />
          </div>
        </button>
      </a>
      <a v-for="item in cta" :key="item.id" :href="item.link">
        <button class="registerInterest">{{ item.text }}</button>
      </a>
    </div>
  </modal>
</template>
<style scoped>
button:focus {
  outline: none;
}

.mobileMenu {
  position: fixed;
  z-index: 100;
  top: 70px;
  right: 0;
  width: 100vw;
  height: 100%;
  padding: 10px 0;
  padding-bottom: 85px;
  background: white;
  color: #333;
  overflow-x: hidden;
  overflow-y: auto;
  transition: transform 0.4s cubic-bezier(0.45, 0, 0.55, 1);
}

.loginMenu {
  min-height: 300px;
  background: #fafbff;
}

.loginMenu a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12.5px 35px 10px 20px;
  margin-bottom: 0;
  color: #333;
  font-size: 14px;
  opacity: 1;
}

.loginMenu .arrow {
  width: 12px;
}

.line {
  width: 100%;
  height: 1px;
  padding: 0 20px;
}

.firstLine {
  position: absolute;
  right: 0;
  left: 0;
  margin-top: 0;
}

.buttons {
  display: flex;
  flex-wrap: nowrap;
  padding: 10px 20px 0;

  /* font-family: "Graphik Bold", sans-serif; */
}

.buttons button {
  width: 99%;
  min-width: 140px;
  height: 40px;
  padding: 10px 25px 10px 25px;
  border-radius: 5px;
  font-weight: 500;
}

.buttons a {
  width: 50%;
}

.loginLink {
  margin-right: 10px;
}

.login {
  width: 50%;
  background: rgba(10, 59, 133, 0.05);
}

.login:hover {
  background: rgba(10, 59, 133, 0.1);
}

.login div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login span {
  position: relative;
  display: flex;
  max-width: 50px;
  align-items: center;
  margin-right: 10px;
}

.login svg {
  display: block;
  width: 12px;
}

.registerInterest {
  width: 50%;
  background: #ffcd00;
}

.registerInterest:hover {
  background: #ffba23;
}

.language {
  display: block;
  padding: 10px 20px;
  margin-bottom: 15px;
  color: black;
  font-size: 16px;
}
</style>
<script>
import { createNamespacedHelpers, mapState } from "vuex";

import Modal from "@/components/molecules/modal/Modal";
import MastheadNavigation from "../MastheadNavigation";
import HorizontalLine from "../elements/HorizontalLine";
import InlineArrow from "../elements/InlineArrow";
import ThemeProvider from "../../contexts/ThemeProvider";

import Icon from "@/components/atoms/icon/Icon";

const { mapState: mapMastheadState, mapActions: mapMastheadActions } = createNamespacedHelpers(
  "masthead"
);

export default {
  components: {
    Modal,
    MastheadNavigation,
    HorizontalLine,
    ThemeProvider,
    InlineArrow,
    Icon,
  },
  props: {
    expanded: {
      required: true,
    },
  },
  data() {
    return {
      loginMenu: false,
    };
  },
  computed: {
    ...mapState("ui", ["site"]),
    ...mapMastheadState(["items"]),
    cta() {
      return this.items.filter((item) => item.cta);
    },
  },
  methods: {
    ...mapMastheadActions(["toggleMenu"]),
  },
};
</script>
