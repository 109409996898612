import Vue from "vue";
import VueLazyload from "vue-lazyload";
import { isUndefined } from "lodash";
import SiteSearch from "@/components/assemblies/search/components/SiteSearch";

import store from "@/store/shared";

// import formatData from "@/components/assemblies/masthead/lib/formatData";

export const search = (configs) =>
  configs.map((CONFIG) => {
    store.commit("search/SET_POPULAR_SEARCHES", CONFIG.items[0]);
    store.commit("search/SET_SUGGESTIONS", CONFIG.items[1]);
    store.commit("search/SET_COURSE_SELECTIONS", CONFIG.items[2]);
    store.commit("search/SET_EVENT_SELECTIONS", CONFIG.items[3]);

    const searchContainer = document.querySelector(CONFIG.SELECTORS.CONTAINER);

    if (!searchContainer) {
      console.warn(`[site-search] No element found for selector ${CONFIG.SELECTORS.CONTAINER}`);
      return null;
    }

    Vue.use(VueLazyload, {
      preLoad: 1.3,
      error: CONFIG.PLACEHOLDER_IMAGE_URL,
      loading: CONFIG.PLACEHOLDER_IMAGE_URL,
      attempt: 1,
    });

    store.commit("search/SET_ALGOLIA_CONFIG", CONFIG.ALGOLIA);
    store.commit("search/SET_INDEX_NAME", CONFIG.INDEX_NAME);
    store.commit("search/SET_CONFIG", CONFIG);

    store.commit("search/SET_REQUIRE_RESULTS_WITH_URL", true);

    // set initial UI state

    if (CONFIG.START_OPEN) {
      store.commit("masthead/SHOW_SEARCH", true);
    }

    if (CONFIG.INITIAL_QS) {
      store.commit("search/STORE_QS", CONFIG.INITIAL_QS);
    }

    if (!isUndefined(CONFIG.QUERY_STRING_STATE_ENABLED)) {
      store.commit("search/SET_QUERY_STRING_STATE_ENABLED", CONFIG.QUERY_STRING_STATE_ENABLED);
    }

    /* eslint-disable no-new */
    return new Vue({
      el: CONFIG.SELECTORS.CONTAINER,
      name: "SiteSearchContainer",
      render: (h) => h(SiteSearch),
      store,
    });
  });
