<template>
  <div class="w-full flex flex-row justify-center" v-if="showButton">
    <button @click="loadMoreByCategory(category)">load more</button>
  </div>
</template>

<style scoped>
button {
  padding: 10px 15px;
  margin: 10px 0 13.5px;
  background: #fff;
  border-radius: 50px;
  box-shadow: 0 2px 30px rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  letter-spacing: 0.07em;
  line-height: 10px;
  text-transform: uppercase;
}
</style>

<script>
import { mapActions, mapState } from "vuex";

export default {
  props: {
    category: String,
  },
  computed: {
    ...mapState("search", ["resultsByCategory"]),

    showButton() {
      const returnedResultsCount = this.resultsByCategory[this.category].hits.length;

      const allResultsCount = this.resultsByCategory[this.category].nbHits;

      return returnedResultsCount < allResultsCount;
    },
  },
  methods: {
    ...mapActions("search", ["loadMoreByCategory"]),
  },
};
</script>
