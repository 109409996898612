import Mousetrap from "mousetrap";

export default {
  created() {
    this.$nextTick(() => {
      Mousetrap.bind("esc", () => this.hide());
      this.preventBodyScroll();
    });
  },
  beforeUnmount() {
    Mousetrap.unbind("esc");
    this.allowBodyScroll();
  },
  methods: {
    hide() {
      this.allowBodyScroll();
      this.$emit("hide");
    },
    preventBodyScroll() {
      document.body.style.overflowX = "hidden";
    },
    allowBodyScroll() {
      document.body.style.overflowX = "";
    },
  },
};
