<template>
  <svg :viewBox="viewBox" aria-hidden="true" :class="[icon]">
    <use :xlink:href="'#icon-' + icon" />
  </svg>
</template>
<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: "currentColor",
    },
    width: {
      type: [Number, String],
      default: 32,
    },
    height: {
      type: [Number, String],
      default: 32,
    },
  },
  computed: {
    viewBox() {
      return `0 0 ${this.width} ${this.height}`;
    },
    colorClass() {
      return `text-${this.color}`;
    },
  },
};
</script>
