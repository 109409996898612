<template>
  <component :is="useComponent" :hit="hit" :has-media-right="hasMediaRight" />
</template>
<script>
import capitalize from "underscore.string/capitalize";

export default {
  props: {
    hit: {
      required: true,
      type: Object,
    },
    hasMediaRight: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      components: {},
      previewType: "Snippet",
    };
  },
  computed: {
    componentHandle() {
      let result;

      switch (this.hit.typeHandle) {
        case "person":
          result = `People${this.previewType}`;
          break;
        default:
          result = `${capitalize(this.hit.sectionHandle)}${this.previewType}`;
          break;
      }

      return result;
    },
    useComponent() {
      return this.components[this.componentHandle]
        ? this.componentHandle
        : `Default${this.previewType}`;
    },
  },
};
</script>
