/**
 * Test if a URL is external to the current hostname
 */
const domainRe = /https?:\/\/((?:[\w\d-]+\.)+[\w\d]{2,})/i;

const getDomain = (url) => (domainRe.exec(url) ? domainRe.exec(url)[1] : null);

export const isExternalUrl = (url) => {
  const linkDomain = getDomain(url);
  // eslint-disable-next-line
  return linkDomain ?  linkDomain !== getDomain(location.href) : false;
};
