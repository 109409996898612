export default {
  computed: {
    tagMetaItems() {
      return [
        {
          title: this.hit.type,
        },
      ];
    },
    roles() {
      return this.hit.personRoles || [];
    },
    metaItems() {
      const email = this.hit.emailAddress
        ? {
            url: `mailto:${this.hit.emailAddress}`,
            title: this.hit.emailAddress,
          }
        : null;
      const phone = this.hit.phoneNumber
        ? {
            url: `tel:${this.hit.phoneNumber}`,
            title: this.hit.phoneNumber,
          }
        : null;

      return [].concat(this.roles.slice(0, 2)).concat([phone, email]).filter(Boolean);
    },
  },
};
