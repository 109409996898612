export const FACETS = [
  {
    name: "hasUrl",
    hidden: true,
    values: [],
  },
  {
    name: "siteId",
    hidden: true,
    values: [],
  },
  {
    name: "startDate",
    hidden: true,
  },
];

export const DISJUNCTIVE_FACETS = [
  {
    displayTitle: "Section",
    name: "section",
    type: "checkboxFacet",
    displayOrder: 1,
    values: [],
  },
  {
    displayTitle: "Level of study",
    name: "levelOfStudy",
    type: "checkboxFacet",
    displayOrder: 2,
    values: [],
  },
  {
    displayTitle: "Qualification level",
    name: "qualificationLevel",
    type: "checkboxFacet",
    displayOrder: 3,
    values: [],
  },
  {
    name: "elementStatus",
    hidden: true,
    values: [],
  },
  {
    name: "sectionHandle",
    hidden: true,
    values: [],
  },
];

export const NUMERIC_RANGE_REFINEMENTS = [
  // {
  //   displayTitle: 'BPM',
  //   name: 'bpm',
  //   type: 'numericRangeFilter',
  //   displayOrder: 1,
  //   values: {
  //     min: 80,
  //     max: 300,
  //   },
  //   bounds: {
  //     min: 80,
  //     max: 300,
  //   },
  // // }, {
  // //   displayTitle: 'Release year',
  // //   name: 'release.releaseYear',
  // //   type: 'numericRangeFilter',
  // //   displayOrder: 2,
  // //   values: [],
  // //   min: 2000,
  // //   max: 2020,
  // },
];
