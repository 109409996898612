const anchorLinks = document.querySelectorAll('a[href^="#"]');
if (anchorLinks.length > 0) {
  anchorLinks.forEach((anchor) => {
    anchor.addEventListener("click", (e) => {
      e.preventDefault();
      const anchorLocation = document.querySelector(anchor.getAttribute("href"));
      if (anchorLocation !== null) {
        anchorLocation.scrollIntoView({ behavior: "smooth" });
      }
    });
  });
}
