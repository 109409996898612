<template>
  <div class="p-4">
    <!-- eslint-disable vue/no-v-html -->
    <div class="pretty wysiwyg-output" v-if="message" v-html="message" />
    <!--    <reset-all-filters v-if="hasRefinements" class="" active-class-name="" title="Clear filters" />-->
  </div>
</template>

<script>
// import ResetAllFilters from "@/search/components/filters/ResetAllFilters";

export default {
  components: {
    // ResetAllFilters,
  },
  props: {
    message: {
      type: String,
      required: false,
      default: () => `<p>Sorry, no results found for that query.<p>`,
    },
    hasRefinements: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
