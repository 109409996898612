/* eslint-disable no-param-reassign */

import {
  FACETS,
  NUMERIC_RANGE_REFINEMENTS,
  DISJUNCTIVE_FACETS,
} from "@/components/assemblies/search/constants/facets";

import { CARD_UI, ALL_HIT_UI_MODES } from "@/components/assemblies/search/constants/hitUiModes";

import {
  toggleArrayValue,
  addUniqueArrayValue,
  rmUniqueArrayValue,
} from "@/store/helpers/arrayHelper";

import { facetsForSearch } from "@/store/helpers/facetHelper";

function rangeFilterForSearch(facets) {
  return facets.reduce((acc, facet) => {
    acc[facet.name] = {
      min: null,
      max: null,
    };
    return acc;
  }, {});
}

export const search = ({ CUSTOM_DISJUNCTIVE_FACETS = DISJUNCTIVE_FACETS, CUSTOM_FACETS = [] }) => {
  const facets = {
    ...facetsForSearch([...CUSTOM_FACETS, ...FACETS]),
    ...rangeFilterForSearch(NUMERIC_RANGE_REFINEMENTS),
    ...facetsForSearch(CUSTOM_DISJUNCTIVE_FACETS),
  };

  return {
    namespaced: true,
    state: {
      algoliaHelpers: [],
      algoliaHelper: null,
      derivedAlgoliaHelpers: {},
      query: "",
      page: null,
      indexName: "",
      isEnabled: true,
      isAlgoliaEventListenersEnabled: false,
      isSearchPage: false,

      algoliaReady: false,
      searchEnabled: true,
      forceSearchShown: false,
      hasSearched: false,
      hasInteracted: false,
      resetScroll: false,

      queryStringStateEnabled: false,
      showSearchOnInit: true,

      forceResultsShown: false,
      requireResultsWithUrl: false,

      limitToPastItems: false,
      limitToFutureItems: false,

      queryShown: true,
      queryHasFocus: false,

      paginationShown: true,
      resultsPerPageOptions: [15, 30, 60, 120],

      mobileFiltersShown: false,
      filtersShown: true,

      hitUiMode: CARD_UI,
      storedQs: "",
      params: {
        hitsPerPage: 30,
        maxValuesPerFacet: 30,
        getRankingInfo: true,
      },
      facets,
      config: {},
      hits: [],
      nbHits: null,
      nbPages: null,

      resultsByCategory: {
        courses: {
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
        },
        events: {
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
        },
        news: {
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
        },
        research: {
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
        },
        people: {
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
        },
        scholarships: {
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
        },
        other: {
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
        },
      },

      loadMore: false,
      mobileFilters: false,

      activeFilter: "all",
      activeLevelOfStudyFilter: ["all"],

      popularSearches: [],
      suggestions: [],

      courseSelections: [],
      eventSelections: [],
    },
    mutations: {
      SET_COURSE_SELECTIONS(state, data) {
        state.courseSelections = data;
      },
      SET_EVENT_SELECTIONS(state, data) {
        state.eventSelections = data;
      },
      SET_SUGGESTIONS(state, data) {
        state.suggestions = data;
      },
      SET_POPULAR_SEARCHES(state, data) {
        state.popularSearches = data;
      },
      MOBILE_FILTERS(state, data) {
        state.mobileFilters = data;
      },
      SET_RESULTS_BY_CATEGORY(state, { results, category }) {
        state.resultsByCategory[category] = results;
      },
      ACTIVE_FILTER(state, data) {
        state.activeFilter = data;
      },
      ACTIVE_LEVELOFSTUDY_FILTER(state, data) {
        state.activeLevelOfStudyFilter.push(data);
      },
      REMOVE_LEVELOFSTUDY_FILTER(state, data) {
        state.activeLevelOfStudyFilter.splice(state.activeLevelOfStudyFilter.indexOf(data), 1);
      },
      RESET_FILTER(state) {
        state.activeLevelOfStudyFilter = ["all"];
      },
      SET_CONFIG(state, config) {
        state.config = config;
      },
      LOAD_MORE(state, isShown) {
        state.loadMore = isShown;
      },
      SET_ALGOLIA_READY(state) {
        state.algoliaReady = true;
      },
      SET_QUERY_STRING_STATE_ENABLED(state, isEnabled) {
        state.queryStringStateEnabled = isEnabled;
      },
      TOGGLE_MOBILE_FILTERS(state) {
        state.mobileFiltersShown = !state.mobileFiltersShown;
      },
      SET_HIT_UI(state, uiMode) {
        if (ALL_HIT_UI_MODES.includes(uiMode)) {
          state.hitUiMode = uiMode;
        } else {
          console.error(`Unknown Hit UI mode ${uiMode}`);
        }
      },
      SET_SEARCH_ENABLED(state, isEnabled) {
        state.searchEnabled = isEnabled;
      },
      RESET_HAS_SEARCHED(state) {
        state.hasSearched = false;
      },
      RESET_HAS_INTERACTED(state) {
        state.hasInteracted = false;
      },
      RESET_SCROLL(state) {
        state.resetScroll = !state.resetScroll;
      },
      STORE_QS(state, qs) {
        state.storedQs = qs;
      },
      SET_PAGINATION_SHOWN(state, shown) {
        state.paginationShown = shown;
      },
      SET_QUERY_SHOWN(state, shown) {
        state.queryShown = shown;
      },
      SET_QUERY_FOCUS(state, hasFocus) {
        state.queryHasFocus = hasFocus;
        state.hasInteracted = true;
      },
      SET_FILTERS_SHOWN(state, shown) {
        state.filtersShown = shown;
      },
      SET_FORCE_RESULTS_SHOWN(state, shown) {
        state.forceResultsShown = shown;
      },
      SET_REQUIRE_RESULTS_WITH_URL(state, required) {
        state.requireResultsWithUrl = required;
      },
      SET_QUERY(state, query) {
        state.query = query;
        state.hasSearched = true;
      },
      CLEAR_SEARCH(state) {
        const arrayFacets = FACETS.concat(CUSTOM_DISJUNCTIVE_FACETS);

        state.query = "";
        state.hasSearched = true;

        arrayFacets.forEach(({ name }) => {
          state.facets[name] = [];
        });

        NUMERIC_RANGE_REFINEMENTS.forEach(({ name, bounds }) => {
          state.facets[name] = [bounds.min, bounds.max];
        });
      },
      SET_INDEX_NAME(state, indexName) {
        state.indexName = indexName;
      },
      SET_ALGOLIA_CONFIG(state, config) {
        state.config = config;
      },
      SET_PAGE(state, page) {
        state.page = parseInt(page, 10);
      },
      SET_HITS_PER_PAGE(state, hitsPerPage) {
        state.params.hitsPerPage = parseInt(hitsPerPage, 10);
      },
      SET_SEARCH_FACET_VALUE(state, { name, value }) {
        addUniqueArrayValue(state.facets[name], value);
        state.hasSearched = true;
      },
      UNSET_SEARCH_FACET_VALUE(state, { name, value }) {
        rmUniqueArrayValue(state.facets[name], value);
        state.hasSearched = true;
      },
      TOGGLE_SEARCH_FACET_VALUE(state, { name, value }) {
        toggleArrayValue(state.facets[name], value);
        state.hasSearched = true;
      },
      SET_RANGE_REFINEMENT(state, { name, values }) {
        state.facets[name] = values;
        state.hasSearched = true;
      },
      CLEAR_FACET(state, name) {
        state.facets[name] = [];
        state.hasSearched = true;
      },
      MARK_INTERACTION(state) {
        state.hasInteracted = true;
      },
      SET_RESULTS(state, { hits, nbHits, page, nbPages }) {
        state.hits = hits;
        state.nbHits = nbHits;
        state.page = page;
        state.nbPages = nbPages;
      },
      SET_SHOW_PAST_FUTURE_ITEMS(state, setting) {
        switch (setting) {
          case "pastOnly":
            state.limitToPastItems = true;
            break;
          case "futureOnly":
            state.limitToFutureItems = true;
            break;
          default:
            break;
        }
      },

      SET_ALGOLIA_HELPERS(state, algoliaHelpers) {
        state.algoliaHelpers = algoliaHelpers;
      },
      SET_ALGOLIA_DERIVED_HELPERS(state, { facetKey, facetKeyDerivedHelper }) {
        state.derivedAlgoliaHelpers[facetKey] = facetKeyDerivedHelper;
      },
      UPDATE_RESULT_BY_CATEGORY(state, { results, category }) {
        const newResults = results;
        const currentHitsInCategory = state.resultsByCategory[category].hits;
        newResults.hits = [...currentHitsInCategory, ...results.hits];

        state.resultsByCategory[category] = newResults;
      },
      SET_IS_ALGOLIA_EVENT_EMITTERS_ENABLED(state, toggleState) {
        state.isAlgoliaEventListenersEnabled = toggleState;
      },
      SET_IS_SEARCH_PAGE(state, toggleState) {
        state.isSearchPage = toggleState;
      },
    },
    getters: {
      query: (state) => state.query,

      params: (state, getters, rootState, rootGetters) =>
        Object.assign(state.params, {
          facets: rootGetters["facets/facetsForAlgolia"],
          disjunctiveFacets: rootGetters["facets/disjunctiveForAlgolia"],
        }),
      hits: ({ hits }) => hits,
      hitsOnPage: ({ hits }) => hits.length,
      totalHits: ({ nbHits }) => nbHits,
      resultsByCategory: ({ resultsByCategory }) => resultsByCategory,

      // filtered category hits in array
      courseFilteredHits: (state) => state.resultsByCategory.courses.hits.filter((hit) => hit),
      eventsFilteredHits: (state) => state.resultsByCategory.events.hits.filter((hit) => hit),
      newsFilteredHits: (state) => state.resultsByCategory.news.hits.filter((hit) => hit),
      researchFilteredHits: (state) => state.resultsByCategory.research.hits.filter((hit) => hit),
      peopleFilteredHits: (state) => state.resultsByCategory.people.hits.filter((hit) => hit),
      scholarshipsFilteredHits: (state) =>
        state.resultsByCategory.scholarships.hits.filter((hit) => hit),
      otherFilteredHits: (state) => state.resultsByCategory.other.hits.filter((hit) => hit),

      totalHitsForResultsByCategory: (state) =>
        state.resultsByCategory.courses.nbHits +
        state.resultsByCategory.events.nbHits +
        state.resultsByCategory.news.nbHits +
        state.resultsByCategory.research.nbHits +
        state.resultsByCategory.people.nbHits +
        state.resultsByCategory.other.nbHits,
      totalHitsV2: (state) =>
        state.resultsByCategory.courses.hits.length +
        state.resultsByCategory.events.hits.length +
        state.resultsByCategory.news.hits.length +
        state.resultsByCategory.research.hits.length +
        state.resultsByCategory.people.hits.length +
        state.resultsByCategory.other.hits.length,
      resultsShown({ forceResultsShown, hasSearched }, rootState, { masthead }) {
        return forceResultsShown || (masthead.searchExpanded && hasSearched);
      },
      firstHitOnPage: (state, { page, perPage }) => 1 + page * perPage,
      lastHitOnPage: (state, { firstHitOnPage, hitsOnPage }) => firstHitOnPage + (hitsOnPage - 1),
      placeholderUrl: ({ config }) => config.PLACEHOLDER_IMAGE_URL,
      page: (state) => state.page,
      pageForUi: (state) => state.page + 1,
      perPage: (state) => state.params.hitsPerPage,
      totalPages: ({ nbPages }) => nbPages,
      hasPrevPage: (state, { pageForUi }) => pageForUi > 1,
      hasNextPage: (state, { pageForUi, totalPages }) => pageForUi < totalPages,
      showMobileFilters(state, getters, rootState, rootGetters) {
        return rootGetters["ui/isMobile"] && state.mobileFiltersShown;
      },
      placeholderText({ config }) {
        let text = "Search courses, research, events and more...";

        if (config.FEED_CONFIG && config.FEED_CONFIG.PLACEHOLDER_TEXT) {
          text = config.FEED_CONFIG.PLACEHOLDER_TEXT;
        }

        return text;
      },
      noResultsMessage({ config }) {
        let message = null;

        if (config.FEED_CONFIG && config.FEED_CONFIG.NO_RESULTS) {
          message = config.FEED_CONFIG.NO_RESULTS;
        }

        return message;
      },
      hasRefinements(state) {
        let isRefined = false;

        const arrayFacets = FACETS.concat(CUSTOM_DISJUNCTIVE_FACETS);

        if (state.query.length) {
          isRefined = true;
        } else {
          arrayFacets.forEach(({ name }) => {
            if (state.facets[name].length) {
              isRefined = true;
            }
          });

          NUMERIC_RANGE_REFINEMENTS.forEach(({ name, bounds }) => {
            const defaultValue = [bounds.min, bounds.max];
            const currentValue = state.facets[name];

            if (currentValue[0] !== defaultValue[0] || currentValue[1] !== defaultValue[1]) {
              isRefined = true;
            }
          });
        }

        return isRefined;
      },
      noResults(state) {
        return (
          state.resultsByCategory.courses.hits.length === 0 &&
          state.resultsByCategory.events.hits.length === 0 &&
          state.resultsByCategory.news.hits.length === 0 &&
          state.resultsByCategory.research.hits.length === 0 &&
          state.resultsByCategory.people.hits.length === 0 &&
          state.resultsByCategory.other.hits.length === 0
        );
      },
    },
    actions: {
      async setPage({ commit, rootGetters }, { page }) {
        page = parseInt(page, 10);

        if (page > rootGetters.totalPages - 1) {
          page = rootGetters.totalPages - 1;
        }
        if (page <= 0) {
          page = 0;
        }

        commit("SET_PAGE", page);

        commit("RESET_SCROLL");
      },
      setResults({ commit }, payload) {
        commit("SET_RESULTS", payload);
      },
      setResultsByCategory({ commit }, payload) {
        commit("SET_RESULTS_BY_CATEGORY", payload);
      },
      pageNext({ dispatch, state }) {
        dispatch("setPage", { page: state.page + 1 });
      },
      pagePrev({ dispatch, state }) {
        dispatch("setPage", { page: state.page - 1 });
      },
      massSetFacetValues({ commit }, payload) {
        if (payload) {
          Object.entries(payload).forEach(([name, values]) => {
            values.forEach((value) => commit("SET_SEARCH_FACET_VALUE", { name, value }));
          });
        }
      },
      loadMoreByCategory({ state, commit, dispatch }, category) {
        dispatch("destroyEventListeners");

        const currentPageNumber = state.resultsByCategory[category].page;

        state.derivedAlgoliaHelpers[category].once("result", (result) => {
          commit("UPDATE_RESULT_BY_CATEGORY", {
            results: {
              hits: result.hits,
              nbHits: result.nbHits,
              page: result.page,
              nbPages: result.nbPages,
            },
            category,
          });
        });

        for (const algoliaHelper of state.algoliaHelpers) {
          algoliaHelper.setPage(currentPageNumber).nextPage().search();
        }
      },

      destroyEventListeners({ state, commit }) {
        for (const category in state.derivedAlgoliaHelpers) {
          state.derivedAlgoliaHelpers[category].removeAllListeners("result");
        }
        commit("SET_IS_ALGOLIA_EVENT_EMITTERS_ENABLED", false);
      },
    },
  };
};
