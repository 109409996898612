<template>
  <div class="m-snippet relative border-b border-lavender transition">
    <div
      class="hover:border-l-8 focus:border-l-8 border-brand-yellow p-6 md:py-9 md:px-19 transition"
    >
      <h3 class="h-trim text-md">
        <a
          v-if="hit.hasUrl"
          class="block no-underline"
          :href="hit.url"
          @click="trackGoResult(hit.uri)"
        >
          {{ hit.title }}
        </a>
        <span v-else class="block">
          {{ hit.title }}
        </span>
      </h3>

      <ul class="flex flex-wrap items-center mt-5">
        <li v-for="(item, index) in metaItems" :key="item + '-' + index" class="leading-none">
          <a v-if="item.url" :href="item.url" class="no-underline">{{ item }} </a
          ><span v-else>{{ item }}</span
          ><span
            v-if="index < metaItems.length - 1"
            role="presentation"
            class="text-brand-yellow px-1"
            >{{ metaSeparator }}
          </span>
        </li>
      </ul>

      <div>
        <a
          v-if="hit.centaurUrl"
          :key="hit.centaurUrl + '-item'"
          :href="hit.centaurUrl"
          class="inline-block mt-2 text-comet text-sm no-underline"
          rel="noopener noreferrer"
          target="_blank"
          >{{ hit.publicationReference }}
          <icon icon="external" class="inline w-5 h-5" />
        </a>
        <span
          v-else
          :key="hit.publicationReference + '-item'"
          class="inline-block mt-2 text-comet text-sm"
          >{{ hit.publicationReference }}
        </span>
      </div>

      <ul class="flex flex-wrap items-center mt-5">
        <li
          v-for="(item, index) in hit.publicationTypes"
          :key="item + '-' + index"
          class="font-bold text-xs leading-none"
        >
          {{ truncate(item, 90, "…")
          }}<span
            v-if="index < hit.publicationTypes.length - 1"
            role="presentation"
            class="text-brand-yellow px-1"
            >{{ metaSeparator }}
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import BaseHit from "../BaseHit";
import ResearchPublication from "../content-types/ResearchPublication";
import Icon from "@/components/atoms/icon/Icon";

export default {
  components: {
    Icon,
  },
  extends: BaseHit,
  mixins: [ResearchPublication],
};
</script>
