<script>
import CoachingRegisterTeaser from "./CoachingRegisterTeaser";
import CoursesTeaser from "./CoursesTeaser";
import DefaultTeaser from "./DefaultTeaser";
import DiscussionPapersTeaser from "./DiscussionPapersTeaser";
import EventsTeaser from "./EventsTeaser";
import HitBySection from "../HitBySection";
import NewsTeaser from "./NewsTeaser";
import PeopleTeaser from "./PeopleTeaser";
import ResearchPublicationsTeaser from "./ResearchPublicationsTeaser";
import ScholarshipsTeaser from "./ScholarshipsTeaser";
import TestimonialsTeaser from "./TestimonialsTeaser";

const components = {
  CoachingRegisterTeaser,
  CoursesTeaser,
  DefaultTeaser,
  DiscussionPapersTeaser,
  EventsTeaser,
  NewsTeaser,
  PeopleTeaser,
  ResearchPublicationsTeaser,
  ScholarshipsTeaser,
  TestimonialsTeaser,
};

export default {
  components,
  extends: HitBySection,
  data() {
    return {
      previewType: "Teaser",
      components,
    };
  },
};
</script>
