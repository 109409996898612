import store from "@/store/shared";

const SEARCH_PATH = "/search";

export default function initStateForSearchPage() {
  if (window.location.pathname === SEARCH_PATH) {
    store.commit("search/SET_IS_SEARCH_PAGE", true);
    store.commit("masthead/TOGGLE_SEARCH");
    store.commit("masthead/SEARCH_QUERY");
  }
}
