<template>
  <div class="breakpoint-debugger">
    <button @click.prevent="toggle">{{ isExpanded ? "Hide" : "Expand" }}</button>
    <div v-if="isExpanded">
      <component
        :is="currentValue(value)"
        v-for="(value, breakpoint) in breakpoints"
        :key="breakpoint"
      >
        {{ breakpoint }}: {{ value }}<br />
      </component>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "BreakpointDebugger",
  data() {
    return {
      isExpanded: false,
    };
  },
  computed: {
    ...mapState("ui", ["breakpoints"]),
  },
  methods: {
    toggle() {
      this.isExpanded = !this.isExpanded;
    },
    currentValue(applied) {
      return applied ? "strong" : "span";
    },
  },
};
</script>
<style lang="postcss" scoped>
.breakpoint-debugger {
  @apply fixed
    bg-black opacity-75
    text-white
    uppercase
    p-3
    bottom-0
    left-0
    z-50;
}

.applied {
  @apply font-bold;
}
</style>
