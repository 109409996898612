<!-- Primary Component
    Craft CMS Data:
      -items.heading ('anything in a string')
      -items.description ('anything in a string')
      -items.image ('anything in a string')
      -items.alt ('description of image')
      -items.link ('https://henley.imgix.net/v3/...')
      -items.search ('yes' or 'no')
      -items.searchPlaceholder ('anything in a string')
      -items.blockDivider ('yes' or 'no')

    Maximum no. of characters for Heading, Description, Placeholder:
    -Heading = 14
    -Description = 100
    -Placeholder = 16
-->
<template>
  <a :href="items.link" @mouseover="hover = true" @mouseleave="hover = false">
    <section>
      <div class="background" v-bind:style="{ backgroundImage: 'url(' + items.image + ')' }"></div>
      <div class="innerContainer">
        <h4 :class="{ 'hover-colour': hover }">
          {{ items.heading }}
          <span class="arrow">
            <inline-arrow
              :name="!hover ? 'default-arrow' : 'default-hover-arrow'"
              :class="{ 'active-animate': hover }"
              class="hover"
            />
          </span>
        </h4>
        <p>
          {{ items.description }}
        </p>
        <Search v-if="items.search == 'yes'" :placeholder="items.searchPlaceholder" />
      </div>
      <VerticalLine v-if="items.blockDivider == 'yes'" class="line" />
    </section>
  </a>
</template>
<style scoped>
h4 {
  display: flex;
  align-items: center;
  color: #333;
}

svg {
  width: 16px;
}

h4 svg {
  margin-left: 10px;
}

p {
  color: #333;
  font-weight: normal;
  font-weight: 400;
  line-height: 14px;
  opacity: 0.5;
}

section {
  display: flex;
  max-width: 440px;
}

section a {
  padding: 0;
}

section .innerContainer {
  max-width: 190px;
  margin-right: 5px;
  margin-left: 20px;
}

.hover-colour {
  color: #0a3b85 !important;
}

.arrow {
  position: relative;
}

.hover {
  position: absolute;
  top: -9px;
  left: 0;
  transition-duration: 0.2s;
}

.active-animate {
  transform: translateX(3px);
}

.image {
  width: 200px;
  max-width: 200px;
  height: 190px;
  max-height: 190px;
}

.background {
  width: 200px;
  max-width: 200px;
  height: 190px;
  max-height: 190px;
  background-size: cover;
  border-radius: 4px;
}

.line {
  height: 190px;
  padding: 0 15px;
  padding-left: 0;
  margin: 0;
  margin-left: 0;
}

@media (max-width: 1200px) {
  section .innerContainer {
    width: auto;
    margin-left: 15px;
  }

  .background {
    width: auto;
    min-width: 160px;
  }

  .line {
    margin-left: 10px;
  }

  .image {
    width: auto;
  }
}

@media (max-width: 1200px) {
  h4 {
    max-width: 135px;
  }
}
</style>
<script>
import InlineArrow from "@/components/assemblies/masthead/components/elements/InlineArrow";
import VerticalLine from "@/components/assemblies/masthead/components/elements/VerticalLine";
import Search from "@/components/assemblies/masthead/components/elements/Search";

export default {
  components: {
    InlineArrow,
    VerticalLine,
    Search,
  },
  data() {
    return {
      hover: false,
    };
  },
  props: {
    items: {
      type: Object,
    },
  },
};
</script>
