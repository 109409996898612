import Vue from "vue";
import CardSlider from "./CardSlider";
import VueSplide from "@splidejs/vue-splide";

Vue.use(VueSplide);

export const cardSlider = (configs) =>
  configs.map(
    (c) =>
      new Vue({
        el: c.el,
        name: "CardSliderWrapper",
        data: function () {
          return {
            items: c.items,
            fallback: c.fallback,
          };
        },
        render(h) {
          return h(CardSlider, {
            props: {
              items: c.items,
              fallback: c.fallback,
            },
          });
        },
      })
  );
