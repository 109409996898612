import Vue from "vue";
import Vuex from "vuex";

import { cloneDeep } from "lodash";

import { facetsModule } from "./modules/facets";
import { masthead } from "./modules/masthead";
import { search } from "./modules/search";
import { ui } from "./modules/ui";

import { breakpointPlugin } from "./plugins/breakpoint";
import { algoliaPlugin } from "./plugins/algolia";
import { algoliaPluginV2 } from "./plugins/algoliaV2";
import { trackingPlugin } from "./plugins/eventTracking";

Vue.use(Vuex);

export default (config = {}) => {
  const storeConfig = {
    modules: {
      facets: facetsModule(config),
      masthead,
      search: search(config),
      ui,
    },
    plugins: [algoliaPlugin(config), algoliaPluginV2(config), breakpointPlugin, trackingPlugin],
  };

  return new Vuex.Store(cloneDeep(storeConfig));
};
