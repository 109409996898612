<template>
  <component :is="hitComponent" :hit="hit" :has-media-right="useMediaRight" />
</template>
<script>
import { mapState } from "vuex";

import { CARD_UI, CARD_UI_RIGHT } from "@/search/constants/hitUiModes";

import BaseHit from "./BaseHit";
import SnippetHit from "./snippets/SnippetHit";
import TeaserHit from "./teasers/TeaserHit";

export default {
  components: {
    SnippetHit,
    TeaserHit,
  },
  extends: BaseHit,
  computed: {
    ...mapState("search", ["hitUiMode"]),
    hitComponent() {
      return this.isCardUi ? "TeaserHit" : "SnippetHit";
    },
    isCardUi() {
      return this.hitUiMode.indexOf(CARD_UI) > -1;
    },
    useMediaRight() {
      return this.hitUiMode === CARD_UI_RIGHT;
    },
  },
};
</script>
