<template>
  <ThemeConsumer v-slot="{ isDark, handleChangeTheme }">
    <li v-if="item.title" :class="{ active: show, inactive: !show }">
      <button :title="item.title" @click="toggle(isDark, handleChangeTheme)">
        <div>
          <p class="title" v-html="item.title" />
          <p v-html="item.description" />
        </div>
        <span v-if="isExternalLink" title="(external link)">
          <icon icon="external" height="8" width="8" class="icon" />
        </span>
        <Icon class="arrow" :class="{ 'up-down-arrow': show }" icon="dropdown-icon-blue" />
      </button>
      <section class="accordion">
        <mobile-accordion
          v-if="item.isExpanded"
          :id="paneId"
          :items="item.children"
          :parent="item"
          :item-class="[itemClass]"
        />
      </section>
      <HorizontalLine class="line" />
    </li>
  </ThemeConsumer>
</template>
<style scoped>
li {
  padding: 0 20px;
}

a {
  position: relative;
  font-size: 14px;
  font-weight: bold;
  line-height: 25px;
}

p {
  margin-right: 40px;
  margin-bottom: 0;
  color: #333;
  font-size: 13px;
  opacity: 0.5;
}

button {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  padding: 10px 0;
  color: black;
  font-size: 16px;
  text-align: left;
}

button:focus {
  outline: 0;
}

.title {
  margin-bottom: 2px;
  color: #333;
  font-size: 16px;
  opacity: 1;
}

.active {
  background: #fafbff;
}

.arrow {
  position: absolute;
  top: -15px;
  right: 20px;
  bottom: 0;
  width: 12px;
  margin: auto;
  transition: transform 450ms ease-in-out;
}

.icon {
  display: inline-block;
  width: 20px;
}

.line {
  width: 100%;
  height: 1px;
}

.up-down-arrow {
  transform: rotate(180deg);
}

.accordion button {
  padding-left: 15px;
  font-size: 14px;
}

.accordion .arrow {
  display: none;
}
</style>
<script>
import { createNamespacedHelpers } from "vuex";

import { isExternalUrl } from "@/lib/isExternalUrl";

import Icon from "@/components/atoms/icon/Icon";
import HorizontalLine from "@/components/assemblies/masthead/components/elements/HorizontalLine";
import MobileAccordion from "@/components/assemblies/masthead/components/mobile/MobileAccordion";
import ThemeConsumer from "@/components/assemblies/masthead/contexts/ThemeConsumer";

const { mapGetters: mapMastheadGetters } = createNamespacedHelpers("masthead");
const { mapGetters: mapUiGetters } = createNamespacedHelpers("ui");

export default {
  components: {
    Icon,
    HorizontalLine,
    MobileAccordion,
    ThemeConsumer,
  },
  data() {
    return {
      show: false,
      active: false,
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    parent: {
      type: Object,
      required: false,
      default: null,
    },
    itemClass: {
      type: [String, Array],
      required: false,
      default: "",
    },
  },
  computed: {
    ...mapMastheadGetters(["menuContracted"]),
    ...mapUiGetters(["is2lg"]),
    paneId() {
      return `masthead-mobile-navigation-pane-${this.item.id}`;
    },
    isExternalLink() {
      return this.isExternalUrl(this.item.url);
    },
    target() {
      return this.isExternalLink ? "_blank" : "_self";
    },
  },
  methods: {
    isExternalUrl,
    toggle(isDark, handleChangeTheme) {
      if (isDark) {
        handleChangeTheme({ key: "light" });
      } else {
        handleChangeTheme({ key: "dark" });
      }
      this.show = !this.show;
      this.$store.commit("masthead/TOGGLE_EXPAND_ITEM", {
        item: this.item,
        isExpanded: !this.item.isExpanded,
      });
    },
  },
};
</script>
