var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"overflow-y-auto h-screen overflow-x-hidden",attrs:{"id":"results-screen"}},[_c('div',{staticClass:"w-screen h-10 mt-5"},[(_vm.toggleSearchQuery && !_vm.isSearchPage)?_c('close-search-button',{staticClass:"xl:mr-24 mr-10 float-right",on:{"click":_vm.closeSearch}}):_vm._e()],1),_c('section',[(_vm.paginationShown && _vm.toggleSearchQuery && !_vm.isMobile)?_c('div',[_c('result-count')],1):_vm._e(),_c('div',{class:_vm.toggleSearchQuery && 'toggleSearchQuery',style:({
        marginBottom:
          _vm.searchExpanded && !_vm.toggleSearchQuery
            ? '15px'
            : _vm.activeFilter === 'courses' && !_vm.isMobile
            ? '130px'
            : '40px',
        marginTop: _vm.isMobile ? '15px' : '',
      })},[(_vm.queryShown)?_c('search-global-input'):_vm._e(),(_vm.paginationShown && _vm.toggleSearchQuery && _vm.isMobile)?_c('div',[_c('result-count')],1):_vm._e(),(_vm.filtersShown && _vm.toggleSearchQuery)?_c('desktop-filters'):_vm._e()],1),_c('section',[(_vm.searchExpanded && !_vm.toggleSearchQuery)?_c('div',{staticClass:"defaultLayout"},[(
            _vm.query.length > 0 &&
            _vm.courseFilteredHits.length === 0 &&
            _vm.eventsFilteredHits.length === 0 &&
            _vm.newsFilteredHits.length === 0 &&
            _vm.researchFilteredHits.length === 0 &&
            _vm.peopleFilteredHits.length === 0
          )?_c('div',[_c('NoResultsOptions')],1):_c('list',{attrs:{"title":_vm.query.length > 0 ? 'Suggestions' : 'Popular searches',"data":_vm.query.length > 0 ? _vm.suggestions : _vm.popularSearches}}),_c('default',{attrs:{"hits":this.resultsByCategory}})],1):_vm._e(),(_vm.searchExpanded && _vm.toggleSearchQuery)?_c('query-matches',{attrs:{"hits":this.resultsByCategory}}):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }