import Vue from "vue";
import TestimonialCarousel from "./TestimonialCarousel";

export const testimonialCarousel = (configs) =>
  configs.map(
    (c) =>
      new Vue({
        el: c.el,
        name: "TestimonialCarouselWrapper",
        data() {
          return {
            items: c.items,
            blockClass: c.blockClass,
            quoteClass: c.quoteClass,
          };
        },
        render(h) {
          return h(TestimonialCarousel, {
            props: {
              items: c.items,
              blockClass: c.blockClass,
              quoteClass: c.quoteClass,
            },
          });
        },
      })
  );
