import loadGoogleMapsApi from "load-google-maps-api";

function GoogleMap({ el, key, mapConfig, markers = [] }) {
  this.el = el;
  this.map = null;
  this.mapConfig = mapConfig;
  this.key = key;
  this.markers = markers;

  this.init();
}

GoogleMap.prototype = {
  constructor: GoogleMap,
  init() {
    loadGoogleMapsApi({
      key: this.key,
      region: "GB",
    }).then(() => {
      this.map = new window.google.maps.Map(document.querySelector(this.el), this.mapConfig);
      if (this.markers) {
        this.markers = this.markers.map((config) => this.addMarker(config, this.map));
      }
    });
  },
  addMarker(config, map) {
    const marker = new window.google.maps.Marker({ ...config, map });

    if (config.infoWindow && config.infoWindow.content) {
      const infoWindow = new window.google.maps.InfoWindow({
        content: this.getInfoWindowContent(config.infoWindow.content),
      });
      marker.addListener("click", () => infoWindow.open(map, marker));
    }

    return marker;
  },
  getInfoWindowContent: (parts) => {
    return `
      <p style="font-size: 15px">
        <strong>${parts.number ? `${parts.number},` : ""} ${parts.address}<br></strong>
        ${parts.city ? `${parts.city}<br>` : ""}
        ${parts.county ? `${parts.county}<br>` : ""}
        ${parts.country ? `${parts.country}<br>` : ""}
      </p>

    `;
  },
};

export const googleMap = (configs) => configs.map((c) => new GoogleMap(c));
