<template>
  <div class="text-grey text-sm">
    Per page:
    <button
      v-for="value in resultsPerPageOptions"
      :key="value"
      :class="{ 'text-brand-blue': isActive(value) }"
      class="mx-px px-px underline text-grey"
      @click.prevent="setPerPage(value)"
    >
      {{ value }}
    </button>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("search", ["perPage", "totalHits"]),
    ...mapState("search", ["resultsPerPageOptions"]),
  },
  methods: {
    setPerPage(value) {
      this.$store.commit("search/SET_HITS_PER_PAGE", value);
      this.$store.commit("search/MARK_INTERACTION");
    },
    isActive(value) {
      return value === this.perPage;
    },
  },
};
</script>
