<template>
  <section class="queryMatches">
    <p v-if="noResults && activeFilter === 'all'">
      <NoResultsOptions />
    </p>
    <div
      v-if="courseFilteredHits.length"
      :style="{ display: activeFilter === 'courses' || activeFilter === 'all' ? 'block' : 'none' }"
    >
      <span>
        <h2>Courses and services</h2>
      </span>
      <div class="hitsContainer">
        <div v-for="hit in filteredLevelOfStudy" :key="hit.name" :hit="hit">
          <card :hit="hit" />
        </div>

        <SimpleLoadMoreButton category="courses" />
      </div>
    </div>
    <p v-if="courseFilteredHits.length === 0 && activeFilter === 'courses'">
      <NoResultsOptions />
    </p>
    <div
      v-if="eventsFilteredHits.length"
      :style="{ display: activeFilter === 'events' || activeFilter === 'all' ? 'block' : 'none' }"
    >
      <span>
        <h2>Events</h2>
      </span>
      <div class="hitsContainer">
        <div v-for="hit in eventsFilteredHits" :key="hit.name" :hit="hit" class="smallImageWrapper">
          <card-small-image v-if="isMobile" :hit="hit" />
          <card-full-horizontal-image v-else :hit="hit" />
        </div>

        <SimpleLoadMoreButton category="events" />
      </div>
    </div>
    <p v-if="eventsFilteredHits.length === 0 && activeFilter === 'events'">
      <NoResultsOptions />
    </p>

    <div
      v-if="scholarshipsFilteredHits.length"
      :style="{
        display: activeFilter === 'scholarships' || activeFilter === 'all' ? 'block' : 'none',
      }"
    >
      <span>
        <h2>Scholarships</h2>
      </span>
      <div class="hitsContainer">
        <div
          v-for="hit in scholarshipsFilteredHits"
          :key="hit.name"
          :hit="hit"
          :style="{ width: isMobile ? '100%' : '' }"
          class="allTextWrapper"
        >
          <card-all-text :hit="hit" />
        </div>

        <SimpleLoadMoreButton category="scholarships" />
      </div>
    </div>

    <div
      v-if="newsFilteredHits.length"
      :style="{ display: activeFilter === 'news' || activeFilter === 'all' ? 'block' : 'none' }"
    >
      <span>
        <h2>News</h2>
      </span>
      <div class="hitsContainer">
        <div v-for="hit in newsFilteredHits" :key="hit.name" :hit="hit" class="smallImageWrapper">
          <card-small-image :hit="hit" />
        </div>

        <SimpleLoadMoreButton category="news" />
      </div>
    </div>
    <p v-if="newsFilteredHits.length === 0 && activeFilter === 'news'">
      <NoResultsOptions />
    </p>

    <div
      v-if="researchFilteredHits.length"
      :style="{
        display: activeFilter === 'research' || activeFilter === 'all' ? 'block' : 'none',
      }"
    >
      <span>
        <h2>Research</h2>
      </span>
      <div class="hitsContainer">
        <div v-for="hit in researchFilteredHits" :key="hit.name" :hit="hit" class="allTextWrapper">
          <card-all-text :hit="hit" />
        </div>

        <SimpleLoadMoreButton category="research" />
      </div>
    </div>
    <p v-if="researchFilteredHits.length === 0 && activeFilter === 'research'">
      <NoResultsOptions />
    </p>

    <div
      v-if="peopleFilteredHits.length"
      :style="{ display: activeFilter === 'people' || activeFilter === 'all' ? 'block' : 'none' }"
    >
      <span>
        <h2>People</h2>
      </span>
      <div class="hitsContainer">
        <div
          v-for="hit in peopleFilteredHits"
          :key="hit.name"
          :hit="hit"
          class="verticalImageWrapper"
        >
          <card-full-vertical-image :hit="hit" />
        </div>

        <SimpleLoadMoreButton category="people" />
      </div>
    </div>
    <p v-if="peopleFilteredHits.length === 0 && activeFilter === 'people'">
      <NoResultsOptions />
    </p>

    <div
      v-if="otherFilteredHits.length"
      :style="{ display: activeFilter === 'other' || activeFilter === 'all' ? 'block' : 'none' }"
    >
      <span>
        <h2>Other</h2>
      </span>
      <div class="hitsContainer">
        <div
          v-for="hit in otherFilteredHits"
          :key="hit.name"
          :hit="hit"
          :style="{ width: isMobile ? '100%' : '' }"
          class="allTextWrapper"
        >
          <card-all-text :hit="hit" />
        </div>

        <SimpleLoadMoreButton category="other" />
      </div>
    </div>
    <p v-if="otherFilteredHits.length === 0 && activeFilter === 'other'">
      <NoResultsOptions />
    </p>
  </section>
</template>

<style scoped>
h2 {
  margin: 0;
  color: #000;
  font-size: 25px;
  font-weight: 600;
  line-height: 50px;
}

span {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.queryMatches {
  padding: 0 90px 60px;
}

.hitsContainer {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
}

@media (max-width: 1439px) {
  .allTextWrapper {
    width: 100%;
  }
}

@media (max-width: 1375px) {
  .queryMatches {
    padding: 0 60px 50px;
  }
}

@media (max-width: 1340px) {
  .queryMatches {
    padding: 0 20px 50px;
  }
}

@media (max-width: 1024px) {
  h2 {
    margin-bottom: 10px;
    font-size: 17px;
    line-height: 21px;
  }

  span {
    margin-bottom: 15px;
  }

  .queryMatches {
    padding: 0 15px 50px;
  }
}

@media (max-width: 869px) {
  .smallImageWrapper {
    width: calc(100%);
  }
}

@media (max-width: 399px) {
  .verticalImageWrapper {
    width: 100%;
  }
}
</style>

<script>
import { mapState, mapGetters } from "vuex";

import Card from "@/components/assemblies/search/components/hits/Card";
import CardFullVerticalImage from "@/components/assemblies/search/components/hits/CardFullVerticalImage";
import CardAllText from "@/components/assemblies/search/components/hits/CardAllText";
import CardSmallImage from "@/components/assemblies/search/components/hits/CardSmallImage";
import CardFullHorizontalImage from "@/components/assemblies/search/components/hits/CardFullHorizontalImage";
import NoResultsOptions from "@/components/assemblies/search/components/results/NoResultsOptions";
import SimpleLoadMoreButton from "@/components/assemblies/search/components/results/SimpleLoadMoreButton";

export default {
  components: {
    Card,
    CardSmallImage,
    CardAllText,
    CardFullVerticalImage,
    CardFullHorizontalImage,
    NoResultsOptions,
    SimpleLoadMoreButton,
  },
  computed: {
    ...mapState("search", [
      "nbHits",
      "hits",
      "query",
      "loadMore",
      "activeFilter",
      "activeLevelOfStudyFilter",
      "resultsByCategory",
    ]),
    ...mapGetters("search", [
      "courseFilteredHits",
      "eventsFilteredHits",
      "newsFilteredHits",
      "researchFilteredHits",
      "peopleFilteredHits",
      "scholarshipsFilteredHits",
      "otherFilteredHits",
      "noResults",
    ]),
    ...mapGetters("ui", ["isMobile"]),
    filteredLevelOfStudy() {
      if (this.activeLevelOfStudyFilter.length > 1) {
        const filteredCourseHits = this.courseFilteredHits.filter((hit) => {
          if (hit.levelOfStudy) {
            return this.activeLevelOfStudyFilter.includes(hit.levelOfStudy[0]);
          }
        });

        return filteredCourseHits;
      }

      return this.courseFilteredHits;
    },
  },
};
</script>
