import { masthead } from "@/components/assemblies/masthead";
// import { search } from "@/search/index";
import { search } from "@/components/assemblies/search/index"; // new search ui
import { contentFeed, searchBanner } from "@/content-feed/index";
import { breakpointDebug } from "@/components/debug/breakpoint/index";

import { bindCookieNotice } from "@/components/molecules/cookie-notice/index";
import { bindToggles } from "@/components/molecules/toggle/index";
import { bindTabs } from "@/components/molecules/tabs/index";
import { bindMaxiAccordions } from "@/components/molecules/maxi-accordion/index";
import { bindMidiAccordions } from "@/components/molecules/midi-accordion/index";
import { bindHeroVideos } from "@/components/molecules/media/hero-videos/index";
import { bindCardSliderControls } from "@/components/molecules/card-slider-controls/index";
import { googleMap } from "@/components/molecules/google-map/index";
import { testimonialCarousel } from "@/components/molecules/testimonial-carousel/index";
import { cardSlider } from "@/components/molecules/card-slider/index";
import { imageCarousel } from "@/components/molecules/media/carousel/index";

bindCookieNotice();
bindMaxiAccordions();
bindMidiAccordions();
bindToggles();
bindTabs();
bindHeroVideos();
bindCardSliderControls();

export default {
  contentFeed,
  searchBanner,
  breakpointDebug,
  masthead,
  search,
  googleMap,
  testimonialCarousel,
  cardSlider,
  imageCarousel,
};
