export default {
  computed: {
    tagMetaItems() {
      const items = [
        {
          title: "Events",
        },
      ];

      if (this.hit.eventTypesNested && this.hit.eventTypesNested.length) {
        items.push(this.hit.eventTypesNested[0]);
      }
      return items;
    },
    metaItems() {
      return (
        []
          .concat(this.hit.startDateFormats.localeDate)
          .concat(this.hit.eventVenue)
          // .concat(this.hit.courseDuration)
          .filter(Boolean)
      );
    },
  },
};
