<template>
  <section>
    <ul v-if="is2lg" class="desktopNav menu">
      <li v-for="item in items" :key="item.id">
        <mega-navigation-item
          v-if="item.title"
          :item="item"
          :item-class="itemClass"
          :showNavbar="showNavbar"
        />
      </li>
    </ul>
    <ul v-else>
      <ThemeConsumer v-slot="{ theme, isDark }">
        <div
          :style="{ backgroundColor: theme.backgroundColor }"
          :class="{ dark: isDark, light: !isDark }"
        >
          <mobile-navigation-item
            v-for="item in items"
            :key="item.id"
            :item="item"
            :item-class="itemClass"
          />
        </div>
      </ThemeConsumer>
    </ul>
  </section>
</template>
<style>
.light span .inactive .title {
  color: #333;
  opacity: 0.5;
}

.menu {
  padding: 0;
  list-style: none;
}

.menu img {
  margin-left: 10px;
}

.menu li {
  display: block;
  border-bottom: 0;
  float: left;
}

.menu li ul {
  position: absolute;
  top: 80px;
  left: 0;
  margin: 0;
}
</style>
<script>
import { createNamespacedHelpers } from "vuex";

import MobileNavigationItem from "@/components/assemblies/masthead/components/mobile/MobileNavigationItem";
import MegaNavigationItem from "@/components/assemblies/masthead/components/desktop/MegaNavigationItem";
import ThemeConsumer from "@/components/assemblies/masthead/contexts/ThemeConsumer";

const { mapGetters: mapUiGetters } = createNamespacedHelpers("ui");

export default {
  components: {
    MobileNavigationItem,
    MegaNavigationItem,
    ThemeConsumer,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    showNavbar: {
      type: Boolean,
    },
    itemClass: {
      type: [String, Array],
      required: false,
      default: "",
    },
  },
  computed: {
    ...mapUiGetters(["is2lg"]),
  },
};
</script>
