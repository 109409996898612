<!-- Senary Component
    Craft CMS Data:
      -items.heading ('anything in a string')
      -items.link ('https://henley.imgix.net/v3/...')

    Maximum no. of characters for Heading:
    -Heading = 32 and overflow will turn to eclipse (...)
-->
<template>
  <section>
    <p class="title">{{ items.title }}</p>
    <p v-if="!items.children[0]">No upcoming events</p>
    <a
      v-if="items.children[0]"
      :href="items.children[0].url"
      @mouseover="one = true"
      @mouseleave="one = false"
    >
      <div>
        <p :class="{ event: one }">{{ items.children[0].title }}</p>
        <p class="date">{{ items.children[0].date }}</p>
      </div>
      <HorizontalLine v-if="items.children[0].horizontalLine == 'yes'" class="line" />
    </a>
    <a
      v-if="items.children[1]"
      :href="items.children[1].url"
      @mouseover="two = true"
      @mouseleave="two = false"
    >
      <div>
        <p :class="{ event: two }">{{ items.children[1].title }}</p>
        <p class="date">{{ items.children[1].date }}</p>
      </div>
      <HorizontalLine v-if="items.children[1].horizontalLine == 'yes'" class="line" />
    </a>
    <a
      v-if="items.children[2]"
      :href="items.children[2].url"
      @mouseover="three = true"
      @mouseleave="three = false"
    >
      <div>
        <p :class="{ event: three }">{{ items.children[2].title }}</p>
        <p class="date">{{ items.children[2].date }}</p>
      </div>
      <HorizontalLine v-if="items.children[2].horizontalLine == 'yes'" class="line" />
    </a>
  </section>
</template>
<style scoped>
section {
  max-width: 165px;
  margin-right: 20px;
}

p {
  overflow: hidden;
  width: 165px;
  margin-bottom: 0;
  color: #333;
  font-weight: 400;
  line-height: 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

a {
  width: 165px;
  align-items: center;
  margin-bottom: 11px;
}

.event {
  color: #0a3b85;
}

.date,
.title {
  margin-bottom: 15px;
  color: #333;
  font-weight: normal;
  line-height: 15px;
  opacity: 0.5;
}

.line {
  width: 180px;
  margin: 15px 0;
}

@media (max-width: 1340px) {
  section {
    width: auto;
    min-width: 120px;
    max-width: 140px;
  }

  a {
    width: auto;
    min-width: 120px;
  }

  p {
    width: auto;
    min-width: 120px;
  }

  .line {
    width: auto;
    min-width: 120px;
  }
}
</style>
<script>
import HorizontalLine from "@/components/assemblies/masthead/components/elements/HorizontalLine";

export default {
  components: {
    HorizontalLine,
  },
  data() {
    return {
      one: false,
      two: false,
      three: false,
    };
  },
  props: {
    items: {
      type: Object,
    },
  },
};
</script>
