<template>
  <div :class="{ 'lg:w-1/2': !hasMediaRight }" class="w-full">
    <div
      :class="{
        'pb-12': hit.hasUrl,
        'm-teaser-top': !hasMediaRight,
        'm-teaser-right': hasMediaRight,
      }"
    >
      <div
        :class="{
          'flex flex-wrap md:flex-row-reverse md:flex-row': hasMediaRight,
        }"
        class="md:px-2"
      >
        <div
          :class="{ 'w-full md:w-2/5 md:pl-12 md:max-w-md': hasMediaRight }"
          class="mb-6 relative"
        >
          <hit-image v-if="!hasMediaRight || hit.image" :hit="hit" />
        </div>
        <div :class="{ 'w-full md:w-3/5': hasMediaRight }">
          <h3 class="mb-1 text-md">
            <a
              v-if="hit.hasUrl"
              class="block no-underline focus:underline hover:underline"
              :href="hit.uri"
              @click="trackGoResult(hit.uri)"
            >
              {{ hit.title }}
            </a>
            <span v-else class="block text-lg font-bold">
              {{ hit.title }}
            </span>
          </h3>
          <div class="mb-4 leading-tight text-xs leading-none">
            <h4 class="font-bold text-xs inline-block mb-0 mr-2">Types of coaching offered:</h4>
            <span v-for="(item, index) in types" :key="item + '-' + index" class="leading-none">
              <a v-if="item.url" :key="item.title + '-item'" :href="item.url" class="no-underline">
                {{ item.title }}
              </a>
              <span v-else>
                {{ truncate(item, 90, "...") }}
              </span>
              <span v-if="index < metaItems.length - 1" class="text-brand-yellow px-1">
                {{ metaSeparator }}
              </span>
            </span>
          </div>
          <div v-if="locations.length" class="mb-4 leading-tight text-xs leading-none">
            <h4 class="font-bold text-xs inline-block mb-0 mr-2">Location(s):</h4>
            <span v-for="(item, index) in locations" :key="item + '-' + index" class="leading-none"
              >{{ item }}
              <span v-if="index < metaItems.length - 1" class="text-brand-yellow px-1">
                {{ metaSeparator }}
              </span>
            </span>
          </div>
          <slot name="excerpt">
            <h4 class="font-bold text-xs mb-0 mr-2">Academic profile:</h4>
            <div v-if="hit.description" class="text-xs mt-3 mb-0 trim" v-html="hit.description" />
          </slot>

          <div v-if="tagMetaItems.length" class="mt-6 mb-3">
            <span v-for="(metaItem, index) in tagMetaItems" :key="metaItem.title" class="meta">
              <a
                v-if="metaItem.url"
                :href="metaItem.url"
                :class="{ 'meta--accent-blue': index > 0 }"
              >
                <span class="meta__inner">{{ metaItem.title }}</span>
              </a>
              <span v-else :class="{ 'meta--accent-blue': index > 0 }">
                {{ metaItem.title }}
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DefaultTeaser from "./DefaultTeaser";
import Coach from "../content-types/Coach";

export default {
  extends: DefaultTeaser,
  mixins: [Coach],
};
</script>
