import { partial, debounce, isNull } from "lodash";

import algoliasearch from "algoliasearch/lite";
import algoliasearchHelper from "algoliasearch-helper";
import Vue from "vue";
import { mapState, mapGetters } from "vuex";

import { UrlStateManager } from "@/store/helpers/UrlStateManager";

import { FACETS, DISJUNCTIVE_FACETS, NUMERIC_RANGE_REFINEMENTS } from "@/search/constants/facets";

function mapFacetsState(facets) {
  return facets.reduce((acc, f) => {
    acc[f.name] = ({ $store }) => {
      return $store.state.search.facets[f.name];
    };
    return acc;
  }, {});
}

// executed bound to $vm instance
function FacetWatcherFactory(f, { addMethod }) {
  return function watchFacet(newValues) {
    if (this.isEnabled) {
      this.helper.clearRefinements(f.name);

      if (Array.isArray(newValues)) {
        newValues.forEach((v) => this.helper[addMethod](f.name, v));
      }

      this.search();
    }
  };
}

// function RangeWatcherFactory(f) {
//   return function watchRangeFacet(rangeValues) {
//     if (this.isEnabled) {
//       this.helper.clearRefinements(f.name);

//       this.helper.addNumericRefinement(f.name, ">=", rangeValues[0]);
//       this.helper.addNumericRefinement(f.name, "<=", rangeValues[1]);

//       this.search();
//     }
//   };
// }

function mapWatchers(factory, facets, opts) {
  return facets.reduce((acc, f) => {
    acc[f.name] = factory(f, opts);

    return acc;
  }, {});
}

const mapFacetWatchers = partial(mapWatchers, FacetWatcherFactory);
// const mapRangeFacetWatchers = partial(mapWatchers, RangeWatcherFactory);

const getTime = () => Math.floor(new Date().getTime() / 1000);

const AlgoliaPlugin = (
  store,
  { CUSTOM_DISJUNCTIVE_FACETS = DISJUNCTIVE_FACETS, CUSTOM_FACETS = [] }
) => {
  const facets = [...CUSTOM_FACETS, ...FACETS];

  return new Vue({
    store,
    data() {
      return {
        client: null,
        helper: null,
        urlStateManager: null,
        searchDebounceAmount: 60,
        // is the adaptor watching vuex state yet?
      };
    },
    computed: {
      ...mapState("search", [
        "algoliaReady",
        "searchEnabled",
        "config",
        "indexName",
        "isEnabled",
        "hasInteracted",
        "limitToFutureItems",
        "limitToPastItems",
        "requireResultsWithUrl",
        "showSearchOnInit",
      ]),
      ...mapGetters("search", ["page", "params", "perPage", "query", "resultsShown"]),

      ...mapFacetsState(facets),
      ...mapFacetsState(CUSTOM_DISJUNCTIVE_FACETS),
      // ...mapFacetsState(NUMERIC_RANGE_REFINEMENTS),
      vueAppId() {
        return this.config.ID;
      },
    },
    watch: {
      indexName() {
        this.initClient();
      },
      SEARCH_API_KEY() {
        if (!this.algoliaReady) {
          this.initClient();
        }
      },
      query(newValue) {
        if (this.isEnabled && newValue !== this.helper.state.query) {
          this.helper.setQuery(newValue);
          this.search();
        }
      },
      perPage(newValue) {
        if (this.isEnabled && newValue !== this.helper.state.hitsPerPage) {
          this.helper.setQueryParameter("hitsPerPage", newValue);
          this.search();
        }
      },
      page(page) {
        if (this.isEnabled && !isNull(page)) {
          this.helper.setPage(page);
          this.search();
        }
      },
      resultsShown(nowShown) {
        if (!nowShown) {
          this.urlStateManager.reset();
        } else if (this.resultsShown && this.hasInteracted) {
          this.urlStateManager.update(this.helper.getState());
        }
      },
      ...mapFacetWatchers(facets, {
        addMethod: "addFacetRefinement",
      }),
      ...mapFacetWatchers(CUSTOM_DISJUNCTIVE_FACETS, {
        addMethod: "addDisjunctiveFacetRefinement",
      }),
      // ...mapRangeFacetWatchers(NUMERIC_RANGE_REFINEMENTS),
    },
    created() {
      if (this.APPLICATION_ID && this.SEARCH_API_KEY) {
        this.initClient();
      }
    },
    methods: {
      initClient() {
        this.client = algoliasearch(
          this.config.ALGOLIA.APPLICATION_ID,
          this.config.ALGOLIA.SEARCH_API_KEY
        );

        this.helper = algoliasearchHelper(this.client, this.indexName, this.params);

        this.helper.on("result", this.handleResults);

        this.urlStateManager = new UrlStateManager(this.$store, this.vueAppId);

        // add enforced params (cannot be removed by user)

        // Show only live or pending entries
        this.helper.addDisjunctiveFacetRefinement("elementStatus", "live");
        this.helper.addDisjunctiveFacetRefinement("elementStatus", "pending");

        // Show only entries with post date in the past
        this.helper.addNumericRefinement("postDate", "<=", getTime());

        const siteId = this.$store.state.search.config.SITE.ID;

        if (siteId) {
          this.helper.addFacetRefinement("siteId", siteId);
        }

        if (this.requireResultsWithUrl) {
          this.helper.addFacetRefinement("hasUrl", "true");
        }

        if (this.limitToPastItems) {
          this.helper.addNumericRefinement("startDate", "<=", getTime());
        } else if (this.limitToFutureItems) {
          this.helper.addNumericRefinement("startDate", ">=", getTime());
        }

        this.search = debounce(() => {
          if (this.searchEnabled) {
            this.helper.search();
          }
        }, this.searchDebounceAmount);

        const qsState = this.urlStateManager.parse();

        if (this.showSearchOnInit && qsState) {
          this.loadState(qsState);
          this.$store.commit("masthead/SHOW_SEARCH", true);
        }

        this.$store.commit("search/SET_ALGOLIA_READY", true);
      },
      search() {},
      fetchById(objectID) {
        const $vm = this;

        const fetchObject = (id) => $vm.client.initIndex($vm.indexName).getObject(id);

        return new Promise((r) => {
          if ($vm.algoliaReady) {
            fetchObject(objectID).then((o) => r(o));
          } else {
            $vm.$watch("algoliaReady", () => {
              fetchObject(objectID).then((o) => r(o));
            });
          }
        });
      },
      handleResults(result) {
        this.$store
          .dispatch("search/setResults", {
            hits: result.hits,
            nbHits: result.nbHits,
            page: result.page,
            nbPages: result.nbPages,
          })
          .then(() => {
            this.$store.commit("search/SET_QUERY", result.query);

            result.facets.map((f) => this.setFacetValues(f, result));
            result.disjunctiveFacets.map((f) => this.setDisjunctiveValues(f, result));
            // result.getRefinements().map((r) => this.setRefinement(r));
            if (this.resultsShown && this.hasInteracted) {
              this.urlStateManager.update(this.helper.getState());
            }
          });
      },
      loadState(state) {
        this.helper.setState(state);

        // we need to directly set the numeric refinement min / max values here,
        // otherwise they get overwritten by the search that gets triggered immediately
        // after the filters are initted, due to the default values of the numeric
        // filters being likely different from those in the QS
        // if (!isUndefined(state.numericRefinements)) {
        //   this.setNumericRefinements(state);
        // }
        this.search();
      },
      setFacetValues(f, result) {
        this.$store.commit("facets/SET_FACET_VALUES", this.prepFacetValues(f, result));
      },
      setDisjunctiveValues(f, result) {
        this.$store.commit("facets/SET_DISJUNCTIVE_VALUES", this.prepFacetValues(f, result));
      },
      setNumericRefinements(state) {
        NUMERIC_RANGE_REFINEMENTS.forEach(({ name }) => {
          const refinement = state.numericRefinements[name];
          if (refinement) {
            Object.keys(refinement).forEach((operator) => {
              const value = refinement[operator][0];

              switch (operator) {
                case ">=":
                  this.$store.commit("search/SET_NUMERIC_RANGE_VALUE", {
                    name,
                    value,
                    type: "min",
                  });
                  break;
                case "<=":
                  this.$store.commit("search/SET_NUMERIC_RANGE_VALUE", {
                    name,
                    value,
                    type: "max",
                  });
                  break;
                default:
                  break;
              }
            });
          }
        });
      },
      // setRefinement(r) {
      //   switch (r.type) {
      //     case "numeric":
      //       this.setNumericRefinement(r);
      //       break;
      //     default:
      //       break;
      //   }
      // },
      prepFacetValues(f, result) {
        return {
          name: f.name,
          values: result.getFacetValues(f.name, { sortBy: ["name:asc"] }),
        };
      },
    },
  });
};

export const algoliaPlugin = (config) => {
  return (store) => {
    /* eslint-disable no-param-reassign */
    store.algoliaPlugin = new AlgoliaPlugin(store, config);
  };
};
