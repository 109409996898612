export default {
  computed: {
    tagMetaItems() {
      let items = [
        {
          title: this.hit.section,
        },
      ];

      if (this.hit.publicationTypesNested && this.hit.publicationTypesNested.length) {
        const types = this.hit.publicationTypesNested;

        if (types) {
          items = items.concat(types);
        }
      }
      return items;
    },
    metaItems() {
      return [].concat(this.hit.publicationYear).concat(this.hit.internalPeople).filter(Boolean);
    },
  },
};
