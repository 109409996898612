/* eslint-disable no-param-reassign */
import { find, orderBy } from "lodash";

import {
  FACETS,
  NUMERIC_RANGE_REFINEMENTS,
  DISJUNCTIVE_FACETS,
} from "@/components/assemblies/search/constants/facets";

import { setFacetValues } from "@/store/helpers/facetHelper";

export const facetsModule = ({
  CUSTOM_DISJUNCTIVE_FACETS = DISJUNCTIVE_FACETS,
  CUSTOM_FACETS = [],
}) => {
  const facets = [...CUSTOM_FACETS, ...FACETS];

  return {
    namespaced: true,
    state: {
      facets,
      numericRefinements: [],
      rangeRefinements: NUMERIC_RANGE_REFINEMENTS,
      disjunctiveFacets: CUSTOM_DISJUNCTIVE_FACETS,
    },
    mutations: {
      SET_FACET_VALUES(state, { name, values }) {
        setFacetValues(state, "facets", name, values);
      },
      // eslint-disable-next-line no-shadow
      SET_DISJUNCTIVE_FACETS(state, facets) {
        state.disjunctiveFacets = facets;
      },
      SET_DISJUNCTIVE_VALUES(state, { name, values }) {
        setFacetValues(state, "disjunctiveFacets", name, values);
      },
      SET_NUMERIC_REFINEMENT_VALUE(state, { name, value }) {
        setFacetValues(state, "numericRefinements", name, value);
      },
      SET_NUMERIC_RANGE_VALUE(state, { name, value, type }) {
        const refinement = find(state.rangeRefinements, { name });

        if (refinement) {
          refinement.values[type] = value;
        } else {
          throw new Error(`no refinement ${name}`);
        }
      },
    },
    getters: {
      // eslint-disable-next-line no-shadow
      filtersForUi({ facets, disjunctiveFacets, rangeRefinements }) {
        const displayedFacets = facets
          .concat(disjunctiveFacets)
          .concat(rangeRefinements)
          .filter((facet) => !facet.hidden);

        return orderBy(displayedFacets, "displayOrder");
      },
      // eslint-disable-next-line no-shadow
      facetsForAlgolia: ({ facets }) => facets.map((f) => f.name),
      // eslint-disable-next-line no-shadow
      disjunctiveForAlgolia: ({ disjunctiveFacets }) =>
        disjunctiveFacets.map((f) => f.name).concat(["status"]),
      rangesForAlgolia: ({ rangeRefinements }) =>
        rangeRefinements.map(({ name, values }) => ({ name, values })),
    },
    actions: {
      setFacetValues({ commit }, facet) {
        commit("SET_FACET_VALUES", facet);

        facet.values
          .filter((val) => val.isRefined)
          .forEach((val) =>
            commit("SET_SEARCH_FACET_VALUE", {
              name: facet.name,
              value: val.name,
            })
          );
      },
      setNumericRefinement({ commit }, r) {
        switch (r.operator) {
          case "<=":
            commit("SET_NUMERIC_RANGE_VALUE", {
              name: r.attributeName,
              value: r.numericValue,
              type: "max",
            });
            break;
          case ">=":
            commit("SET_NUMERIC_RANGE_VALUE", {
              name: r.attributeName,
              value: r.numericValue,
              type: "min",
            });
            break;
          case "=":
          default:
            commit("SET_NUMERIC_REFINEMENT_VALUE", {
              name: r.attributeName,
              value: r.numericValue,
            });
            break;
        }
      },
    },
  };
};
