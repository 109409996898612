import Vue from "vue";

import { mapState, mapGetters } from "vuex";

import trackingHelper from "@/components/assemblies/search/mixins/trackingHelper";

const TrackingPlugin = Vue.extend({
  mixins: [trackingHelper],
  computed: {
    ...mapGetters("search", ["pageForUi", "query", "totalHits"]),
    ...mapState("search", ["facets"]),
  },
  watch: {
    query(newQuery, oldQuery) {
      if (newQuery !== oldQuery) {
        this.trackQuery(newQuery);
      }
    },
    totalHits(total) {
      if (total === 0) {
        this.trackNoResults(this.query);
      }
    },
  },
  created() {
    this.$store.subscribe(this.mutationHandler);
  },
  methods: {
    isFacetApplied({ name, value }) {
      return this.facets[name].indexOf(value) > -1;
    },
    mutationHandler({ type, payload }) {
      let result;
      switch (type) {
        // show / hide search
        case "masthead/TOGGLE_SEARCH":
          result = this.trackQuery("");
          break;
        // change page
        case "search/SET_PAGE":
          result = this.trackGoResultPage(this.pageForUi);
          break;
        // facets
        case "search/TOGGLE_SEARCH_FACET_VALUE":
          result = this.isFacetApplied(payload)
            ? this.trackAddFacet(this.getFacetLabel(payload))
            : this.trackRemoveFacet(this.getFacetLabel(payload));
          break;
        case "search/CLEAR_FACET":
          result = this.trackRemoveFacet(this.getFacetLabel(payload));
          break;
        default:
          break;
      }
      return result;
    },
    getFacetLabel: ({ name, value }) => `${name}: ${value}`,
  },
});

export const trackingPlugin = (store) => {
  /* eslint-disable no-param-reassign */
  store.trackingPlugin = new TrackingPlugin({ store });
};
