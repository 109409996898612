<template>
  <div>
    <div class="relative">
      <div role="group" aria-roledescription="slide" :arial-label="ariaLabel">
        <figure class="w-full block relative z-10" aria-labelledby="">
          <img
            class="w-full block p-0 m-0 relative"
            :src="currentImage.url"
            :alt="currentImage.title"
          />
        </figure>
      </div>

      <div v-if="items.length > 1" class="absolute bottom-0 right-0 bg-white z-10">
        <carousel-controls
          :items="items"
          :current-index="currentIndex"
          text-color="accent-blue"
          @prev="goPrev"
          @next="goNext"
        />
      </div>
    </div>
    <!-- eslint-disable vue/no-v-html -->
    <span
      :id="id + '-caption'"
      class="w-full md:w-1/2"
      v-html="currentImage.caption || currentImage.title"
    />
  </div>
</template>
<script>
import CarouselControls from "./CarouselControls";

export default {
  components: {
    CarouselControls,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    id: {
      type: String,
      default: () => `carousel-${Math.floor(Math.random() * 9999999999999)}`,
    },
  },
  data() {
    return {
      currentIndex: 0,
    };
  },
  computed: {
    imageCount() {
      return this.items.length;
    },
    currentImage() {
      return this.items[this.currentIndex];
    },
    ariaLabel() {
      return `${this.currentIndex + 1} of ${this.items.length}`;
    },
  },
  methods: {
    goNext() {
      this.currentIndex = this.currentIndex === this.imageCount - 1 ? 0 : this.currentIndex + 1;
    },
    goPrev() {
      this.currentIndex = this.currentIndex === 0 ? this.imageCount - 1 : this.currentIndex - 1;
    },
  },
};
</script>
