import Vue from "vue";
import MidiAccordion from "./MidiAccordion";

const SELECTORS = {
  HEADING: "[data-accordion-heading]",
};

function Accordion(el) {
  const targetId = el.getAttribute("data-target");
  return new Vue({
    el,
    name: "MidiAccordionWrapper",
    data() {
      return {
        id: el.id,
        htmlClasses: [...el.classList],
        text: el.querySelector(SELECTORS.HEADING).innerHTML,
        targetId,
        target: document.querySelector(targetId),
      };
    },
    render(h) {
      return h(MidiAccordion, {
        props: {
          id: this.id,
          target: this.target,
          targetId: this.targetId,
          text: this.text,
        },
      });
    },
  });
}

export function bindMidiAccordions() {
  return [...document.querySelectorAll("[data-accordion='midi']")].map((el) => Accordion(el));
}
