<template>
  <div>
    <button
      title="Show search results as cards"
      aria-label="Show search results as cards"
      @click.prevent="setUiMode(uiModes.CARD_UI)"
    >
      <icon
        icon="search-grid"
        :class="isActiveHitUiMode(uiModes.CARD_UI) ? 'text-brand-blue' : 'text-grey'"
        class="inline-block w-5 mx-1"
      />
    </button>
    <button
      title="Show search results as a list"
      aria-label="Show search results as a list"
      @click.prevent="setUiMode(uiModes.LIST_UI)"
    >
      <icon
        icon="search-list"
        :class="isActiveHitUiMode(uiModes.LIST_UI) ? 'text-brand-blue' : 'text-grey'"
        class="inline-block w-5 mx-1"
      />
    </button>
  </div>
</template>
<script>
import { mapState } from "vuex";

import { CARD_UI, LIST_UI } from "@/search/constants/hitUiModes";

import Icon from "@/components/atoms/icon/Icon";

export default {
  components: {
    Icon,
  },
  data: () => ({
    uiModes: {
      CARD_UI,
      LIST_UI,
    },
  }),
  computed: {
    ...mapState("search", ["hitUiMode"]),
  },
  methods: {
    isActiveHitUiMode(hitUiMode) {
      return hitUiMode === this.hitUiMode;
    },
    setUiMode(uiMode) {
      return this.$store.commit("search/SET_HIT_UI", uiMode);
    },
  },
};
</script>
