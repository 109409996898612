<template>
  <default-snippet :hit="hit" :tag-meta-items-overide="tagMetaItems">
    <template v-slot:excerpt>
      <p v-if="hit.excerpt">"{{ hit.excerpt }}"</p>
      <p v-if="hit.caption" class="mt-2 text-sm">{{ hit.caption }}</p>
    </template>
  </default-snippet>
</template>
<script>
import DefaultSnippet from "./DefaultSnippet";
import Testimonial from "../content-types/Testimonial";

export default {
  components: {
    DefaultSnippet,
  },
  extends: DefaultSnippet,
  mixins: [Testimonial],
};
</script>
