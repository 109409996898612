<!-- @todo doc this in styleguide -->
<template>
  <div>
    <div class="overflow-x-hidden overflow-y-auto">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import modalHelper from "@/lib/mixins/modalHelper";

export default {
  mixins: [modalHelper],
  props: {
    bgColor: {
      type: String,
      default: "white",
    },
    top: {
      type: Number,
      default: 0,
    },
    zIndex: {
      type: Number,
      default: 30,
    },
  },
};
</script>
