<template>
  <div>
    <div class="flex flex-wrap xl:justify-center">
      <div class="w-full">
        <div class="bg-accent-blue px-6 py-6 text-center shadow-3xl">
          <div class="h-grid--right h-grid--left overflow-hidden">
            <div class="lg:pt-6 lg:pb-10 md:max-w-xl mx-auto bg-accent-blue relative z-10">
              <h2 v-if="heading" class="h3 text-white leading-none">{{ heading }}</h2>
              <div
                class="flex items-center pt-px pb-px mx-auto border-solid border-b border-blue-cadet"
              >
                <div class="flex-1 text-white h-12">
                  <div class="w-full h-full">
                    <label class="sr-only" :for="inputId">Enter search keywords</label>
                    <query-input
                      :id="inputId"
                      class="w-full h-full pr-4 block appearance-none bg-transparent text-inherit text-md leading-tight focus:outline-none"
                    />
                  </div>
                </div>
                <icon
                  icon="search"
                  color="blue-cadet"
                  class="w-4 md:w-5 h-4 md:h-5 ml-5"
                  width="20"
                  height="21"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <results v-if="resultsShown && hasSearched && searchEnabled"></results>
    <mobile-filters v-if="showMobileFilters && resultsShown"></mobile-filters>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";

import Icon from "@/components/atoms/icon/Icon";
import MobileFilters from "@/search/components/filters/MobileFilters";
import Results from "@/search/components/results/Results";
import QueryInput from "@/search/components/query/QueryInput";

export default {
  name: "SearchBanner",
  components: {
    Icon,
    MobileFilters,
    Results,
    QueryInput,
  },
  props: {
    heading: {
      type: [String, Boolean],
      default: "Search courses",
    },
    caption: {
      type: [String, Boolean],
      default: false,
    },
  },
  computed: {
    ...mapGetters("search", ["showMobileFilters", "resultsShown"]),
    ...mapState("search", ["hasSearched", "searchEnabled", "query"]),
    inputId() {
      return `search-keywords-${Math.floor(Math.random() * 9999999)}`;
    },
  },
  watch: {
    query(newQuery) {
      if (!newQuery) {
        this.$store.commit("search/RESET_HAS_SEARCHED");
        this.$store.commit("search/SET_SEARCH_ENABLED", false);
      } else if (!this.searchEnabled) {
        this.$store.commit("search/SET_SEARCH_ENABLED", true);
      }
    },
  },
};
</script>
