<template>
  <div>
    <results></results>
    <mobile-filters v-if="showMobileFilters"></mobile-filters>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import Results from "@/search/components/results/Results";
import MobileFilters from "@/search/components/filters/MobileFilters";

export default {
  name: "ContentFeed",
  components: {
    Results,
    MobileFilters,
  },
  computed: {
    ...mapGetters("search", ["showMobileFilters"]),
  },
};
</script>
