<template>
  <div>
    <div class="hitsContainer" style="margin-top: 60px">
      <span class="headingContainer">
        <h2>{{ heading }}</h2>
        <button @click="seeMore(category)" class="desktop">
          See more ({{ hitCount }}) <inline-arrow name="default-arrow" />
        </button>
      </span>
      <slot />
    </div>
    <button @click="seeMore(category)" class="mobile">
      See more ({{ hitCount }}) <inline-arrow name="default-arrow" />
    </button>
  </div>
</template>

<style scoped>
h2 {
  margin: 0;
  color: #000;
  font-size: 25px;
  font-weight: 600;
  line-height: 50px;
}

button {
  padding: 10px 15px;
  margin: 10px 0 13.5px;
  background: #fff;
  border-radius: 50px;
  box-shadow: 0 2px 30px rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  letter-spacing: 0.07em;
  line-height: 10px;
  text-transform: uppercase;
}

button svg {
  display: inline;
  width: 10px;
  margin-bottom: 2px;
  margin-left: 5px;
}

.headingContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.desktop {
  display: block;
}

.mobile {
  display: none;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: auto;
}

@media (max-width: 1439px) {
  .allTextWrapper {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  h2 {
    margin-bottom: 10px;
    font-size: 17px;
    line-height: 25px;
  }

  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }

  .hitsContainer {
    margin-bottom: 30px;
  }
}
</style>

<script>
import { mapActions } from "vuex";

import InlineArrow from "@/components/assemblies/masthead/components/elements/InlineArrow";

export default {
  components: {
    InlineArrow,
  },
  props: {
    heading: {
      type: String,
      required: true,
    },
    hitCount: {
      type: Number,
      required: true,
    },
    category: {
      type: String,
      required: true,
    },
  },
  methods: {
    ...mapActions("masthead", ["searchQuery"]),
    toTop() {
      const elmnt = document.getElementById("search-overlay");
      elmnt.scrollTo({ top: 0, behavior: "smooth" });
    },
    seeMore(value) {
      this.toTop();
      this.searchQuery();
      this.$store.commit("search/ACTIVE_FILTER", value);
    },
  },
};
</script>
