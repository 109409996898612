<template>
  <section>
    <all-filters />
  </section>
</template>
<script>
import AllFilters from "@/components/assemblies/search/components/filters/All";

export default {
  components: {
    AllFilters,
  },
};
</script>
