import Vue from "vue";
import Tabs from "./Tabs";

const SELECTORS = {
  NAV: "[data-tab-nav]",
  TRIGGER: "[data-tab-trigger]",
  TARGET: "[role='tabpanel']",
};

function MakeTabs(wrapper) {
  const el = wrapper.querySelector(SELECTORS.NAV);
  const classList = [...el.classList];
  const triggers = [...wrapper.querySelectorAll(SELECTORS.TRIGGER)].map((trigger) => {
    return {
      target: trigger.getAttribute("aria-controls"),
      id: trigger.id,
      text: trigger.innerHTML,
    };
  });

  const targets = [...wrapper.querySelectorAll(SELECTORS.TARGET)];

  const initialTarget = wrapper.querySelector(SELECTORS.TARGET);

  return new Vue({
    el,
    name: "TabsWrapper",
    data() {
      return {
        wrapper,
        triggers,
        targets,
        initialTarget,
        classList,
        id: wrapper.id,
      };
    },
    render(h) {
      return h(Tabs, {
        props: {
          id: this.id,
          initialTarget: this.initialTarget,
          wrapper: this.wrapper,
          targets: this.targets,
          triggers: this.triggers,
          classList: this.classList,
        },
      });
    },
  });
}

export function bindTabs() {
  return [...document.querySelectorAll("[data-tabs]")].map((el) => MakeTabs(el));
}
