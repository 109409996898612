<template>
  <section :class="toggleSearchQuery && 'searchGlobalInput'">
    <Icon icon="search-input" />
    <div class="search-wrapper">
      <input
        ref="input"
        type="search"
        :placeholder="placeholder"
        :value="query"
        name="search input"
        autocomplete="off"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="true"
        required
        @input="setQuery"
        v-on:keyup.enter="showSearchQuery"
      />
      <button @click="resetQuery">
        <Icon v-if="query.length > 0" icon="close-icon" class="inputClose" />
      </button>
      <button @click="showSearchQuery" v-if="query.length > 0" class="searchButton">SEARCH</button>
    </div>
  </section>
</template>
<style scoped>
section {
  position: relative;
  width: 620px;
  height: 50px;
}

.search-wrapper {
  top: 195px;
  left: 290px;
  display: flex;
  width: 620px;
  height: 50px;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  padding: 8px 5px 8px 45px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #fff;
  border-radius: 50px;
  font-size: 16px;
}

input {
  width: 100%;
}

input::placeholder {
  color: rgba(0, 0, 0, 0.5);
}

input::-webkit-search-cancel-button {
  display: none;
}

input[type="search"],
input[type="search"]::-webkit-search-decoration {
  appearance: none;
}

input:focus {
  outline: 0;
}

button:hover {
  cursor: pointer;
}

.searchButton {
  top: 5px;
  right: 5px;
  padding: 15px 20px;
  background: #0a3b85;
  border-radius: 100px;
  color: white;
  font-size: 12px;
  line-height: 10px;
}

.inputClose {
  top: 5px;
  right: 100px;
  width: 40px;
}

.search-input {
  position: absolute;
  top: 18px;
  left: 20px;
  width: 15px;
  height: 15px;
}

.searchGlobalInput {
  width: 407px;
}

.searchGlobalInput .search-wrapper {
  width: 407px;
}

.searchGlobalInput .searchButton {
  display: none;
}

.searchGlobalInput .inputClose {
  top: 8px;
  right: 8px;
  width: 35px;
}

@media (max-width: 1300px) {
  section {
    width: auto;
  }

  .search-wrapper {
    width: calc(100% - 150px);
  }

  .searchGlobalInput {
    width: 100%;
  }

  .searchGlobalInput .search-wrapper {
    width: 100%;
  }

  .searchButton {
    right: 155px;
  }

  .inputClose {
    right: 250px;
  }
}

@media (max-width: 1024px) {
  .search-wrapper {
    width: calc(100% - 50px);
  }

  .searchButton {
    right: 55px;
  }

  .inputClose {
    /* right: 150px; */
    display: none;
  }
}
</style>
<script>
import { debounce } from "lodash";
import { createNamespacedHelpers, mapActions, mapState } from "vuex";

const { mapState: mapSearchState } = createNamespacedHelpers("search");

import Icon from "@/components/atoms/icon/Icon";

export default {
  components: {
    Icon,
  },
  props: {
    placeholder: {
      type: String,
      default: "Search courses, research, events and more...",
    },
  },
  computed: {
    ...mapSearchState(["query"]),
    ...mapState("masthead", ["searchExpanded", "toggleSearchQuery"]),
  },
  methods: {
    ...mapActions("masthead", ["searchQuery"]),
    showSearchQuery() {
      return this.searchQuery();
    },
    setQuery: debounce(function debouncedSetQuery(e) {
      this.$store.commit("search/SET_QUERY", e.target.value.trim());
      this.$store.commit("search/SET_QUERY", e.target.value.trim());
      this.$emit("change");
    }, 400),
    resetQuery() {
      this.$store.commit("search/SET_QUERY", "");
    },
  },
  beforeMount() {
    this.resetQuery();
  },
  mounted() {
    this.$refs.input.focus();
  },
  watch: {
    searchExpanded(newSearchExpandedVal) {
      if (newSearchExpandedVal) {
        this.$refs.input.focus();
      }
    },
  },
};
</script>
