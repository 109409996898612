<template>
  <a :href="hit.uri">
    <section>
      <span v-if="hit.image" v-bind:style="{ backgroundImage: 'url(' + hit.image.url + ')' }">
      </span>
      <span v-else>
        <Icon icon="news" class="placeholder" />
      </span>
      <div>
        <h4
          v-html="highlight(hit.title)"
          :style="{ fontWeight: !toggleSearchQuery && query.length === 0 ? '600' : '100' }"
        ></h4>
        <p v-if="hit.unifiedDate">
          {{ hit.unifiedDateFormats.localeDate }}
        </p>
      </div>
    </section>
  </a>
</template>
<style scoped>
section {
  position: relative;
  display: flex;
  width: 407px;
  height: auto;
  min-height: 120px;
  padding: 15px;
  margin: 0 13px 20px 0;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 30px rgba(0, 0, 0, 0.04);
}

div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

h4 {
  margin: 0;
  color: #000;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
}

p {
  margin: 0;
  color: #000;
  font-size: 12px;
  line-height: 15px;
  opacity: 0.6;
}

span {
  display: block;
  overflow: hidden;
  min-width: 90px;
  max-width: 90px;
  min-height: 90px;
  max-height: 90px;
  margin-right: 20px;
  background-position: center;
  background-size: cover;
  border-radius: 5px;
}

.clock {
  display: inline;
  width: 15px;
  margin-right: 5px;
  margin-bottom: 3px;
}

@media (max-width: 869px) {
  section {
    width: calc(100%);
  }
}
</style>
<script>
import { mapState } from "vuex";

import Icon from "@/components/atoms/icon/Icon";

export default {
  components: {
    Icon,
  },
  props: {
    hit: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState("search", ["query"]),
    ...mapState("masthead", ["toggleSearchQuery"]),
  },
  methods: {
    highlight(content) {
      if (!this.query) {
        return content;
      }
      if (content !== null) {
        return content.replace(new RegExp(this.query, "gi"), (match) => {
          return '<span style="font-weight: bold">' + match + "</span>";
        });
      }
    },
  },
};
</script>
