export default {
  computed: {
    tagMetaItems() {
      const items = [
        {
          title: "Course",
        },
      ];

      if (this.hit.levelOfStudy && this.hit.levelOfStudy.length) {
        items.push({
          title: this.hit.levelOfStudy[0],
        });
      }

      return items;
    },
    metaItems() {
      const items = [];

      if (this.hit.qualificationLevel && this.hit.qualificationLevel.length) {
        items.push(this.hit.qualificationLevel[0]);
      }

      return items.concat(this.hit.studyMode).concat(this.hit.courseDuration).filter(Boolean);
    },
  },
};
