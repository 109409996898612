<template>
  <section class="innerMenuExpanded">
    <PrimaryComponent v-for="item in primaryComponent" :key="item.id" :items="item" />
    <section class="megNavSecondaryComponent">
      <SecondaryComponent v-for="item in secondaryComponent" :key="item.id" :items="item" />
    </section>
    <section class="megNavTertiaryComponent">
      <TertiaryComponent v-for="item in tertiaryComponent" :key="item.id" :items="item" />
    </section>
    <VerticalLine v-for="item in tertiaryBlockDivider" :key="item.id" class="line" />
    <section
      v-for="item in quaternaryTitleComponent"
      :key="item.id"
      class="megNavQuaternaryComponent"
    >
      <p class="title" v-for="item in quaternaryTitleComponent" :key="item.id">
        {{ item.title }}
      </p>
      <QuaternaryComponent v-for="item in quaternaryComponent" :key="item.id" :items="item" />
    </section>
  </section>
</template>
<style scoped>
.innerMenuExpanded {
  display: flex;
  width: 1300px;
  margin: auto;
  margin-top: 0;
}

.line {
  height: 190px;
  padding: 0 20px;
  margin: 0;
  margin-left: 0;
}

.noPadding {
  padding-left: 0;
  margin-left: 20px;
}

.title {
  margin-bottom: 10px;
  color: #333;
  font-weight: normal;
  line-height: 15px;
  opacity: 0.5;
}

.megNavSecondaryComponent {
  display: flex;
  max-width: 660px;
  margin: 0;
}

.megNavTertiaryComponent {
  display: flex;
  flex-direction: column;
}

.megNavQuaternaryComponent {
  width: 165px;
  margin-right: 15px;
}

@media (max-width: 1340px) {
  .innerMenuExpanded {
    width: inherit;
  }

  .title {
    width: auto;
  }

  .megNavQuaternaryComponent {
    width: auto;
    min-width: 120px;
  }
}
</style>
<script>
import PrimaryComponent from "@/components/assemblies/masthead/components/desktop/mega-nav/PrimaryComponent";
import SecondaryComponent from "@/components/assemblies/masthead/components/desktop/mega-nav/SecondaryComponent";
import TertiaryComponent from "@/components/assemblies/masthead/components/desktop/mega-nav/TertiaryComponent";
import QuaternaryComponent from "@/components/assemblies/masthead/components/desktop/mega-nav/QuaternaryComponent";
import VerticalLine from "@/components/assemblies/masthead/components/elements/VerticalLine";

export default {
  components: {
    PrimaryComponent,
    SecondaryComponent,
    TertiaryComponent,
    QuaternaryComponent,
    VerticalLine,
  },
  props: {
    items: {
      type: Array,
    },
  },
  computed: {
    primaryComponent() {
      return this.items.filter((item) => item.blockType === "Primary Component");
    },
    secondaryComponent() {
      return this.items.filter((item) => item.blockType === "Secondary Component");
    },
    secondaryBlockDivider() {
      return this.items.filter(
        (item) => item.blockType === "Secondary Component" && item.blockDivider === "yes"
      );
    },
    tertiaryComponent() {
      return this.items.filter((item) => item.blockType === "Tertiary Component");
    },
    tertiaryBlockDivider() {
      return this.items.filter(
        (item) => item.blockType === "Tertiary Component" && item.blockDivider === "yes"
      );
    },
    quaternaryTitleComponent() {
      return this.items.filter((item) => item.blockType === "Title For Quaternary Component");
    },
    quaternaryComponent() {
      return this.items.filter((item) => item.blockType === "Quaternary Component");
    },
  },
};
</script>
