<template>
  <div class="m-snippet relative border-b border-lavender transition">
    <div
      class="hover:border-l-8 focus:border-l-8 border-brand-yellow p-6 md:py-9 md:px-19 transition"
    >
      <!-- SD: 09/10/2019 Disabled per v4 redesign changes
      <div class="">
        <span
          v-for="(metaItem, index) in renderedTagMetaItems"
          :key="metaItem.title"
          class="inline-block"
        >
          <a
            v-if="metaItem.url"
            :href="metaItem.url"
            :class="{ 'meta--accent-blue': index > 0 }"
            class="meta"
          >
            <span class="meta__inner">{{ metaItem.title }}</span>
          </a>
          <span v-else :class="{ 'meta--accent-blue': index > 0 }" class="meta">{{
            metaItem.title
          }}</span>
        </span>
      </div>
      -->

      <div class="flex flex-wrap">
        <div class="w-full lg:flex-1 min-w-1/2">
          <div class="lg:pr-12" :class="{ 'mb-5': hit.text }">
            <h3 class="h-trim text-md">
              <a
                v-if="hit.hasUrl"
                class="block no-underline"
                :href="hit.uri"
                @click="trackGoResult(hit.uri)"
              >
                {{ hit.title }}
              </a>
              <span v-else class="block">
                {{ hit.title }}
              </span>
            </h3>
          </div>

          <template v-for="(metaItem, i) in subMetaItems">
            <a
              v-if="metaItem.url"
              :key="metaItem.title + '-item'"
              :href="metaItem.url"
              class="block mt-4 text-comet text-sm no-underline"
            >
              {{ metaItem.title }}
            </a>
            <span
              v-else
              :key="metaItem.title + '-item'"
              class="block mt-4 text-comet text-sm no-underline"
            >
              {{ metaItem.title }}
            </span>
            <span
              v-if="i < subMetaItems.length - 1"
              :key="metaItem.title + '-sep'"
              class="text-grey"
              role="presentation"
              >{{ subMetaSeparator }}</span
            >
          </template>

          <!-- SD: 09/10/2019 Disabled per v4 redesign changes
          <slot name="excerpt">
            <div v-if="hit.excerpt">
              <p v-if="hit.hasUrl" class="mt-3 mb-0">{{ truncate(hit.excerpt, 200, "...") }}</p>
              <p v-else class="mt-3 mb-0">{{ hit.excerpt }}</p>
            </div>
          </slot>
          -->
        </div>

        <div class="w-full mt-2 lg:mt-0 lg:w-auto lg:ml-12 lg:text-right">
          <div class="flex flex-wrap items-center">
            <span
              v-for="(item, index) in metaItems"
              :key="item + '-' + index"
              class="font-bold text-xs leading-none"
            >
              <a
                v-if="item.url"
                :key="item.title + '-item'"
                :href="item.url"
                class="font-bold text-xs no-underline"
              >
                {{ item.title }}
              </a>
              <span v-else>
                {{ truncate(item, 90, "...") }}
              </span>
              <span v-if="index < metaItems.length - 1" class="text-brand-yellow px-1">
                {{ metaSeparator }}
              </span>
            </span>
            <a
              v-if="hit.url"
              :href="hit.url"
              class="ml-4 px-2 text-accent-blue"
              :title="hit.title"
              role="presentation"
              target="_blank"
            >
              <icon
                icon="chevron-right"
                class="w-5 leading-none text-currentColor hover:text-accent-blue focus:text-accent-blue transition"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseHit from "../BaseHit";
import Icon from "@/components/atoms/icon/Icon";

export default {
  components: {
    Icon,
  },
  extends: BaseHit,
  computed: {
    metaItems() {
      return [];
    },
  },
};
</script>
