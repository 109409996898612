/* eslint-disable no-param-reassign */

function findByTitle(arr, item) {
  return arr.find((i) => i.title === item.title);
}

function flatenNav(items) {
  return items.reduce((acc, item) => {
    acc.push(item);

    if (item.children) {
      acc = [...acc, flatenNav(item.children)];
    }

    return acc;
  }, []);
}

function closeAllNavItems(items) {
  flatenNav(items).forEach((item) => {
    if (item.isExpanded) {
      item.isExpanded = false;
    }
  });
}

export const masthead = {
  namespaced: true,
  state: {
    items: [],
    navigationBanner: [],
    searchExpanded: false,
    menuExpanded: false,
    searchMobileExpanded: false,
    toggleSearchQuery: false,
  },
  mutations: {
    SET_ITEMS(state, items) {
      state.items = items;
    },
    SET_NAVIGATION_BANNER(state, items) {
      state.navigationBanner = items;
    },
    TOGGLE_EXPAND_ITEM(state, { item, isExpanded }) {
      let stateItem;

      stateItem = findByTitle(state.items, item);

      if (stateItem) {
        stateItem.isExpanded = isExpanded;
      }
    },
    SEARCH_QUERY(state) {
      state.toggleSearchQuery = true;
    },
    SHOW_SEARCH(state) {
      state.searchExpanded = true;
      state.menuExpanded = false;
      state.toggleSearchQuery = false;
    },
    HIDE_SEARCH(state, hide) {
      state.menuExpanded = hide;
    },
    MOBILE_SEARCH(state, isExpanded) {
      state.searchMobileExpanded = isExpanded;
    },
    HIDE_SEARCHV2(state) {
      state.searchExpanded = false;
      state.menuExpanded = false;
      state.toggleSearchQuery = false;
    },
    TOGGLE_SEARCH(state) {
      state.searchExpanded = !state.searchExpanded;

      if (state.searchExpanded) {
        state.menuExpanded = false;
      }
    },
    TOGGLE_MENU(state) {
      if (state.menuExpanded) {
        closeAllNavItems(state.items);
      }

      state.menuExpanded = !state.menuExpanded;

      if (state.menuExpanded) {
        state.searchExpanded = false;
      }
    },
  },
  getters: {
    useContactedMenu(state, getters, rootState, rootGetters) {
      return !rootGetters["ui/isMd"] && !rootGetters["ui/isXl"];
    },
    menuContracted(state, getters) {
      return getters.useContactedMenu && !state.menuExpanded;
    },
    showSearchInput(state, getters, rootState, rootGetters) {
      return state.searchExpanded || (!state.searchExpanded && !rootGetters["ui/isMobile"]);
    },
  },
  actions: {
    toggleSearch({ commit }) {
      commit("TOGGLE_SEARCH");
    },
    toggleSearchV2({ commit }) {
      commit("SHOW_SEARCH");
    },
    hideSearch({ commit }) {
      commit("HIDE_SEARCH");
    },
    hideSearchV2({ commit }) {
      commit("HIDE_SEARCHV2");
    },
    toggleMenu({ commit }) {
      commit("TOGGLE_MENU");
    },
    searchQuery({ commit }) {
      commit("SEARCH_QUERY");
    },
  },
};
