import Vue from "vue";
import qs from "qs";
import algoliasearchHelper from "algoliasearch-helper";

import { mapState } from "vuex";
import { createBrowserHistory } from "history";

import findOne from "@/lib/findOne";

export function UrlStateManager(store, instanceId) {
  return new Vue({
    store,
    data() {
      return {
        history: createBrowserHistory(),
        instanceId,
        ignoreQsParams: [
          "sep",
          "utm_source",
          "utm_medium",
          "utm_campaign",
          "utm_term",
          "utm_content",
        ],
      };
    },
    computed: {
      ...mapState("search", ["storedQs", "queryStringStateEnabled"]),
    },
    methods: {
      parse() {
        if (this.queryStringStateEnabled) {
          const qString = this.history.location.search.replace(/^\?/, "");

          const qObj = qs.parse(qString);

          if (findOne(Object.keys(qObj), this.ignoreQsParams)) {
            return false;
          }

          if (qString.length && qObj.instanceId === this.instanceId) {
            // @todo migrate away from this to unblock upgrade to algoliasearchHelper 3.x
            return algoliasearchHelper.url.getStateFromQueryString(qString);
          }
        }
        return false;
      },
      update(state) {
        if (this.queryStringStateEnabled) {
          this.history.replace({
            // @todo migrate away from this to unblock upgrade to algoliasearchHelper 3.x
            search: `?${algoliasearchHelper.url.getQueryStringFromState(state, {
              safe: true,
            })}&instanceId=${this.instanceId}`,
            hash: window.location.hash,
          });
        }
      },
      reset() {
        this.history.replace({
          search: `?${this.storedQs}`,
          hash: window.location.hash,
        });
      },
    },
  });
}
