<!-- Quaternary Component
    Craft CMS Data:
      -items.heading ('anything in a string')
      -items.link ('https://henley.imgix.net/v3/...')

    *This component can be included in Study, Business and Research. 

    Maximum no. of Sections: 
    -Sections = 6

    Maximum no. of characters for Heading:
    -Heading = 25 and overflow will turn to eclipse (...)
-->
<template>
  <section>
    <a :href="items.link" @mouseover="hover = true" @mouseleave="hover = false">
      <h6>{{ items.heading }}</h6>
      <span>
        <inline-arrow
          :name="!hover ? 'default-arrow' : 'default-hover-arrow'"
          :class="{ 'active-animate': hover }"
          class="hover"
        />
      </span>
    </a>
  </section>
</template>
<style scoped>
section {
  max-width: 165px;
  margin-right: 15px;
}

h6 {
  overflow: hidden;
  margin-bottom: 0;
  color: #333;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

h6:hover {
  color: #0a3b85;
}

a {
  display: flex;
  width: 165px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 11px;
}

svg {
  width: 10px;
  min-width: 10px;
}

.hover {
  transition-duration: 0.2s;
}

.active-animate {
  transform: translateX(3px);
}

@media (max-width: 1340px) {
  a {
    width: auto;
    min-width: 130px;
  }

  section {
    width: auto;
    min-width: 130px;
  }
}
</style>
<script>
import InlineArrow from "@/components/assemblies/masthead/components/elements/InlineArrow";

export default {
  components: {
    InlineArrow,
  },
  data() {
    return {
      hover: false,
    };
  },
  props: {
    items: {
      type: Object,
    },
  },
};
</script>
