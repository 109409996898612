<!-- Secondary Component
    Craft CMS Data:
      -items.heading ('anything in a string')
      -items.description ('anything in a string')
      -items.image ('anything in a string')
      -items.alt ('description of image')
      -items.link ('https://henley.imgix.net/v3/...')
      -items.blockDivider ('yes' or 'no')

    Maximum no. of characters for Heading & Description:
    -One line max characters = 19, can use the max characters for description of 90
    -Two line max characters = 37, can use max characters for description of 56
-->
<template>
  <a :href="items.link" @mouseover="hover = true" @mouseleave="hover = false">
    <section>
      <a class="innerLink" :href="items.link">
        <div v-bind:style="{ backgroundImage: 'url(' + items.image + ')' }"></div>
        <h6 :class="{ 'hover-colour': hover }">
          {{ items.heading }}
          <inline-arrow
            :name="!hover ? 'default-arrow' : 'default-hover-arrow'"
            :class="{ 'active-animate': hover }"
            class="hover"
          />
        </h6>
        <p>
          {{ items.description }}
        </p>
      </a>
      <VerticalLine v-if="items.blockDivider === 'yes'" class="line" />
    </section>
  </a>
</template>
<style scoped>
section {
  display: flex;
  width: auto;
}

h6 {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-top: 10px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.innerLink {
  width: 190px;
  max-width: 190px;
  height: 120px;
  max-height: 120px;
}

p {
  color: #333;
  font-weight: normal;
  line-height: 15px;
  opacity: 0.5;
}

div {
  width: auto;
  min-width: 150px;
  max-width: 190px;
  height: 120px;
  max-height: 120px;
  background-size: cover;
  border-radius: 4px;
}

svg {
  width: 10px;
  min-width: 10px;
}

.line {
  width: 1px;
  margin: 0 15px;
}

.hover-colour {
  color: #0a3b85 !important;
}

.hover,
.inactive {
  transition-duration: 0.2s;
}

.active-animate {
  transform: translateX(3px);
}

@media (max-width: 1340px) {
  div {
    height: 100px;
  }

  .innerLink {
    width: auto;
  }
}
</style>
<script>
import InlineArrow from "@/components/assemblies/masthead/components/elements/InlineArrow";
import VerticalLine from "@/components/assemblies/masthead/components/elements/VerticalLine";

export default {
  components: {
    InlineArrow,
    VerticalLine,
  },
  data() {
    return {
      hover: false,
    };
  },
  props: {
    items: {
      type: Object,
    },
  },
};
</script>
