<template>
  <section>
    <section v-for="item in items" :key="item.id">
      <section v-if="item.blockType == 'Primary Component'">
        <a :href="item.link">
          <Icon class="accordionArrow" icon="mobile-curve-arrow" />
          {{ item.heading }}
          <InlineArrow class="arrow" name="default-arrow" />
        </a>
        <HorizontalLine class="line" />
      </section>
      <section v-else-if="item.blockType == 'Secondary Component'">
        <a :href="item.link">
          <Icon class="accordionArrow" icon="mobile-curve-arrow" />
          {{ item.heading }}
          <InlineArrow class="arrow" name="default-arrow" />
        </a>
        <HorizontalLine class="line" />
      </section>
      <section v-else-if="item.blockType == 'Tertiary Component'">
        <a :href="item.link">
          <Icon class="accordionArrow" icon="mobile-curve-arrow" />
          {{ item.heading }}
          <InlineArrow class="arrow" name="default-arrow" />
        </a>
        <HorizontalLine class="line" />
      </section>
    </section>
  </section>
</template>
<style scoped>
section {
  position: relative;
}

a {
  display: flex;
  align-items: center;
  padding: 10px 0;
  padding-left: 15px;
  margin-right: 40px;
  color: #333;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
}

svg {
  display: block;
  margin-right: 20px;
}

.line {
  width: 100%;
  height: 1px;
}

.arrow {
  position: absolute;
  right: 20px;
  width: 12px;
}

.accordionArrow {
  width: 10px;
  margin-right: 20px;
}
</style>
<script>
import HorizontalLine from "@/components/assemblies/masthead/components/elements/HorizontalLine";
import InlineArrow from "@/components/assemblies/masthead/components/elements/InlineArrow";
import Icon from "@/components/atoms/icon/Icon";

export default {
  components: {
    HorizontalLine,
    InlineArrow,
    Icon,
  },
  props: {
    items: {
      type: Array,
    },
  },
};
</script>
