<template>
  <input
    ref="input"
    type="search"
    :placeholder="placeholder"
    :value="query"
    :class="queryHasFocus ? 'bg-white text-black' : ''"
    name="q"
    autocomplete="off"
    autocorrect="off"
    autocapitalize="off"
    spellcheck="true"
    required
    @input="setQuery"
    @focus="onFocus"
    @blur="onBlur"
  />
</template>
<style scoped>
input {
  border-top: 1px solid #ededed;
}
</style>
<script>
import { debounce } from "lodash";
import { createNamespacedHelpers } from "vuex";

const { mapState: mapSearchState } = createNamespacedHelpers("search");

export default {
  props: {
    placeholder: {
      type: String,
      default: "What are you looking for?",
    },
  },
  computed: {
    ...mapSearchState(["query", "queryHasFocus"]),
  },
  methods: {
    setQuery: debounce(function debouncedSetQuery(e) {
      this.$store.commit("search/SET_QUERY", e.target.value.trim());
      this.$store.commit("search/SET_QUERY", e.target.value.trim());

      this.$emit("change");
    }, 400),
    resetQuery() {
      this.$store.commit("search/SET_QUERY", "");
    },
    onFocus() {
      this.$store.commit("search/SET_QUERY_FOCUS", true);
    },
    onBlur() {
      this.$store.commit("search/SET_QUERY_FOCUS", false);
    },
    focus() {
      this.$refs.input.focus();
    },
  },
};
</script>
