export default {
  computed: {
    types() {
      return this.hit.coachingType || [];
    },
    locations() {
      let locs = this.hit.geographicalLocationNested || [];

      locs = locs.filter((loc) => loc.level > 1) || locs;

      return locs.map((loc) => loc.title);
    },
    excerpt() {
      return this.hit.description;
    },
    tagMetaItems() {
      const linkedIn = this.hit.linkedin
        ? {
            url: `${this.hit.linkedin}`,
            title: "LinkedIn",
          }
        : null;

      return [].concat([linkedIn]).filter(Boolean);
    },
  },
};
