<template>
  <section :class="!toggleSearchQuery && 'wrapper'">
    <search-box
      v-if="showSearchInput || forceResultsShown"
      ref="searchBox"
      :placeholder="placeholderText"
      @change="showSearchResults"
    />
    <span v-if="!toggleSearchQuery">
      <p>Close search</p>
      <button @click="searchHidden">
        <Icon icon="close-icon" />
      </button>
    </span>
  </section>
</template>
<style scoped>
section {
  position: relative;
  height: 50px;
}

p {
  position: absolute;
  top: 20px;
  right: 50px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 10px;
  line-height: 10px;
}

button svg {
  position: absolute;
  top: 5px;
  right: 0;
  width: 40px;
  cursor: pointer;
}

.wrapper {
  margin: 40px 90px 0 90px;
}

@media (max-width: 1024px) {
  p {
    display: none;
  }

  .wrapper {
    margin: 15px;
  }
}
</style>
<script>
import { mapState, mapActions, mapGetters } from "vuex";

import Icon from "@/components/atoms/icon/Icon";
import SearchBox from "@/components/assemblies/search/components/query/SearchBox";

export default {
  components: {
    Icon,
    SearchBox,
  },
  computed: {
    ...mapState("search", ["forceResultsShown", "query"]),
    ...mapGetters("search", ["resultsShown", "placeholderText"]),
    ...mapState("masthead", ["toggleSearchQuery"]),
    ...mapGetters("masthead", ["showSearchInput"]),
  },
  methods: {
    ...mapActions("masthead", ["hideSearchV2", "toggleSearchV2"]),
    searchHidden() {
      this.$store.commit("search/SET_QUERY", "");
      return this.hideSearchV2();
    },
    showSearchResults() {
      if (!this.resultsShown && this.query.length) {
        this.toggleSearchV2();
      }
    },
  },
};
</script>
