<template>
  <span class="text-sm leading-none">
    <span v-if="totalHits">
      Showing {{ firstHitOnPage }}-{{ lastHitOnPage }} of {{ totalHits }} results</span
    >
    <span v-else>0 results</span>
  </span>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("search", ["firstHitOnPage", "lastHitOnPage", "query", "totalHits"]),
  },
};
</script>
