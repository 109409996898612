<template>
  <section
    @mouseover="toggle, (megaNav = true), (arrow = 'up')"
    @mouseleave="toggle, (megaNav = false), (arrow = 'down')"
    :id="tab"
  >
    <a href="#" class="desktopTabs">
      <span v-html="item.title" />
      <Icon v-if="toggleSearchQuery || !showNavbar" :class="arrow" icon="dropdown-icon-blue" />
      <Icon v-else :class="arrow" icon="dropdown-icon-white" />
      <div :class="{ 'activeBarStatus ': megaNav }"></div>
    </a>

    <transition name="fade">
      <section class="menuExpanded" :class="toggleSearchQuery && 'searchExpanded'" v-if="megaNav">
        <NewsEventsComponent
          v-if="item.section == 'NewsEvents'"
          :id="paneId"
          :items="item.children"
          :item-class="[itemClass]"
        />
        <mega-navigation v-else :id="paneId" :items="item.children" :item-class="[itemClass]" />
      </section>
    </transition>
  </section>
</template>
<style scoped>
li {
  min-width: fit-content;
  cursor: pointer;
}

.desktopTabs {
  position: relative;
  display: flex;
  align-items: center;
  padding: 35px 25px;
  padding-left: 0;
  text-decoration: none;
}

.activeBarStatus {
  position: absolute;
  right: 20px;
  bottom: 0;
  left: 0;
  border-top: 6px solid #0a3b85;
}

.dropdown-icon-blue,
.dropdown-icon-white {
  top: calc(50% - 4px / 2);
  left: calc(50% - 8px / 2);
  width: 12px;
  margin-left: 6px;
}

.up {
  transform: rotate(180deg);
  transition: transform 450ms ease-in-out;
}

.down {
  transition: transform 450ms ease-in-out;
}

.menuExpanded {
  position: absolute;
  z-index: 2;
  top: 80px;
  right: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 240px;
  min-height: 240px;
  flex-wrap: wrap;
  padding: 20px 70px 0;
  border-top: 1px solid #ededed;
  margin: 0;
  background: white;
  color: black;
}

.searchExpanded {
  border-bottom: 1px solid #ededed;
  background: #f8f9fb;
}

@media (max-width: 1340px) {
  .menuExpanded {
    padding: 20px;
  }
}
</style>
<style>
.menuExpanded a {
  padding: 0;
}
</style>
<script>
import { createNamespacedHelpers, mapState } from "vuex";
import { isExternalUrl } from "@/lib/isExternalUrl";

const { mapGetters: mapMastheadGetters } = createNamespacedHelpers("masthead");
const { mapGetters: mapUiGetters } = createNamespacedHelpers("ui");

import MegaNavigation from "@/components/assemblies/masthead/components/desktop/MegaNavigation";
import NewsEventsComponent from "@/components/assemblies/masthead/components/desktop/mega-nav/NewsEventsComponent";
import Icon from "@/components/atoms/icon/Icon";

export default {
  components: {
    MegaNavigation,
    NewsEventsComponent,
    Icon,
  },
  data() {
    return {
      megaNav: false,
      arrow: "down",
    };
  },
  props: {
    showNavbar: {
      type: Boolean,
    },
    item: {
      type: Object,
      required: true,
    },
    itemClass: {
      type: [String, Array],
      required: false,
      default: "",
    },
  },
  computed: {
    ...mapState("masthead", ["toggleSearchQuery"]),
    ...mapMastheadGetters(["menuContracted"]),
    ...mapUiGetters(["is2lg"]),
    paneId() {
      return `masthead-mega-navigation-pane-${this.item.id}`;
    },
    tab() {
      return this.item.title.replace(/\s/g, "");
    },
  },
  methods: {
    isExternalUrl,
    toggle() {
      this.$store.commit("masthead/TOGGLE_EXPAND_ITEM", {
        item: this.item,
        isExpanded: !this.item.isExpanded,
      });
    },
  },
};
</script>
