<template>
  <span>
    <slot />
  </span>
</template>

<script>
const themes = {
  light: {},
  dark: {},
};

export default {
  data() {
    return {
      themeKey: "dark",
    };
  },
  methods: {
    handleChangeTheme({ key }) {
      this.themeKey = key;
    },
  },
  computed: {
    theme() {
      return themes[this.themeKey];
    },
    isDark() {
      return this.themeKey === "dark";
    },
  },
  provide() {
    return {
      handleChangeTheme: this.handleChangeTheme,
      theme: () => this.theme,
      isDark: () => this.isDark,
      themes,
    };
  },
};
</script>
