<template>
  <div class="fixed left-0 bottom-0 top-0 right-0 bg-white z-50 py-6 overflow-y-auto">
    <button title="Close filters" @click="toggleMobileFilters">
      <icon icon="close" class="absolute top-0 right-0 text-accent-blue block w-12 mx-1" />
    </button>
    <div class="container">
      <h3>Filter Results</h3>
      <search-box
        v-if="queryShown"
        :placeholder="placeholderText"
        class="border border-grey-light mb-4"
      />
      <all-filters />
      <mobile-filter-button />

      <!--      <reset-all-filters class-name="btn btn-block" active-class-name="btn btn-block" />-->
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";

import AllFilters from "@/search/components/filters/All";
import MobileFilterButton from "@/search/components/filters/MobileFilterButton";
import SearchBox from "@/search/components/query/SearchBox";
// import ResetAllFilters from "@/search/components/filters/ResetAllFilters";
import Icon from "@/components/atoms/icon/Icon";

export default {
  components: {
    AllFilters,
    MobileFilterButton,
    SearchBox,
    Icon,
    // ResetAllFilters,
  },
  computed: {
    ...mapState("search", ["queryShown"]),
    ...mapGetters("search", ["placeholderText"]),
  },
  mounted() {
    document.body.style.overflow = "hidden";
  },
  beforeUnmount() {
    document.body.style.overflow = "";
  },
  methods: {
    toggleMobileFilters() {
      this.$store.commit("search/TOGGLE_MOBILE_FILTERS");
    },
  },
};
</script>
