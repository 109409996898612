<template>
  <section>
    <fieldset class="toggleFilter">
      <ul>
        <li class="firstListItem" :class="activeClass('all')" @click="activeSection('all')">
          <span
            >{{ capitalize("all") }}&nbsp;<span class="count">{{
              totalHitsForResultsByCategory
            }}</span></span
          >
        </li>
        <li
          v-for="(value, index) in filteredArray"
          :key="index"
          :class="activeClass(value.name)"
          @click="activeSection(value.name)"
        >
          <label class="checkbox" :for="getFilterValueId(value.name)">
            <input
              type="checkbox"
              :id="getFilterValueId(value.name)"
              :value="value.name"
              :checked="value.isRefined"
            />
            <span class="whitespace-nowrap"
              >{{ capitalize(value.alias || value.name) }}
              &nbsp;
              <span class="count">
                {{ value.count }}
              </span>
            </span>
          </label>
        </li>
      </ul>
    </fieldset>
    <fieldset v-if="activeFilter === 'courses' && !isMobile" class="levelOfStudy">
      <ul>
        <p>LEVEL OF STUDY</p>
        <li v-for="(value, index) in levelOfStudyArr" :key="index">
          <label :for="getFilterValueId(value.name)">
            <input
              :id="getFilterValueId(value.name)"
              type="checkbox"
              :value="value.name"
              @change="activeLevelOfStudy(value.name)"
              :checked="value.isRefined"
            />
            <span
              >{{ capitalize(value.name) }}&nbsp;<span class="count">{{ value.count }}</span></span
            >
          </label>
        </li>
      </ul>
    </fieldset>
    <div v-if="activeFilter === 'courses' && isMobile">
      <button @click="activeMobileFilters(true)">LEVEL OF STUDY <Icon icon="sliders" /></button>
    </div>
    <div v-if="mobileFilters" class="overlay"></div>
    <div v-if="activeFilter === 'courses' && isMobile && mobileFilters" class="levelOfStudyMobile">
      <ul>
        <span class="line"></span>
        <p>LEVEL OF STUDY</p>
        <li v-for="(value, index) in levelOfStudyArr" :key="index">
          <label :for="getFilterValueId(value.name)">
            <input
              :id="getFilterValueId(value.name)"
              type="checkbox"
              :value="value.name"
              @change="activeLevelOfStudy(value.name)"
              :checked="value.isRefined"
            />
            <span
              >{{ capitalize(value.name) }}&nbsp;<span class="count">{{ value.count }}</span></span
            >
          </label>
        </li>
      </ul>
      <button @click="activeMobileFilters(false)">APPLY</button>
    </div>
  </section>
</template>
<style scoped>
button {
  display: flex;
  width: fit-content;
  align-items: center;
  padding: 12.5px 15px;
  border: 1px solid rgba(237, 237, 237, 0.92941);
  margin: 12.5px 5px 0;
  background: #fff;
  border-radius: 50px;
  color: #000;
  font-size: 12px;
  letter-spacing: 0.07em;
  line-height: 10px;
}

button svg {
  width: 20px;
  margin-left: 5px;
}

fieldset ul {
  top: 154.28px;
  left: 90px;
  display: flex;
  width: 803px;
  height: 49.72px;
  box-sizing: border-box;
  flex-direction: row;

  /* flex-wrap: wrap; */
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #fff;
  border-radius: 50px;
}

li {
  width: max-content;
  align-self: center;
  color: #000;
  font-weight: 600;
  text-transform: uppercase;
}

li:hover {
  cursor: pointer;
}

span {
  display: flex;
  color: #000;
  font-size: 12px;
  font-weight: initial;
  letter-spacing: 0.07em;
  line-height: 10px;
}

input:checked {
  background: #0a3b85;
  border-radius: 100px;
  color: white;
}

label {
  margin: 0;
}

p {
  display: flex;
  align-items: center;
  margin: 0;
  margin-right: 30px;
  color: #000;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.07em;
  line-height: 10px;
  text-transform: uppercase;
}

.overlay {
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
}

.levelOfStudy li {
  margin: 0 20px;
}

.perPage {
  position: absolute;
  bottom: 0;
}

.levelOfStudy ul {
  position: absolute;
  top: 90px;
  right: 0;
  left: 0;
  width: 100%;
  padding: 0 90px;
  border: none;
  background: rgba(10, 59, 133, 0.03);
  border-radius: initial;
}

.levelOfStudy label {
  display: flex;
  align-items: center;
}

.levelOfStudy input {
  border: 1px solid rgba(58, 58, 58, 0.2);
  margin-right: 10px;
  background: #fff;
}

.levelOfStudyMobile {
  position: fixed;
  z-index: 1;
  right: 0;
  bottom: 80px;
  left: 0;
  padding: 5px 15px 20px 15px;
  background: #fff;
  border-radius: 20px 20px 0 0;
  transition: transform 0.5s ease-in-out;
}

.levelOfStudyMobile button {
  display: block;
  padding: 15px 40px;
  margin: auto;
  background: #0a3b85;
  border-radius: 100px;
  color: white;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.07em;
  line-height: 10px;
  text-transform: uppercase;
}

.levelOfStudyMobile ul {
  border-bottom: 1px solid #ededed;
  margin-bottom: 20px;
}

.levelOfStudyMobile li {
  padding: 15px 0;
  margin-left: 0;
  opacity: 0.6;
}

.levelOfStudyMobile input {
  margin-right: 15px;
}

.levelOfStudyMobile p {
  display: block;
  padding: 20px 0;
  border-bottom: 1px solid #ededed;
  margin: auto;
  text-align: center;
}

.levelOfStudyMobile label {
  display: flex;
  align-items: center;
}

.count {
  margin-left: 4px;
  color: #000;
  font-weight: initial;
  opacity: 0.5;
}

.line {
  width: 50px;
  border-top: 3px solid #ededed;
  margin: auto;
}

.active {
  background: #0a3b85 !important;
  border-radius: 100px;
}

.active span {
  color: white;
}

#courseswrapper {
  padding: 15px 20px 14px;
  background: #0a3b85;
  border-radius: 100px;
  color: white;
}

.firstListItem,
.toggleFilter .firstListItem {
  padding: 0;
  border: none;
  margin: 0;
  background: transparent;
}

.toggleFilter li {
  padding: 15px 15px 14px !important;
}

.toggleFilter ul {
  width: auto;
  padding: 0 5px;
}

@media (max-width: 1375px) {
  .levelOfStudy ul {
    padding: 0 60px;
  }
}

@media (max-width: 1340px) {
  .levelOfStudy ul {
    padding: 0 20px;
  }
}

@media (max-width: 1600px) {
  .toggleFilter ul {
    width: fit-content;
    max-width: initial;
    margin-top: 10px;
  }

  .levelOfStudy ul {
    top: 135px;
  }
}

@media (max-width: 1129px) {
  .toggleFilter ul {
    flex-wrap: nowrap;
    border: navajowhite;
    background: transparent;
  }

  .toggleFilter li {
    padding: 15px 20px 14px;
    border: 1px solid rgba(237, 237, 237, 0.92941);
    margin: 0 5px;
    background: #fff;
    border-radius: 50px;
  }

  .firstListItem,
  .toggleFilter .firstListItem {
    border: 1px solid rgba(237, 237, 237, 0.92941);
    margin-right: 5px;
    background: #fff;
  }

  section {
    overflow: hidden;
    overflow-x: scroll;
  }
}
</style>
<script>
import { mapGetters, mapState } from "vuex";

import slugify from "underscore.string/slugify";
import capitalize from "underscore.string/capitalize";

import Icon from "@/components/atoms/icon/Icon";

export default {
  components: {
    Icon,
  },
  data() {
    return {
      currentFilter: "everything",
      levelOfStudyArr: [
        { name: "Apprenticeships" },
        { name: "Executive Education" },
        { name: "MBA" },
        { name: "Masters" },
        { name: "PhD / DBA" },
        { name: "Undergraduate" },
      ],
    };
  },
  computed: {
    ...mapState("search", [
      "query",
      "activeFilter",
      "resultsByCategory",
      "activeLevelOfStudyFilter",
      "mobileFilters",
    ]),
    ...mapGetters("search", ["totalHitsForResultsByCategory", "resultsByCategory"]),
    ...mapGetters("ui", ["isMobile"]),
    filteredArray() {
      const data = [
        {
          name: "courses",
          alias: "course and services",
          count: this.resultsByCategory.courses.nbHits,
        },
        { name: "events", count: this.resultsByCategory.events.nbHits },
        { name: "scholarships", count: this.resultsByCategory.scholarships.nbHits },
        { name: "news", count: this.resultsByCategory.news.nbHits },
        { name: "research", count: this.resultsByCategory.research.nbHits },
        { name: "people", count: this.resultsByCategory.people.nbHits },
        { name: "other", count: this.resultsByCategory.other.nbHits },
      ];
      return data;
    },
  },
  methods: {
    activeMobileFilters(value) {
      this.$store.commit("search/MOBILE_FILTERS", value);
    },
    activeClass(value) {
      return this.activeFilter === value ? "active" : "";
    },
    activeSection(value) {
      // Hacky solution to prevent scrolling right.
      // Proper fix is to identify the bug somewhere in the CSS
      document.getElementById("results-screen").scrollTo({ left: 0 });

      if (value !== "courses") {
        this.$store.commit("search/RESET_FILTER", value);
      }
      this.$store.commit("search/ACTIVE_FILTER", value);
    },
    setFilter(filter) {
      this.currentFilter = filter;
    },
    getFilterValueId(valueName) {
      return slugify(`${valueName}`);
    },
    activeLevelOfStudy(value) {
      if (!this.activeLevelOfStudyFilter.includes(value)) {
        this.$store.commit("search/ACTIVE_LEVELOFSTUDY_FILTER", value);
      } else {
        this.$store.commit("search/REMOVE_LEVELOFSTUDY_FILTER", value);
      }
    },
    capitalize: (str) => capitalize(str),
  },
};
</script>
