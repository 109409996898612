<!-- Quinary Component
    Craft CMS Data:
      -items.heading ('anything in a string')
      -items.link ('https://henley.imgix.net/v3/...')

    Maximum no. of characters for Heading:
    -Heading = 55 and overflow will turn to eclipse (...)
-->
<template>
  <section>
    <p class="title">{{ items.title }}</p>
    <a v-for="item in items.children" :key="item.id" :items="item" :href="item.url">
      <div>
        <p class="event">{{ item.title }}</p>
        <HorizontalLine v-if="item.horizontalLine == 'yes'" class="line" />
      </div>
    </a>
  </section>
</template>
<style scoped>
section {
  max-width: 165px;
  margin-right: 20px;
}

a {
  display: flex;
  width: 165px;
  align-items: center;
  justify-content: space-between;
}

p {
  display: block;
  display: -webkit-box;
  overflow: hidden;
  max-width: 100%;
  margin: 0 auto;
  -webkit-box-orient: vertical;
  color: #333;
  font-size: 12px;
  font-weight: 400;
  -webkit-line-clamp: 2;
  line-height: 15px !important;
  line-height: 1;
  text-overflow: ellipsis;
}

.title {
  margin-bottom: 15px;
  color: #333;
  font-weight: normal;
  line-height: 15px;
  opacity: 0.5;
}

.line {
  width: 180px;
  margin: 15px 0;
}

.event:hover {
  color: #0a3b85;
}

@media (max-width: 1340px) {
  section {
    width: auto;
    min-width: 120px;
    max-width: 140px;
  }

  a {
    width: auto;
    min-width: 120px;
  }

  .line {
    width: auto;
    min-width: 120px;
  }
}
</style>
<script>
import HorizontalLine from "@/components/assemblies/masthead/components/elements/HorizontalLine";

export default {
  components: {
    HorizontalLine,
  },
  props: {
    items: {
      type: Object,
    },
  },
};
</script>
