<script>
import trackingHelper from "@/search/mixins/trackingHelper";

import HitImage from "./HitImage";

export default {
  components: {
    HitImage,
  },
  mixins: [trackingHelper],
  props: {
    hit: {
      required: true,
      type: Object,
    },
    isWide: {
      type: Boolean,
      default: false,
    },
    tagMetaItemsOveride: {
      required: false,
      type: [Array, Boolean],
      default: false,
    },
    hasMediaRight: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    metaSeparator: () => "|",
    subMetaSeparator: () => "/",
    tagMetaItems() {
      const items = [];

      if (this.hit.sectionHandle === "pages") {
        items.push({
          title: this.hit.section,
          url: this.hit.breadcrumb[0] || null,
        });
      } else {
        items.push({
          title: this.hit.type,
        });
      }

      return items;
    },
    metaItems() {
      return [];
    },
    subMetaItems() {
      let items = [];

      if (this.hit.breadcrumb && Array.isArray(this.hit.breadcrumb)) {
        items = this.hit.breadcrumb.slice(1);
      }

      return items;
    },
    renderedTagMetaItems() {
      return this.tagMetaItemsOveride || this.tagMetaItems;
    },
  },
  methods: {
    truncate(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      }
      return text;
    },
    parseAndTruncate(text, length, suffix) {
      const parser = new DOMParser();
      const dom = parser.parseFromString("<!doctype html><body>" + text, "text/html");
      const decoded_string = dom.body.textContent;
      if (decoded_string.length > length) {
        return text.substring(0, length) + suffix;
      }
      return decoded_string;
    },
  },
};
</script>
