<script>
import HitBySection from "../HitBySection";
import CoachingRegisterSnippet from "./CoachingRegisterSnippet";
import CoursesSnippet from "./CoursesSnippet";
import DefaultSnippet from "./DefaultSnippet";
import DiscussionPapersSnippet from "./DiscussionPapersSnippet";
import EventsSnippet from "./EventsSnippet";
import NewsSnippet from "./NewsSnippet";
import PeopleSnippet from "./PeopleSnippet";
import ScholarshipsSnippet from "./ScholarshipsSnippet";
import ResearchPublicationsSnippet from "./ResearchPublicationsSnippet";
import TestimonialsSnippet from "./TestimonialsSnippet";

const components = {
  CoachingRegisterSnippet,
  CoursesSnippet,
  DefaultSnippet,
  DiscussionPapersSnippet,
  EventsSnippet,
  NewsSnippet,
  PeopleSnippet,
  ScholarshipsSnippet,
  ResearchPublicationsSnippet,
  TestimonialsSnippet,
};

export default {
  components,
  extends: HitBySection,
  data() {
    return {
      previewType: "Snippet",
      components,
    };
  },
};
</script>
