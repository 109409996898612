<template>
  <section v-if="dataManipulated(data).length">
    <h2>{{ title }}</h2>
    <div>
      <p v-for="(item, index) in dataManipulated(data)" :key="item.id" @click="updateQuery(item)">
        <span v-if="index <= count">{{ item }}</span>
      </p>
    </div>
  </section>
</template>
<style scoped>
section {
  padding: 40px 0 0;
}

h2 {
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  color: #000;
  font-size: 25px;
  font-weight: 600;
  line-height: 25px;
}

p {
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  font-size: 16px;
  line-height: 25px;
}

@media (max-width: 1024px) {
  section {
    padding: 15px 0;
  }

  h2 {
    padding-bottom: 10px;
    font-size: 17px;
    line-height: 21px;
  }
}
</style>
<script>
import { mapState, mapActions } from "vuex";

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    data: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      count: 4,
    };
  },
  computed: {
    ...mapState("search", ["hitUiMode", "query"]),
    ...mapActions("masthead", ["searchQuery"]),
  },
  methods: {
    updateQuery(value) {
      this.$store.commit("search/SET_QUERY", value);
      // this.searchQuery();
    },
    dataManipulated(data) {
      const result = JSON.stringify(data).replace(/['"]+/g, "").split(",");

      if (this.title.toLowerCase() === "suggestions") {
        if (!this.query) {
          return result;
        } else {
          return result.filter((single) => single.toLowerCase().includes(this.query.toLowerCase()));
        }
      } else {
        return result;
      }
    },
  },
};
</script>
