<!-- Search Component used in Mega Navigation Blocks -->
<template>
  <div>
    <Icon icon="search-icon-blue" />
    <input :placeholder="placeholder" />
  </div>
</template>
<style scoped>
div {
  position: relative;
}

svg.search-icon-white {
  position: absolute;
  top: 11px;
  left: 10px;
  width: 14px;
  height: 14px;
  color: #333;
  opacity: 0.5;
}

input {
  top: 195px;
  left: 290px;
  display: flex;
  width: 180px;
  height: 36px;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  padding: 8px 5px 8px 35px;
  border: 1px solid rgba(10, 59, 133, 0.3);
  background: rgba(10, 59, 133, 0.01);
  border-radius: 100px;
  color: #333;
  opacity: 0.5;
}

input:focus {
  outline: 0;
}

@media (max-width: 1200px) {
  div {
    width: auto;
    min-width: 150px;
  }

  input {
    width: auto;
    min-width: 150px;
  }
}
</style>
<script>
import Icon from "@/components/atoms/icon/Icon";

export default {
  components: {
    Icon,
  },
  props: {
    placeholder: {
      type: String,
    },
  },
};
</script>
