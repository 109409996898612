<template>
  <filters />
</template>
<script>
import Filters from "@/components/assemblies/search/components/filters/All";

export default {
  components: {
    Filters,
  },
};
</script>
