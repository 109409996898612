<template>
  <div class="flex flex-wrap">
    <div
      v-if="filtersShown && isLg"
      :class="{
        'min-w-min max-w-expanded w-full md:w-1/4 relative z-50': isFixed,
        'w-full md:w-1/3 lg:w-1/4 ': !isFixed,
      }"
      class="border border-grey-light p-6"
    >
      <desktop-filters />
    </div>

    <div
      ref="scrollEl"
      :class="{ 'h-full max-h-screen overflow-y-auto z-40': isFixed }"
      class="w-full md:flex-1 relative"
    >
      <search-global-input
        v-if="queryShown"
        :is-fixed="isFixed"
        :is-closeable="isFixed"
        :show-search-icon="isFixed && !isMobile"
      />
      <div :class="{ 'h-full overflow-y-auto px-8': isFixed }" class="w-full pt-6">
        <div class="max-w-screen2Xl mx-auto">
          <div v-if="!isLg && filtersShown">
            <mobile-filter-button />
          </div>

          <div v-if="paginationShown" class="flex flex-wrap mb-4">
            <div class="pl-6 mr-auto w-1/3 text-left">
              <result-count />
            </div>

            <div class="ml-auto mr-auto w-1/3 text-center">
              <result-ui-switcher />
            </div>
            <div class="ml-auto w-1/3 text-right">
              <per-page />
            </div>
          </div>

          <hits :hits="hits" :is-wide="isFixed" />
          <pagination v-if="paginationShown" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";

import { scrollToTarget } from "@/search/helpers/scroll-to-helper";
import DesktopFilters from "@/search/components/filters/Desktop";
import Hits from "@/search/components/results/Hits";
import MobileFilterButton from "@/search/components/filters/MobileFilterButton";
import Pagination from "@/search/components/pagination/Full";
import PerPage from "@/search/components/results/PerPage";
import ResultCount from "@/search/components/results/ResultCount";
import ResultUiSwitcher from "@/search/components/results/ResultUiSwitcher";
import SearchGlobalInput from "@/search/components/SearchGlobalInput.vue";

export default {
  components: {
    DesktopFilters,
    Hits,
    MobileFilterButton,
    Pagination,
    PerPage,
    ResultCount,
    ResultUiSwitcher,
    SearchGlobalInput,
  },
  props: {
    isFixed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState("search", ["filtersShown", "queryShown", "paginationShown", "page", "resetScroll"]),
    ...mapGetters("search", ["hits", "totalHits", "totalPages"]),
    ...mapGetters("ui", ["isDesktop", "isLg", "isMobile"]),
    hasNoResults() {
      return this.totalHits === 0;
    },
  },
  watch: {
    resetScroll() {
      this.scrollToContainer();
    },
  },
  created() {
    this.$nextTick(() => {
      if (this.isFixed) {
        document.body.style.overflowY = "hidden";
      }
    });
  },
  beforeUnmount() {
    if (this.isFixed) {
      document.body.style.overflowY = "";
    }
  },
  methods: {
    scrollToContainer() {
      const scrollOpts = this.isFixed ? { scrollElement: this.$refs.scrollEl } : {};

      this.$nextTick(() => scrollToTarget(this.$el, scrollOpts));
    },
  },
};
</script>
