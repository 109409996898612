<template>
  <span>
    <slot v-bind="{ theme: theme(), isDark: isDark(), handleChangeTheme }" />
  </span>
</template>

<script>
export default {
  inject: ["theme", "handleChangeTheme", "isDark"],
};
</script>
