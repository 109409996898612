import Cookies from "js-cookie";

function CookieNotice(el) {
  const cookieName = "cookieMessageShown";
  const msgShown = Cookies.get(cookieName);

  if (msgShown || !el) {
    return;
  }

  el.classList.remove("js:hidden");
  Cookies.set(cookieName, 1);
}

export function bindCookieNotice() {
  return [...document.querySelectorAll("[data-cookie-notice]")].map((el) => CookieNotice(el));
}
