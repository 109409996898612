<template>
  <div v-if="currentVideo && isExpanded" class="m-hero-video">
    <button
      class="absolute top-0 right-0 bg-accent-blue w-8 xl:w-16"
      title="Close video player"
      @click.prevent="close"
    >
      <icon icon="close" class="w-8 mr-auto ml-auto h-8 xl:h-16 text-white group-hover:text-grey" />
    </button>
    <youtube
      ref="youtube"
      :fit-parent="true"
      :resize="true"
      :player-vars="playerVars"
      :video-id="currentVideo.id"
      @ended="onEnd"
    />
  </div>
</template>
<script>
import Vue from "vue";
import VueYoutube from "vue-youtube";
import Icon from "@/components/atoms/icon/Icon";

Vue.use(VueYoutube);

export default {
  components: {
    Icon,
  },
  props: {
    videos: {
      type: Array,
      required: true,
    },
    currentVideo: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
    isExpanded: {
      type: Boolean,
      required: true,
    },
    isPlaying: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    playerVars() {
      return {
        autoplay: 1,
        enablejsapi: 1,
        playsinline: 1,
        modestbranding: 1,
        rel: 0,
        color: "white",
      };
    },
  },
  watch: {
    isPlaying(shouldPlay) {
      return shouldPlay ? this.playVideo() : this.pauseVideo();
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    onEnd() {
      this.$emit("end");
    },
    async playVideo() {
      if (this.$refs.youtube) {
        await this.$refs.youtube.player.playVideo();
      }
    },
    async pauseVideo() {
      if (this.$refs.youtube) {
        await this.$refs.youtube.player.pauseVideo();
      }
    },
  },
};
</script>
