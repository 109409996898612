<template>
  <section class="NewsEventsComponent">
    <PrimaryComponent v-for="item in primaryNewsComponent" :key="item.id" :items="item" />
    <QuinaryComponent v-for="item in quinaryComponent" :key="item.id" :items="item" />
    <VerticalLine v-for="item in quinaryBlockDivider" :key="item.id" class="line" />
    <PrimaryComponent v-for="item in primaryEventsComponent" :key="item.id" :items="item" />
    <SenaryComponent v-for="item in senaryComponent" :key="item.id" :items="item" />
  </section>
</template>
<style scoped>
.NewsEventsComponent {
  display: flex;
  margin: 0 auto;
}

.line {
  height: 190px;
  padding: 0 20px;
  padding-left: 0;
  margin: 0;
  margin-left: 0;
}
</style>
<script>
import PrimaryComponent from "@/components/assemblies/masthead/components/desktop/mega-nav/PrimaryComponent";
import QuinaryComponent from "@/components/assemblies/masthead/components/desktop/mega-nav/QuinaryComponent";
import SenaryComponent from "@/components/assemblies/masthead/components/desktop/mega-nav/SenaryComponent";
import VerticalLine from "@/components/assemblies/masthead/components/elements/VerticalLine";

export default {
  components: {
    PrimaryComponent,
    QuinaryComponent,
    SenaryComponent,
    VerticalLine,
  },
  props: {
    items: {
      type: Array,
    },
  },
  computed: {
    primaryNewsComponent() {
      return this.items.filter((item) => item.heading === "News");
    },
    quinaryComponent() {
      return this.items.filter((item) => item.blockType === "Quinary Component");
    },
    quinaryBlockDivider() {
      return this.items.filter(
        (item) => item.blockType === "Quinary Component" && item.blockDivider == "yes"
      );
    },
    primaryEventsComponent() {
      return this.items.filter((item) => item.heading === "Events");
    },
    senaryComponent() {
      return this.items.filter((item) => item.blockType === "Senary Component");
    },
  },
};
</script>
