<template>
  <a :href="hit.uri">
    <section>
      <span
        v-if="hit.image"
        v-bind:style="{ backgroundImage: 'url(' + hit.image.url + ')' }"
      ></span>
      <span v-else>
        <Icon icon="people" width="193" height="220" class="placeholder" />
      </span>
      <h4>{{ hit.title }}</h4>
      <p v-if="hit.personRoles" v-html="highlight(hit.personRoles[0])" class="mb-1"></p>
      <p v-if="hit.department" v-html="highlight(hit.department[0])"></p>
    </section>
  </a>
</template>
<style scoped>
section {
  position: relative;
  width: 193px;
  height: auto;
  min-height: 220px;
  margin: 0 17px 20px 0;
  border-radius: 0 10px 10px 0;
}

h4 {
  margin-top: 15px;
  margin-bottom: 5px;
  color: #000;
  font-size: 15px;
  font-weight: 600;
  line-height: 21px;
}

p {
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  line-height: 15px;
}

span {
  display: block;
  overflow: hidden;
  min-height: 220px;
  background-position: center;
  background-size: cover;
  border-radius: 5px;
}

.placeholder {
  height: 220px;
}

@media (max-width: 500px) {
  section {
    width: 168px;
  }
}

@media (max-width: 399px) {
  section {
    width: 100%;
  }
}
</style>
<script>
import { mapState } from "vuex";

import Icon from "@/components/atoms/icon/Icon";

export default {
  components: {
    Icon,
  },
  props: {
    hit: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState("search", ["query"]),
  },
  methods: {
    highlight(content) {
      if (!this.query) {
        return content;
      }
      if (content !== null) {
        return content.replace(new RegExp(this.query, "gi"), (match) => {
          return '<span style="font-weight: bold">' + match + "</span>";
        });
      }
    },
  },
};
</script>
