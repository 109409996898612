<template>
  <section>
    <!-- only display featured courses if selected in the CMS and when no query has been made yet  -->
    <ResultsBlock
      v-if="courseSelections.length > 0 && query.length === 0"
      heading="Courses and services"
      category="courses"
      :hitCount="resultCountByCategory.courses"
    >
      <div class="flex flex-row flex-wrap">
        <div v-for="(hit, index) in courseSelections" :key="hit.id" :hit="hit" class="cardWrapper">
          <card v-if="index <= courseCount" :hit="hit" />
        </div>
      </div>
    </ResultsBlock>

    <!-- only display featured events if selected in the CMS and when no query has been made yet  -->
    <ResultsBlock
      v-if="eventSelections.length > 0 && query.length === 0"
      heading="Events"
      category="events"
      :hitCount="resultCountByCategory.events"
    >
      <div class="flex flex-row flex-wrap">
        <div v-for="(hit, index) in eventSelections" :key="hit.id" :hit="hit">
          <div v-if="index <= eventCount">
            <card-small-image v-if="isMobile" :hit="hit" />
            <card-full-horizontal-image v-else :hit="hit" />
          </div>
        </div>
      </div>
    </ResultsBlock>

    <section v-if="query.length > 0">
      <ResultsBlock
        v-if="resultsByCategory.courses.nbHits > 0"
        heading="Courses and services"
        category="courses"
        :hitCount="resultCountByCategory.courses"
      >
        <div class="flex flex-row flex-wrap">
          <div
            v-for="(hit, index) in resultsByCategory.courses.hits"
            :key="hit.id"
            :hit="hit"
            class="cardWrapper"
          >
            <card v-if="index <= courseCount" :hit="hit" />
          </div>
        </div>
      </ResultsBlock>

      <ResultsBlock
        v-if="resultsByCategory.events.nbHits > 0"
        heading="Events"
        category="events"
        :hitCount="resultCountByCategory.events"
      >
        <div class="flex flex-row flex-wrap">
          <div
            v-for="(hit, index) in resultsByCategory.events.hits"
            :key="hit.id"
            :hit="hit"
            class="smallImageWrapper"
          >
            <div v-if="index <= eventCount">
              <card-small-image v-if="isMobile" :hit="hit" />
              <card-full-horizontal-image v-else :hit="hit" />
            </div>
          </div>
        </div>
      </ResultsBlock>

      <!-- TODO: All repeated UI could be replaced with  ResultsBlock component-->

      <div v-if="resultsByCategory.scholarships.nbHits > 0">
        <div class="hitsContainer">
          <span class="headingContainer">
            <h2>Scholarships</h2>
            <button @click="seeMore('scholarships')" class="desktop">
              See more ({{ resultCountByCategory.scholarships }})<inline-arrow
                name="default-arrow"
              />
            </button>
          </span>
          <div
            v-for="(hit, index) in resultsByCategory.scholarships.hits"
            :key="hit.id"
            :hit="hit"
            class="allTextWrapper"
          >
            <card-all-text v-if="index <= scholarshipsCount" :hit="hit" />
          </div>
        </div>
        <button @click="seeMore('scholarships')" class="mobile">
          See more ({{ resultCountByCategory.scholarships }})<inline-arrow name="default-arrow" />
        </button>
      </div>

      <div v-if="resultsByCategory.news.nbHits > 0">
        <div class="hitsContainer">
          <span class="headingContainer">
            <h2>News</h2>
            <button @click="seeMore('news')" class="desktop">
              See more ({{ resultCountByCategory.news }})<inline-arrow name="default-arrow" />
            </button>
          </span>
          <div
            v-for="(hit, index) in resultsByCategory.news.hits"
            :key="hit.id"
            :hit="hit"
            class="smallImageWrapper"
          >
            <card-small-image v-if="index <= newsCount" :hit="hit" />
          </div>
        </div>
        <button @click="seeMore('news')" class="mobile">
          See more ({{ resultCountByCategory.news }})<inline-arrow name="default-arrow" />
        </button>
      </div>

      <div v-if="resultsByCategory.research.nbHits > 0">
        <div class="hitsContainer">
          <span class="headingContainer">
            <h2>Research</h2>
            <button @click="seeMore('research')" class="desktop">
              See more ({{ resultCountByCategory.research }})<inline-arrow name="default-arrow" />
            </button>
          </span>
          <div
            v-for="(hit, index) in resultsByCategory.research.hits"
            :key="hit.id"
            :hit="hit"
            class="allTextWrapper"
          >
            <card-all-text v-if="index <= researchCount" :hit="hit" />
          </div>
        </div>
        <button @click="seeMore('research')" class="mobile">
          See more ({{ resultCountByCategory.research }})<inline-arrow name="default-arrow" />
        </button>
      </div>

      <div v-if="resultsByCategory.people.nbHits > 0">
        <div class="hitsContainer">
          <span class="headingContainer">
            <h2>People</h2>
            <button @click="seeMore('people')" class="desktop">
              See more ({{ resultCountByCategory.people }})<inline-arrow name="default-arrow" />
            </button>
          </span>
          <div
            v-for="(hit, index) in resultsByCategory.people.hits"
            :key="hit.id"
            :hit="hit"
            class="verticalImageWrapper"
          >
            <card-full-vertical-image v-if="index <= peopleCount" :hit="hit" />
          </div>
        </div>
        <button @click="seeMore('people')" class="mobile">
          See more ({{ resultCountByCategory.people }})<inline-arrow name="default-arrow" />
        </button>
      </div>

      <div v-if="resultsByCategory.other.nbHits > 0">
        <div class="hitsContainer">
          <span class="headingContainer">
            <h2>Other</h2>
            <button @click="seeMore('other')" class="desktop">
              See more ({{ resultCountByCategory.other }})<inline-arrow name="default-arrow" />
            </button>
          </span>
          <div
            v-for="(hit, index) in resultsByCategory.other.hits"
            :key="hit.id"
            :hit="hit"
            class="allTextWrapper"
          >
            <card-all-text v-if="index <= otherCount" :hit="hit" />
          </div>
        </div>
        <button @click="seeMore('other')" class="mobile">
          See more ({{ resultCountByCategory.other }})<inline-arrow name="default-arrow" />
        </button>
      </div>
    </section>
  </section>
</template>
<style scoped>
h2 {
  margin: 0;
  color: #000;
  font-size: 25px;
  font-weight: 600;
  line-height: 50px;
}

span {
  display: flex;
}

button {
  padding: 10px 15px;
  margin: 10px 0 13.5px;
  background: #fff;
  border-radius: 50px;
  box-shadow: 0 2px 30px rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  letter-spacing: 0.07em;
  line-height: 10px;
  text-transform: uppercase;
}

button svg {
  display: inline;
  width: 10px;
  margin-bottom: 2px;
  margin-left: 5px;
}

.headingContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.hitsContainer {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
}

.desktop {
  display: block;
}

.mobile {
  display: none;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: auto;
}

.defaultSelection {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 1439px) {
  .allTextWrapper {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  h2 {
    margin-bottom: 10px;
    font-size: 17px;
    line-height: 25px;
  }

  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }

  .hitsContainer {
    margin-bottom: 30px;
  }
}

@media (max-width: 869px) {
  .smallImageWrapper {
    width: calc(100%);
  }

  .cardWrapper {
    width: 100%;
  }

  .defaultSelection {
    display: block;
    width: 100%;
  }
}

@media (max-width: 399px) {
  .verticalImageWrapper {
    width: 100%;
  }
}
</style>
<script>
import { mapState, mapGetters, mapActions } from "vuex";

import Card from "@/components/assemblies/search/components/hits/Card";
import CardFullVerticalImage from "@/components/assemblies/search/components/hits/CardFullVerticalImage";
import CardFullHorizontalImage from "@/components/assemblies/search/components/hits/CardFullHorizontalImage";
import CardSmallImage from "@/components/assemblies/search/components/hits/CardSmallImage";
import CardAllText from "@/components/assemblies/search/components/hits/CardAllText";
import InlineArrow from "@/components/assemblies/masthead/components/elements/InlineArrow";
import ResultsBlock from "./ResultsBlock.vue";

export default {
  components: {
    Card,
    CardFullVerticalImage,
    CardFullHorizontalImage,
    CardSmallImage,
    CardAllText,
    InlineArrow,
    ResultsBlock,
  },
  props: {
    hits: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      courseCount: 2,
      eventCount: 3,
      researchCount: 3,
      newsCount: 5,
      peopleCount: 5,
      scholarshipsCount: 3,
      otherCount: 3,
    };
  },
  computed: {
    ...mapState("search", ["query", "courseSelections", "eventSelections"]),
    ...mapGetters("search", ["resultsByCategory", "noResults"]),
    ...mapGetters("ui", ["isMobile"]),
    resultCountByCategory() {
      return {
        courses: this.resultsByCategory.courses.nbHits,
        events: this.resultsByCategory.events.nbHits,
        research: this.resultsByCategory.research.nbHits,
        news: this.resultsByCategory.news.nbHits,
        people: this.resultsByCategory.people.nbHits,
        scholarships: this.resultsByCategory.scholarships.nbHits,
        other: this.resultsByCategory.other.nbHits,
      };
    },
  },
  methods: {
    ...mapActions("masthead", ["searchQuery"]),
    toTop() {
      const elmnt = document.getElementById("search-overlay");
      elmnt.scrollTo({ top: 0, behavior: "smooth" });
    },
    seeMore(value) {
      this.toTop();
      this.searchQuery();
      this.$store.commit("search/ACTIVE_FILTER", value);
    },
  },
};
</script>
