import Vue from "vue";
import Masthead from "./components/Masthead";

import store from "@/store/shared";

import formatData from "./lib/formatData";

export const masthead = (configs) =>
  configs.map(({ items, el, site }) => {
    const navigation = items.slice(0, -1);
    const navigationBanner = items.slice(-1).pop();

    store.commit("masthead/SET_ITEMS", formatData(navigation));
    store.commit("masthead/SET_NAVIGATION_BANNER", navigationBanner);
    store.commit("ui/SET_SITE_CONFIG", site);

    return new Vue({
      el,
      store,
      render(h) {
        h("<div/>");
        return h(Masthead);
      },
    });
  });
