<template>
  <button class="btn block w-full text-center mb-4" @click="toggleMobileFilters">{{ text }}</button>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("search", ["totalHits", "showMobileFilters"]),
    text() {
      return this.showMobileFilters
        ? `Show ${this.totalHits} results`
        : `Filter ${this.totalHits} results`;
    },
  },
  methods: {
    toggleMobileFilters() {
      this.$store.commit("search/TOGGLE_MOBILE_FILTERS");
    },
  },
};
</script>
