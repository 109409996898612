<template>
  <div class="overlay">
    <mobile v-if="showMobileFilters" />
    <results />
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";

import Results from "@/components/assemblies/search/components/results/Results";
import Mobile from "@/components/assemblies/search/components/filters/Mobile";

import { hideScroll } from "@/lib/hideScroll";

export default {
  components: {
    Results,
    Mobile,
  },
  computed: {
    ...mapGetters("search", ["showMobileFilters"]),
    ...mapState("masthead", ["searchExpanded"]),
  },
  watch: {
    searchExpanded(newSearchExpandedVal) {
      newSearchExpandedVal ? hideScroll(true) : hideScroll(false);
    },
  },
};
</script>
