<template>
  <div>
    <div v-if="hits.length" :class="isCardUi ? 'flex flex-wrap' : ''">
      <hit
        v-for="hit in hits"
        :key="hit.id"
        :hit="hit"
        :is-wide="isWide"
        :class="{
          '2xl:w-1/3 2xl:border-b-0': isWide && isCardUi,
        }"
      />
    </div>
    <no-results v-else :has-refinements="hasRefinements" :message="noResultsMessage" />
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";

import { CARD_UI, CARD_UI_RIGHT } from "@/search/constants/hitUiModes";

import Hit from "@/search/components/hits/Hit";
import NoResults from "@/search/components/results/NoResults";

export default {
  components: {
    Hit,
    NoResults,
  },
  props: {
    hits: {
      type: Array,
      required: true,
    },
    isWide: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState("search", ["hitUiMode"]),
    ...mapGetters("search", ["hasRefinements", "noResultsMessage"]),
    isCardUi() {
      return this.hitUiMode.indexOf(CARD_UI) > -1;
    },
    hasMediaRight() {
      return this.hitUiMode === CARD_UI_RIGHT;
    },
  },
  methods: {},
};
</script>
