<template>
  <a :href="hit.uri">
    <section :style="{ minHeight: toggleSearchQuery ? '183px' : '127px' }">
      <div class="tags">
        <p class="pill" v-for="(breadcrumb, index) in firstThreeBreadCrumbs" :key="index">
          {{ breadcrumb.title }}
        </p>
      </div>
      <h4 v-html="highlight(hit.title)"></h4>
      <div v-if="toggleSearchQuery">
        <p>
          <span v-html="highlight(hit.excerpt)"></span>
        </p>
      </div>
    </section>
  </a>
</template>
<style scoped>
section {
  position: relative;
  width: 620px;
  height: auto;
  padding: 15px 20px 20px 20px;
  margin: 0 10px 20px 0;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 30px rgba(0, 0, 0, 0.04);
}

h4 {
  margin-top: 12px;
  margin-bottom: 10px;
  font-size: 17px;
  line-height: 23px;
}

.tags {
  display: flex;
  overflow: hidden;
  flex-direction: row;
  line-height: 26px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pill {
  display: inline;
  width: fit-content;
  height: 20px;
  box-sizing: border-box;
  padding: 4.5px 8px 4.5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin: 0 5px 5px 0;
  border-radius: 100px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 10px;
  letter-spacing: 0.05em;
  line-height: 10px;
  text-transform: uppercase;
}

p {
  display: -webkit-box;
  overflow: hidden;
  padding-top: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin: 10px 0 0;
  -webkit-box-orient: vertical;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  -webkit-line-clamp: 3;
  line-height: 15px;
  text-overflow: ellipsis;
}

.orange {
  background: #ed8b00;
}

.green {
  background: #70cf96;
}

.purple {
  background: #ba6bd9;
}

@media (max-width: 1439px) {
  section {
    width: 100%;
  }
}
</style>
<script>
import { mapState } from "vuex";

export default {
  props: {
    hit: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState("masthead", ["toggleSearchQuery"]),
    ...mapState("search", ["query"]),
    firstThreeBreadCrumbs() {
      return this.hit.breadcrumb.slice(0, 3);
    },
  },
  methods: {
    highlight(content) {
      if (!this.query) {
        return content;
      }
      if (content !== null) {
        return content.replace(new RegExp(this.query, "gi"), (match) => {
          return '<span style="font-weight: bold">' + match + "</span>";
        });
      }
    },
  },
};
</script>
