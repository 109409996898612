<template>
  <a :href="hit.uri">
    <section>
      <span v-if="hit.image">
        <img :src="hit.image.url" :alt="hit.image.title" />
      </span>
      <span v-else>
        <Icon icon="events" width="300" height="190" class="placeholder" />
      </span>
      <div>
        <h4
          :style="{
            fontWeight: !toggleSearchQuery && query.length === 0 ? '600' : '100',
          }"
          v-html="highlight(hit.title)"
        ></h4>
        <p v-if="hit.unifiedDate">
          <Icon icon="clock" />{{ hit.unifiedDateFormats.localeDate }} •
          {{ hit.unifiedDateFormats.localeTime }}
        </p>
      </div>
    </section>
  </a>
</template>
<style scoped>
section {
  position: relative;
  width: 300px;
  height: auto;
  min-height: 190px;
  margin: 0 15px 20px 0;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 30px rgba(0, 0, 0, 0.04);
}

span {
  display: block;
  overflow: hidden;
  width: 100%;
  height: 142px;
  border-radius: 10px 10px 0 0;
}

img {
  width: 100%;
  border-radius: 10px 10px 0 0;
}

div {
  display: flex;
  height: 120px;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
}

h4 {
  display: -webkit-box;
  overflow: hidden;
  margin-bottom: 15px;
  -webkit-box-orient: vertical;
  font-size: 15px;
  -webkit-line-clamp: 3;
  line-height: 20px;
  text-overflow: ellipsis;
}

p {
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  line-height: 15px;
}

.clock {
  display: inline;
  width: 15px;
  margin-right: 5px;
  margin-bottom: 3px;
}

.placeholder {
  height: 190px;
}
</style>
<script>
import { mapState } from "vuex";

import Icon from "@/components/atoms/icon/Icon";

export default {
  components: {
    Icon,
  },
  props: {
    hit: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState("search", ["query"]),
    ...mapState("masthead", ["toggleSearchQuery"]),
  },
  methods: {
    highlight(content) {
      if (!this.query) {
        return content;
      }
      if (content !== null) {
        return content.replace(new RegExp(this.query, "gi"), (match) => {
          return '<span style="font-weight: bold">' + match + "</span>";
        });
      }
    },
  },
};
</script>
