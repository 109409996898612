<template>
  <div>
    <div v-if="metaItems.length" class="absolute z-10 top-0 right-0 -mt-px">
      <span v-for="(metaItem, index) in metaItems" :key="metaItem.title" class="meta">
        <a v-if="metaItem.url" :href="metaItem.url" :class="{ 'meta--accent-blue': index > 0 }">
          <span class="meta__inner">{{ metaItem.title }}</span>
        </a>
        <span v-else :class="{ 'meta--accent-blue': index > 0 }">
          {{ metaItem.title }}
        </span>
      </span>
    </div>
    <a class="block" :href="hit.uri" @click="trackGoResult(hit.url)">
      <img v-lazy="imageUrl" :alt="hit.title" class="block w-full shadow-3xl" />
    </a>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import trackingHelper from "@/search/mixins/trackingHelper";

export default {
  mixins: [trackingHelper],
  props: {
    hit: {
      required: true,
      type: Object,
    },
    metaItems: {
      required: false,
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters("search", ["placeholderUrl"]),
    imageUrl() {
      return this.hit.image && this.hit.image.url ? this.hit.image.url : this.placeholderUrl;
    },
  },
};
</script>
