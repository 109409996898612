import Vue from "vue";
import VueLazyload from "vue-lazyload";
import { isUndefined } from "lodash";
import ContentFeed from "@/content-feed/components/ContentFeed";
import SearchBanner from "@/content-feed/components/SearchBanner";
import StoreFactory from "@/store";

const FEED_OPTIONS = {
  SHOW_FILTERS: "search/SET_FILTERS_SHOWN",
  SHOW_QUERY_INPUT: "search/SET_QUERY_SHOWN",
  PAGINATE: "search/SET_PAGINATION_SHOWN",
  RESULT_TYPE: "search/SET_HIT_UI",
  SHOW_PAST_FUTURE_ITEMS: "search/SET_SHOW_PAST_FUTURE_ITEMS",
};

const createFeed = (CONFIG, component, beforeInject = () => {}) => {
  const searchContainer = document.querySelector(CONFIG.SELECTORS.CONTAINER);
  if (!searchContainer) {
    return null;
  }

  const store = StoreFactory({
    CUSTOM_DISJUNCTIVE_FACETS: CONFIG.DISJUNCTIVE_FACETS,
    CUSTOM_FACETS: CONFIG.FACETS,
  });

  const { FEED_CONFIG } = CONFIG;
  const { PRELOAD_QUERY } = FEED_CONFIG;

  Vue.use(VueLazyload, {
    preLoad: 1.3,
    error: CONFIG.PLACEHOLDER_IMAGE_URL,
    loading: CONFIG.PLACEHOLDER_IMAGE_URL,
    attempt: 1,
  });

  store.commit("search/SET_ALGOLIA_CONFIG", CONFIG.ALGOLIA);
  store.commit("search/SET_INDEX_NAME", CONFIG.INDEX_NAME);
  store.commit("search/SET_CONFIG", CONFIG);
  store.commit("search/SET_REQUIRE_RESULTS_WITH_URL", false);

  // set UI state
  Object.entries(FEED_OPTIONS).forEach(([option, mutation]) => {
    if (!isUndefined(FEED_CONFIG[option])) {
      store.commit(mutation, FEED_CONFIG[option]);
    }
  });

  // set Search state
  if (!isUndefined(PRELOAD_QUERY)) {
    store.dispatch("search/massSetFacetValues", PRELOAD_QUERY.facets);
    store.dispatch("search/massSetFacetValues", PRELOAD_QUERY.disjunctiveFacets);

    if (PRELOAD_QUERY.hitsPerPage) {
      store.commit("search/SET_HITS_PER_PAGE", PRELOAD_QUERY.hitsPerPage);
    }
  }

  if (!isUndefined(CONFIG.QUERY_STRING_STATE_ENABLED)) {
    store.commit("search/SET_QUERY_STRING_STATE_ENABLED", CONFIG.QUERY_STRING_STATE_ENABLED);
  }

  beforeInject({ store, FEED_CONFIG });

  /* eslint-disable no-new */
  return new Vue({
    el: CONFIG.SELECTORS.CONTAINER,
    name: "FeedContainer",
    render: (h) =>
      h(component, {
        props: {
          heading: FEED_CONFIG.HEADING,
          caption: FEED_CONFIG.CAPTION,
        },
      }),
    store,
  });
};

export const contentFeed = (configs) =>
  configs.map((CONFIG) =>
    createFeed(CONFIG, ContentFeed, ({ store }) => {
      store.commit("search/SET_FORCE_RESULTS_SHOWN", true);
    })
  );

export const searchBanner = (configs) =>
  configs.map((CONFIG) =>
    createFeed(CONFIG, SearchBanner, ({ store, FEED_CONFIG }) => {
      store.commit("masthead/SHOW_SEARCH", true);
      store.commit("search/SET_QUERY_SHOWN", false);
      store.commit("search/SET_FORCE_RESULTS_SHOWN", false);
      store.commit("search/RESET_HAS_SEARCHED");

      if (!isUndefined(FEED_CONFIG.SEARCH_ENABLED)) {
        store.commit("search/SET_SEARCH_ENABLED", FEED_CONFIG.SEARCH_ENABLED);
      }
    })
  );
