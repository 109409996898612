<template>
  <div class="m-snippet relative border-b border-lavender transition">
    <div
      class="hover:border-l-8 focus:border-l-8 border-brand-yellow p-6 md:py-9 md:pl-19 md:pr-10 transition"
    >
      <div class="flex flex-wrap">
        <div class="w-full lg:flex-1 min-w-1/2">
          <div class="lg:pr-12" :class="{ 'mb-5': hit.text }">
            <h3 class="h-trim text-md mb-3">
              <a
                v-if="hit.hasUrl"
                class="block no-underline"
                :href="hit.uri"
                @click="trackGoResult(hit.uri)"
              >
                {{ hit.title }}
              </a>
              <span v-else class="block">
                {{ hit.title }}
              </span>
            </h3>
          </div>
          <div class="mb-4 leading-tight text-xs leading-none">
            <h4 class="font-bold text-xs inline-block mb-0 mr-2">Types of coaching offered:</h4>
            <span v-for="(item, index) in types" :key="item + '-' + index" class="leading-none">
              <a v-if="item.url" :key="item.title + '-item'" :href="item.url" class="no-underline">
                {{ item.title }}
              </a>
              <span v-else>
                {{ truncate(item, 90, "...") }}
              </span>
              <span v-if="index < metaItems.length - 1" class="text-brand-yellow px-1">
                {{ metaSeparator }}
              </span>
            </span>
          </div>
          <div v-if="locations.length" class="mb-4 leading-tight text-xs leading-none">
            <h4 class="font-bold text-xs inline-block mb-0 mr-2">Location(s):</h4>
            <span v-for="(item, index) in locations" :key="item + '-' + index" class="leading-none"
              >{{ item }}
              <span v-if="index < metaItems.length - 1" class="text-brand-yellow px-1">
                {{ metaSeparator }}
              </span>
            </span>
          </div>
          <slot name="excerpt">
            <h4 class="font-bold text-xs mb-0 mr-2">Academic profile:</h4>
            <div v-if="hit.description" class="text-xs mt-3 mb-0 trim" v-html="hit.description" />
          </slot>
        </div>

        <div class="mt-4 lg:mt-0">
          <span
            v-for="(metaItem, index) in renderedTagMetaItems"
            :key="metaItem.title"
            class="inline-block"
          >
            <a
              v-if="metaItem.url"
              :href="metaItem.url"
              :class="{ 'meta--accent-blue': index > 0 }"
              class="meta"
            >
              <span class="meta__inner">{{ metaItem.title }}</span>
            </a>
            <span v-else :class="{ 'meta--accent-blue': index > 0 }" class="meta">{{
              metaItem.title
            }}</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Coach from "../content-types/Coach";
import BaseHit from "../BaseHit";

export default {
  components: {},
  extends: BaseHit,
  mixins: [Coach],
};
</script>
