<template>
  <h3 :id="id" class="text-inherit mb-0">
    <button
      :aria-controls="'inner-' + id"
      :aria-expanded="isExpanded"
      :aria-label="ariaLabel + ' ' + toggleLabel"
      :class="[...htmlClasses, isExpanded ? 'is--active border-transparent' : 'border-comet']"
      class="block border-b flex flex-wrap focus:bg-transparent font-bold hover:bg-transparent items-center justify-between leading-loose py-1 text-left text-sm trim w-full"
      @click.prevent="toggle"
    >
      <!-- eslint-disable vue/no-v-html -->
      <span v-html="text" />
      <span class="ml-2">
        <icon
          :icon="expandIcon"
          role="presentation"
          width="8"
          height="8"
          class="inline-block w-6"
        />
      </span>
    </button>
  </h3>
</template>
<script>
import ToggleBase from "@/components/molecules/toggle/ToggleBase";

export default {
  extends: ToggleBase,
};
</script>
