<template>
  <button class="flex flex-row items-center h-10" @click="handleClick">
    <p class="p-0 pr-2 m-0 leading-none text-xs text-grey-dark whitespace-no-wrap">Close search</p>
    <Icon icon="close-icon" class="h-10" />
  </button>
</template>

<script>
import Icon from "@/components/atoms/icon/Icon";

export default {
  name: "CloseSearchButton",
  components: {
    Icon,
  },
  methods: {
    handleClick() {
      this.$emit("click");
    },
  },
};
</script>
