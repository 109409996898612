<template>
  <default-teaser :hit="hit" :tag-meta-items-overide="tagMetaItems">
    <template v-slot:excerpt> "{{ hit.excerpt }}" </template>
  </default-teaser>
</template>
<script>
import DefaultTeaser from "./DefaultTeaser";
import Testimonial from "../content-types/Testimonial";

export default {
  components: {
    DefaultTeaser,
  },
  extends: DefaultTeaser,
  mixins: [Testimonial],
};
</script>
