<template>
  <div :class="textColorClass" class="flex flex-wrap items-center">
    <button class="py-4 px-3" aria-label="Previous Slide" @click.prevent="prev">
      <icon icon="caret-left" height="5" width="8" class="w-6 h-4" />
    </button>
    <span>{{ currentIndex + 1 }} / {{ items.length }}</span>
    <button class="py-4 px-3" aria-label="Next Slide" @click.prevent="next">
      <icon icon="caret" height="5" width="8" class="w-6 h-4" />
    </button>
  </div>
</template>
<script>
import Icon from "@/components/atoms/icon/Icon";

export default {
  components: {
    Icon,
  },
  props: {
    items: {
      required: true,
      type: Array,
    },
    currentIndex: {
      required: true,
      type: Number,
    },
    textColor: {
      required: false,
      type: String,
      default: "",
    },
  },
  computed: {
    textColorClass() {
      return `text-${this.textColor}`;
    },
  },
  methods: {
    prev() {
      this.$emit("prev");
    },
    next() {
      this.$emit("next");
    },
  },
};
</script>
