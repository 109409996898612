<template>
  <div class="pr-2">
    <component
      :is="getFilterComponent(filter)"
      v-for="filter in filtersForUi"
      :key="filter.name"
      :filter="filter"
      :real-time="true"
      :values="filter.values"
    ></component>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import RangeFilter from "@/search/components/filters/RangeFilter";
import CheckboxFilter from "@/search/components/filters/CheckboxFilter";

export default {
  components: {
    RangeFilter,
    CheckboxFilter,
  },
  computed: {
    ...mapGetters("facets", ["filtersForUi"]),
  },
  methods: {
    getFilterComponent({ type }) {
      let componentName = "CheckboxFilter";

      switch (type) {
        case "numericRangeFilter":
          componentName = "RangeFilter";
          break;
        default:
          componentName = "CheckboxFilter";
          break;
      }

      return componentName;
    },
  },
};
</script>
