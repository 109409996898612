<script>
import { mapGetters } from "vuex";

import stringHelper from "@/search/mixins/stringHelper";
// import ResetAllFilters from "@/search/components/filters/ResetAllFilters";
import Filters from "@/search/components/filters/All";

export default {
  components: {
    Filters,
    // ResetAllFilters,
  },
  mixins: [stringHelper],
  computed: {
    ...mapGetters("ui", ["isDesktop"]),
  },
};
</script>

<template>
  <div>
    <div class="flex flex-wrap items-center mb-6 text-right">
      <!--      <reset-all-filters />-->
    </div>

    <filters />
  </div>
</template>
