<template>
  <h3 :id="id" class="border-t border-lavender w-full block mb-0">
    <button
      :aria-controls="'inner-' + id"
      :aria-expanded="isExpanded"
      :aria-label="ariaLabel"
      :class="[...htmlClasses, isExpanded ? ' is--active border-brand-yellow' : '']"
      class="block border-l-4 border-transparent w-full hover:bg-grey-lighter font-normal tracking-slight trim text-left flex items-center p-5 py-6 transition"
      @click.prevent="toggle"
    >
      <!-- eslint-disable vue/no-v-html -->
      <span class="block trim" v-html="text" />
      <span class="text-inherit ml-auto text-sm" role="presentation">
        {{ toggleLabel }}
      </span>
      <span class="ml-2">
        <icon :icon="expandIcon" role="presentation" width="7" height="7" class="block w-5" />
      </span>
    </button>
  </h3>
</template>
<script>
import ToggleBase from "@/components/molecules/toggle/ToggleBase";

export default {
  extends: ToggleBase,
  watch: {
    isExpanded(nowExpanded) {
      if (nowExpanded) {
        this.setHash();
      } else {
        this.unsetHash();
      }
    },
  },
  methods: {
    setHash() {
      if (window.history.replaceState) {
        window.history.replaceState(null, null, `#${this.id}`);
      } else {
        window.location.hash = this.id;
      }
    },
    unsetHash() {
      if (window.history.replaceState) {
        window.history.replaceState(null, null, "#");
      } else {
        window.location.hash = null;
      }
    },
  },
};
</script>
