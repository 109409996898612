<template>
  <section>
    <span v-if="hits">
      Viewing {{ totalHitsV2 }} of {{ totalHitsForResultsByCategory }} results for
      <span class="bold">{{ query }}</span></span
    >
    <span v-else>0 results</span>
  </section>
</template>
<style scoped>
section {
  padding: 40px 90px 0;
  margin-bottom: 20px;
}

span {
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  line-height: 15px;
}

.bold {
  font-weight: bold;
}

@media (max-width: 1375px) {
  section {
    padding: 60px 60px 0 60px;
  }
}

@media (max-width: 1340px) {
  section {
    padding: 20px 20px 0 20px;
  }
}

@media (max-width: 1129px) {
  section {
    margin-bottom: 5px;
  }
}

@media (max-width: 1024px) {
  section {
    padding: 15px 0 0 15px;
  }
}
</style>
<script>
import { mapGetters, mapState } from "vuex";

export default {
  computed: {
    ...mapGetters("search", ["query", "totalHitsV2", "totalHitsForResultsByCategory"]),
    ...mapState("search", ["hits", "nbHits"]),
  },
};
</script>
