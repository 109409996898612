<template>
  <fieldset v-if="filter.values.length" class="mb-8">
    <div class="flex flex-wrap items-center mb-4">
      <div class="trim w-2/3 pr-4">
        <h5 class="h-reset text-base text-brand-blue">{{ filter.displayTitle }}</h5>
      </div>
      <div class="flex-1 text-right">
        <!--        @toodo needs to only clear the user specified filters, not all -->
        <!--        <button-->
        <!--          class="text-accent-blue hover:text-brand-blue focus:text-brand-blue text-right ml-auto"-->
        <!--          :class="{ 'm-search-filter__reset&#45;&#45;active': hasRefinements }"-->
        <!--          :title="'Clear all'"-->
        <!--          :disabled="!hasRefinements"-->
        <!--          @click.prevent="reset"-->
        <!--        >-->
        <!--          Clear-->
        <!--        </button>-->
      </div>
    </div>
    <ul class="">
      <li v-for="value in filter.values" :key="value.name">
        <label class="checkbox mt-4 mb-4" :for="getFilterValueId(value.name)">
          <input
            :id="getFilterValueId(value.name)"
            type="checkbox"
            class="m-search-filter__list-item-checkbox"
            :value="value.name"
            :checked="value.isRefined"
            @change="toggleValue(value)"
          />
          <span class="checkbox__label"
            >{{ capitalize(value.name) }}&nbsp;<span class="text-grey"
              >({{ value.count }})</span
            ></span
          >
        </label>
      </li>
    </ul>
  </fieldset>
</template>

<script>
import slugify from "underscore.string/slugify";
import capitalize from "underscore.string/capitalize";

export default {
  props: {
    filter: {
      required: true,
      type: Object,
    },
  },
  computed: {
    hasRefinements() {
      return this.filter.values.filter((v) => v.isRefined).length > 0;
    },
  },
  methods: {
    reset() {
      this.$store.commit("search/CLEAR_FACET", this.filter.name);
    },
    getFilterValueId(valueName) {
      return slugify(`${this.filter.name}-${valueName}`);
    },
    capitalize: (str) => capitalize(str),
    toggleValue(value) {
      this.$store.commit("search/MARK_INTERACTION");
      this.$store.commit("search/TOGGLE_SEARCH_FACET_VALUE", {
        name: this.filter.name,
        value: value.name,
      });
    },
  },
};
</script>
