export function bindCardSliderControls() {
  const controls_prev = document.querySelectorAll(".js-card-slider-controls-prev");
  const controls_next = document.querySelectorAll(".js-card-slider-controls-next");
  for (let i = 0; i < controls_prev.length; i++) {
    controls_prev[i].addEventListener("click", () => {
      window.dispatchEvent(new CustomEvent("henley-splide-prev"));
    });
  }
  for (let i = 0; i < controls_next.length; i++) {
    controls_next[i].addEventListener("click", () => {
      window.dispatchEvent(new CustomEvent("henley-splide-next"));
    });
  }
}
