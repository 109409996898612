import Vue from "vue";
import HeroVideo from "./HeroVideo";

const HeroTargets = {};

const HeroTarget = (targetSelector) => {
  return new Vue({
    el: document.querySelector(targetSelector),
    data() {
      return {
        videos: [],
        currentVideo: null,
        isExpanded: false,
        isPlaying: false,
        wrapperPlayingClass: "m-hero--playing",
      };
    },
    watch: {
      isExpanded(isExpanded) {
        const heroContainer = this.$el.parentNode;

        return isExpanded
          ? heroContainer.classList.add(this.wrapperPlayingClass)
          : heroContainer.classList.remove(this.wrapperPlayingClass);
      },
    },
    methods: {
      addVideo(el, { id, caption, thumbnail }) {
        this.bindEl(el);

        this.videos.push({ el, id, caption, thumbnail });
      },
      bindEl(el) {
        el.addEventListener("click", (e) => this.clickHandler(e, el));
      },
      unbindEl(el) {
        el.removeEventListener("click", (e) => this.clickHandler(e, el));
      },
      clickHandler(e, el) {
        e.preventDefault();

        const video = this.videos.find((v) => v.el === el);

        this.isExpanded = true;

        if (video === this.currentVideo && this.isPlaying) {
          this.pause();
        } else {
          this.currentVideo = video;
          this.play();
        }
      },
      play() {
        this.isPlaying = true;
      },
      pause() {
        this.isPlaying = false;
      },
      toggle() {
        this.isPlaying = !this.isPlaying;
      },
      close() {
        this.isExpanded = false;
        this.pause();
      },
    },
    render(h) {
      return h(HeroVideo, {
        props: {
          videos: this.videos,
          currentVideo: this.currentVideo,
          isExpanded: this.isExpanded,
          isPlaying: this.isPlaying,
        },
        on: {
          close: this.close,
          end: this.close,
        },
      });
    },
  });
};

function HeroVideos(targetSelector) {
  let heroTarget = HeroTargets[targetSelector];

  if (!heroTarget) {
    heroTarget = new HeroTarget(targetSelector);
    HeroTargets[targetSelector] = heroTarget;
  }

  return heroTarget;
}

export function bindHeroVideos() {
  return [...document.querySelectorAll("[data-youtube-player]")].map((el) => {
    const {
      youtubeId: id,
      youtubeTarget: targetSelector,
      youtubeCaption: caption,
      youtubeThumbnail: thumbnail,
    } = el.dataset;

    return HeroVideos(targetSelector).addVideo(el, { id, caption, thumbnail });
  });
}
