function filterItem(item) {
  const result = { ...item };

  result.isExpanded = false;

  if (result.children) {
    result.children = result.children.reduce((children, child) => {
      const childResult = filterItem(child, result);

      if (childResult) {
        children.push(childResult);
      }

      return children;
    }, []);
  }
  return result;
}

export default (config) => {
  return [...config]
    .reduce((output, item) => {
      const result = filterItem(item);

      if (result) {
        output.push(result);
      }

      return output;
    }, [])
    .map((current) => {
      const item = { ...current };

      return item;
    });
};
