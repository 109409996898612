<template>
  <fieldset class="m-filter-group m-filter-group--range">
    <div class="grid">
      <div class="grid__cell">
        <h3 class="m-filter-group__title">{{ filter.displayTitle }}</h3>
      </div>
      <div class="grid__cell u-text-right">
        <button
          class="m-filter-group__reset"
          :class="{ 'm-filter-group__reset--active': hasRefinements }"
          :disabled="!hasRefinements"
          @click.prevent="reset"
        >
          Reset
        </button>
      </div>
    </div>
    <vue-slider v-model="sliderValues" v-bind="rangeConfig" :min="min" :max="max">
      <template v-slot:tooltip="tooltip">
        <div class="diy-tooltip">
          <span class="text-small">{{ tooltip.value }}</span>
        </div>
      </template>
    </vue-slider>
  </fieldset>
</template>

<script>
import VueSlider from "vue-slider-component";

export default {
  components: {
    VueSlider,
  },
  props: {
    filter: {
      required: true,
      type: Object,
    },
    values: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      max: null,
      min: null,
    };
  },
  computed: {
    hasRefinements() {
      return !(this.values.min === this.min && this.values.max === this.max);
    },
    rangeConfig() {
      return {
        dotSize: 10,
        height: 2,
        speed: 0.2,
        lazy: true,
        min: this.filter.bounds.min,
        max: this.filter.bounds.max,
        tooltipDir: "bottom",
        // @todo abstract colours to palette
        sliderStyle: {
          backgroundColor: "#38c9f1",
        },
        bgStyle: {
          backgroundColor: "#768497",
        },
        processStyle: {
          backgroundColor: "#38c9f1",
        },
      };
    },
    sliderValues: {
      get() {
        return [this.values.min, this.values.max];
      },
      set(newValues) {
        this.$store.commit("search/MARK_INTERACTION");
        this.$store.commit("search/SET_RANGE_REFINEMENT", {
          name: this.filter.name,
          values: newValues,
        });
      },
    },
  },
  created() {
    this.min = this.filter.bounds.min;
    this.max = this.filter.bounds.max;
  },
  methods: {
    reset() {
      this.$store.commit("search/SET_RANGE_REFINEMENT", {
        name: this.filter.name,
        values: [this.min, this.max],
      });
    },
  },
};
</script>
