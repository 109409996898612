import algoliasearch from "algoliasearch/lite";
import algoliasearchHelper from "algoliasearch-helper";
import Vue from "vue";
import { mapState, mapGetters } from "vuex";

import { ALGOLIA_FACET } from "@/components/assemblies/search/constants/facets";

const AlgoliaPlugin = (store) => {
  const HITS_PER_PAGE = 12;

  return new Vue({
    store,
    computed: {
      ...mapState("search", [
        "derivedAlgoliaHelpers",
        "algoliaHelpers",
        "algoliaReady",
        "searchEnabled",
        "config",
        "indexName",
        "isEnabled",
        "isAlgoliaEventListenersEnabled",
      ]),
      ...mapGetters("search", ["page", "query"]),

      vueAppId() {
        return this.config.ID;
      },
    },
    watch: {
      indexName() {
        this.initClient();
        this.initListenersOnDerivedHelpers();
      },
      SEARCH_API_KEY() {
        if (!this.algoliaReady) {
          this.initClient();
        }
      },
      query(newValue) {
        if (!this.isAlgoliaEventListenersEnabled) {
          this.initListenersOnDerivedHelpers();
        }

        for (const algoliaHelper of this.algoliaHelpers) {
          algoliaHelper.setQuery(newValue).search();
        }
      },
    },
    created() {
      if (this.APPLICATION_ID && this.SEARCH_API_KEY) {
        this.initClient();
      }
    },
    methods: {
      /**
       * Initialize the Algolia client.
       */
      initClient() {
        // first we create alogliasearch client and keep this instance local to this helper
        this.client = algoliasearch(
          this.config.ALGOLIA.APPLICATION_ID,
          this.config.ALGOLIA.SEARCH_API_KEY
        );

        this.$store.commit("search/SET_ALGOLIA_READY", true);
      },
      initListenersOnDerivedHelpers() {
        const SITE_ID_FACET = "siteId";
        const HAS_URL_FACET = "hasUrl";
        const ELEMENT_STATUS_FACET = "elementStatus";
        const START_DATE_FACET = "startDate";

        const config = {
          facets: [SITE_ID_FACET, HAS_URL_FACET, ELEMENT_STATUS_FACET, START_DATE_FACET],
          disjunctiveFacets: [ALGOLIA_FACET],
          hitsPerPage: HITS_PER_PAGE,
        };

        /**
         * We set up 3 separate algoliasearchHelpers to achieve the following
         *
         * "courses", "research", "people", "other" items need to use an index that priorities textual matches
         *
         * "events" items need to use an index priorities upcoming events (later we also need filter  out past events )
         *
         * "news" items need to use an index priorities most recently posted
         */
        const algoliaHelperForTextualMatches = algoliasearchHelper(
          this.client,
          this.indexName,
          config
        );

        const algoliaHelperForEvents = algoliasearchHelper(
          this.client,
          this.indexName + "_startDateOrderReversed",
          config
        );

        const algoliaHelperForNews = algoliasearchHelper(
          this.client,
          this.indexName + "_publishDateOrder",
          config
        );

        // Pair up disjunctive facets with a algolia helper
        const algoliaHelpersWithDisjunctiveFacets = [
          {
            algoliaHelper: algoliaHelperForTextualMatches,
            disjunctiveFacets: ["courses", "research", "people", "other", "scholarships"],
          },
          { algoliaHelper: algoliaHelperForEvents, disjunctiveFacets: ["events"] },
          { algoliaHelper: algoliaHelperForNews, disjunctiveFacets: ["news"] },
        ];

        for (const item of algoliaHelpersWithDisjunctiveFacets) {
          const siteId = this.$store.state.search.config.SITE.ID;
          if (siteId) {
            item.algoliaHelper.addFacetRefinement(SITE_ID_FACET, siteId); // filter results by siteId: 1 = henleyUk, 3 = ICMA
          }
          item.algoliaHelper.addFacetRefinement("hasUrl", "true"); // filter out internal modules
          item.algoliaHelper.addFacetRefinement(ELEMENT_STATUS_FACET, "live"); // filter out draft pages

          // apply filter so we dont see past events
          if (item.disjunctiveFacets.some((x) => x === "events")) {
            item.algoliaHelper.addNumericRefinement(
              START_DATE_FACET,
              ">",
              new Date().getTime() / 1000
            );
          }

          // Set up derived helpers for each given facet that has been paired with this index
          item.disjunctiveFacets.map((facetKey) => {
            const facetKeyDerivedHelper = item.algoliaHelper.derive((searchParams) =>
              searchParams.addDisjunctiveFacetRefinement(ALGOLIA_FACET, facetKey)
            );
            this.$store.commit("search/SET_ALGOLIA_DERIVED_HELPERS", {
              facetKey,
              facetKeyDerivedHelper,
            });
          });
        }

        // Now we're ready to store all algolia helpers into vuex to be used later
        this.$store.commit(
          "search/SET_ALGOLIA_HELPERS",
          algoliaHelpersWithDisjunctiveFacets.map((i) => i.algoliaHelper)
        );

        // Next we can loop over all derived helpers to add event listeners on them
        for (const category in this.derivedAlgoliaHelpers) {
          this.derivedAlgoliaHelpers[category].on("result", (result) => {
            this.handleResults(result, category);
          });
        }
        // Important to set this flag as an indicator to avoid adding event listeners again.
        this.$store.commit("search/SET_IS_ALGOLIA_EVENT_EMITTERS_ENABLED", true);

        // We run an initial search to populate Vuex with unfiltered results.
        for (const algoliaHelper of this.algoliaHelpers) {
          algoliaHelper.setQuery("").search();
        }
      },

      handleResults(result, category) {
        this.$store.dispatch("search/setResultsByCategory", {
          category: category,
          results: {
            hits: result.hits,
            nbHits: result.nbHits,
            page: result.page,
            nbPages: result.nbPages,
          },
        });
      },

      destroyEventListeners() {
        this.algoliaHelper.removeAllListeners("result");
      },
    },
    unmounted() {
      this.destroyEventListeners();
    },
  });
};

export const algoliaPluginV2 = (config) => {
  return (store) => (store.algoliaPlugin = new AlgoliaPlugin(store, config));
};
