<!-- Inline Arrow Component used in Mega Navigation Blocks -->
<template>
  <icon :icon="name" />
</template>
<script>
import Icon from "@/components/atoms/icon/Icon";

export default {
  components: {
    Icon,
  },
  props: {
    name: {
      type: String,
      required: true,
      default: "",
    },
  },
};
</script>
