<template>
  <section>
    <h2>No results found</h2>
    <p>
      Sorry, we couldn’t find anything for <span>'{{ this.query }}'</span> <br />
      Why not try...
    </p>
    <div class="wrapper">
      <span v-for="(single, index) in dataManipulated(popularSearches)" :key="single">
        <div v-if="index <= 5" class="option" @click="updateQuery(single)">
          <Icon icon="search-input" />
          <span>{{ single }}</span>
        </div>
      </span>
    </div>
  </section>
</template>
<style scoped>
section {
  padding: 200px 0 300px;
  text-align: center;
}

h2 {
  font-size: 25px;
  font-weight: 600;
  line-height: 25px;
}

p {
  margin-bottom: 40px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  line-height: 25px;
}

span {
  font-weight: 600;
}

.wrapper {
  display: flex;
  justify-content: center;
}

.option {
  display: flex;
  width: fit-content;
  box-sizing: border-box;
  align-items: center;
  padding: 15px 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin: 0 5px;
  background: #fff;
  border-radius: 100px;
}

.option:hover {
  cursor: pointer;
}

.option svg {
  width: 15px;
  margin-right: 10px;
}

.option span {
  display: block;
  width: fit-content;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  letter-spacing: 0.07em;
  line-height: 10px;
  text-transform: uppercase;
}

@media (max-width: 1080px) {
  section {
    padding: 200px 0;
  }

  .wrapper {
    flex-direction: column;
    justify-content: center;
  }

  .wrapper span {
    margin: auto;
  }

  .option {
    margin: 10px 0;
  }
}
</style>
<script>
import { mapState } from "vuex";
import Icon from "@/components/atoms/icon/Icon";
export default {
  components: {
    Icon,
  },
  computed: {
    ...mapState("search", ["query", "popularSearches"]),
  },
  methods: {
    updateQuery(value) {
      this.$store.commit("search/SET_QUERY", value);
    },
    dataManipulated(data) {
      return JSON.stringify(data).replace(/['"]+/g, "").split(",");
    },
  },
};
</script>
