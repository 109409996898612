<template>
  <a :href="hit.uri">
    <section
      :style="{
        minHeight: toggleSearchQuery ? '200px' : '134px',
        fontWeight: !toggleSearchQuery && query.length === 0 ? '600' : '100',
      }"
    >
      <span :class="colourChange"></span>
      <div class="tags">
        <h6 v-if="hit.levelOfStudy">{{ hit.levelOfStudy[0] }}</h6>
        <h6 v-if="hit.courseDuration">{{ hit.courseDuration }}</h6>
      </div>
      <h4
        v-html="highlight(hit.title)"
        :style="{ fontWeight: !toggleSearchQuery && query.length === 0 ? '600' : '100' }"
      ></h4>
      <div v-if="toggleSearchQuery">
        <p v-html="highlight(hit.excerpt)"></p>
      </div>
    </section>
  </a>
</template>
<style scoped>
section {
  position: relative;
  width: 397px;
  height: auto;
  min-height: 200px;
  padding: 20px;
  margin: 0 22px 20px 0;
  background: #fff;
  border-radius: 0 10px 10px 0;
}

h4 {
  display: -webkit-box;
  overflow: hidden;
  margin-top: 12px;
  margin-bottom: 10px;
  -webkit-box-orient: vertical;
  font-size: 17px;
  -webkit-line-clamp: 2;
  line-height: 23px;
  text-overflow: ellipsis;
}

h6 {
  display: inline;
  width: fit-content;
  height: 20px;
  box-sizing: border-box;
  padding: 4.5px 8px 4.5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin: 0 5px 5px 0;
  border-radius: 100px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 10px;
  letter-spacing: 0.05em;
  line-height: 10px;
  text-transform: uppercase;
}

span {
  position: absolute;
  top: 0%;
  right: 97.54%;
  bottom: 0%;
  left: 0%;
  max-width: 8px;
  border-radius: 10px 0 0 10px;
}

p {
  display: -webkit-box;
  overflow: hidden;
  padding-top: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin: 10px 0;
  -webkit-box-orient: vertical;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  -webkit-line-clamp: 3;
  line-height: 15px;
  text-overflow: ellipsis;
}

.orange {
  background: #ed8b00;
}

.green {
  background: #70cf96;
}

.purple {
  background: #ba6bd9;
}

.blue {
  background: #57ccf2;
}

.red {
  background: #cf3d30;
}

.pink {
  background: #f09ccc;
}

.tags {
  overflow: hidden;
  line-height: 26px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (max-width: 869px) {
  section {
    width: 100%;
    min-width: 100%;
  }

  h4 {
    font-size: 15px;
    line-height: 20px;
  }
}

/* @media (max-width: 500px) {
  h4 {
    font-size: 15px;
    line-height: 20px;
  }
} */
</style>
<script>
import { mapState } from "vuex";

export default {
  props: {
    hit: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState("masthead", ["searchExpanded", "toggleSearchQuery"]),
    ...mapState("search", ["query"]),
    colourChange() {
      if (this.hit.levelOfStudy) {
        if (this.hit.levelOfStudy[0].toLowerCase() === "apprenticeships") {
          return "blue";
        } else if (this.hit.levelOfStudy[0].toLowerCase() === "masters") {
          return "orange";
        } else if (this.hit.levelOfStudy[0].toLowerCase() === "undergraduate") {
          return "green";
        } else if (this.hit.levelOfStudy[0].toLowerCase() === "executive education") {
          return "red";
        } else if (this.hit.levelOfStudy[0].toLowerCase() === "mba") {
          return "pink";
        } else {
          return "purple";
        }
      } else {
        return null;
      }
    },
  },
  methods: {
    highlight(content) {
      if (!this.query) {
        return content;
      }
      if (content !== null) {
        return content.replace(new RegExp(this.query, "gi"), (match) => {
          return '<span style="font-weight: bold">' + match + "</span>";
        });
      }
    },
  },
};
</script>
